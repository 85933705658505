import React, { useState, useEffect, useRef } from 'react';
import './AlternativeCart.css';
import CashLimitPopup from '../../components/CashLimitPopup';
import { BACKEND_URL } from '../../config';

const AlternativeCart = ({ 
  cartItems, 
  removeFromCart, 
  checkout, 
  updateQuantity, 
  togglePaymentType, 
  clearCart, 
  userSettings, 
  onSaveForLater, 
  onLoadBuylist,
  setCartItems = () => {},
  calculatePrice = () => ({}),
  allowedConditions = [],
  addToCart = () => {},
  customItems = []
}) => {
  const [showCashLimitPopup, setShowCashLimitPopup] = useState(false);
  const [cashLimitConfirmed, setCashLimitConfirmed] = useState(false);
  // Use customItems prop if provided, otherwise use local state
  const [localCustomItems, setLocalCustomItems] = useState([]);
  const [showCustomItems, setShowCustomItems] = useState(false);
  const [isLoadingCustomItems, setIsLoadingCustomItems] = useState(false);
  
  // We no longer need to use customItems in the cart as they're shown in the main content area
  
  const cashSubtotal = cartItems.reduce((sum, item) => sum + (item.isCash ? parseFloat(item.cashPrice) * item.quantity : 0), 0);
  const creditSubtotal = cartItems.reduce((sum, item) => sum + (!item.isCash ? parseFloat(item.creditPrice) * item.quantity : 0), 0);
  const total = cartItems.reduce((sum, item) => sum + (item.isCash ? parseFloat(item.cashPrice) : parseFloat(item.creditPrice)) * item.quantity, 0);
  const totalItems = cartItems.reduce((sum, item) => sum + item.quantity, 0);
  
  // Fetch custom buylist items when username changes if fetchCustomItems prop is not provided
  useEffect(() => {
    if (userSettings && userSettings.username && !customItems) {
      fetchLocalCustomItems(userSettings.username);
    }
  }, [userSettings, customItems]);
  
  // Listen for closeCustomItems and showCustomItems events
  useEffect(() => {
    const handleCloseCustomItems = () => {
      setShowCustomItems(false);
    };
    
    const handleShowCustomItems = () => {
      setShowCustomItems(true);
    };
    
    // Add event listeners to the cart element
    const cartElement = document.querySelector('.alt-cart');
    if (cartElement) {
      cartElement.addEventListener('closeCustomItems', handleCloseCustomItems);
      cartElement.addEventListener('showCustomItems', handleShowCustomItems);
    }
    
    // Clean up event listeners
    return () => {
      if (cartElement) {
        cartElement.removeEventListener('closeCustomItems', handleCloseCustomItems);
        cartElement.removeEventListener('showCustomItems', handleShowCustomItems);
      }
    };
  }, []);

  // Function to fetch custom buylist items (only used if fetchCustomItems prop is not provided)
  const fetchLocalCustomItems = async (username) => {
    try {
      setIsLoadingCustomItems(true);
      const response = await fetch(`${BACKEND_URL}/custom-buylist/${username}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setLocalCustomItems(data.items || []);
    } catch (error) {
      console.error('Error fetching custom buylist items:', error);
    } finally {
      setIsLoadingCustomItems(false);
    }
  };
  
  // Check if cash limit is exceeded
  useEffect(() => {
    // Only check if we have user settings and there are items in the cart
    if (userSettings && cartItems.length > 0) {
      const cashLimit = userSettings.cash_limit;
      
      // If cash_limit is defined, not 0, and the cash subtotal exceeds it
      if (cashLimit !== undefined && cashLimit !== 0 && cashSubtotal > cashLimit) {
        // Only show the popup if it hasn't been confirmed yet
        if (!cashLimitConfirmed) {
          setShowCashLimitPopup(true);
        }
      } else {
        // Reset if the cash subtotal goes back below the limit
        setShowCashLimitPopup(false);
      }
    }
  }, [cashSubtotal, userSettings, cartItems, cashLimitConfirmed]);
  
  const handleCashLimitPopupClose = (confirmed) => {
    setShowCashLimitPopup(false);
    if (confirmed) {
      setCashLimitConfirmed(true);
    }
  };

  const currencySymbols = {
    USD: '$', EUR: '€', GBP: '£', JPY: '¥', CAD: 'C$', AUD: 'A$', CHF: 'CHF', CNY: '¥', SEK: 'kr', NZD: 'NZ$',
    MXN: 'Mex$', SGD: 'S$', HKD: 'HK$', NOK: 'kr', KRW: '₩', TRY: '₺', RUB: '₽', INR: '₹', BRL: 'R$', ZAR: 'R'
  };

  const currencySymbol = currencySymbols[userSettings?.currency || 'USD'] || '$';
  const currency = userSettings?.currency || 'USD';
  
  // Function to update cart item details (condition, printing, etc.)
  const updateCartItemDetails = (updatedItem) => {
    // Instead of using setCartItems directly, we need to use the existing cart update functions
    // First remove the old item
    removeFromCart(updatedItem);
    
    // Then add the updated item back
    setTimeout(() => {
      try {
        addToCart(
          updatedItem,
          updatedItem.condition,
          updatedItem.printing,
          updatedItem.quantity,
          updatedItem.skuId,
          updatedItem.isCash,
          updatedItem.language || 'EN',
          updatedItem.orderNote || '',
          updatedItem.nonEnglish || null
        );
      } catch (err) {
        console.error(`Error updating item ${updatedItem.name}:`, err);
      }
    }, 0);
  };
  
  // Reference to the file input element
  const fileInputRef = useRef(null);

  // Function to trigger the file input click
  const triggerFileInput = () => {
    fileInputRef.current.click();
  };
  
  // Function to import cart items from CSV
  const importFromCSV = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    
    const reader = new FileReader();
    
    reader.onload = (e) => {
      try {
        const csvContent = e.target.result;
        const lines = csvContent.split('\n');
        
        // Skip header row and empty lines
        const dataRows = lines.filter((line, index) => index > 0 && line.trim() !== '');
        
        // Skip the last row if it's a summary row (contains "Total:")
        const itemRows = dataRows.filter(row => !row.includes('"Total:"'));
        
        // Parse CSV rows into cart items
        const importedItems = itemRows.map((row, index) => {
          // Split by comma, but respect quoted values
          const values = [];
          let inQuotes = false;
          let currentValue = '';
          
          for (let i = 0; i < row.length; i++) {
            const char = row[i];
            
            if (char === '"') {
              if (inQuotes && row[i + 1] === '"') {
                // Handle escaped quotes
                currentValue += '"';
                i++;
              } else {
                // Toggle quote state
                inQuotes = !inQuotes;
              }
            } else if (char === ',' && !inQuotes) {
              // End of value
              values.push(currentValue);
              currentValue = '';
            } else {
              currentValue += char;
            }
          }
          
          // Add the last value
          values.push(currentValue);
          
          // Extract values from the row
          const [name, condition, printing, language, quantity, priceType, price] = values;
          
          // Create a cart item object
          return {
            name: name.replace(/^"|"$/g, ''),
            condition: condition.replace(/^"|"$/g, ''),
            printing: printing.replace(/^"|"$/g, ''),
            language: language.replace(/^"|"$/g, ''),
            quantity: parseInt(quantity.replace(/^"|"$/g, ''), 10) || 1,
            isCash: priceType.replace(/^"|"$/g, '') === 'Cash',
            cashPrice: priceType.replace(/^"|"$/g, '') === 'Cash' ? price.replace(/^"|"$/g, '') : '0',
            creditPrice: priceType.replace(/^"|"$/g, '') === 'Credit' ? price.replace(/^"|"$/g, '') : '0',
            cartItemId: `imported-${index}-${Date.now()}` // Generate a unique ID
          };
        });
        
        // Set the cart items
        if (importedItems.length > 0) {
          setCartItems(importedItems);
          alert(`Successfully imported ${importedItems.length} items from CSV.`);
        } else {
          alert('No valid items found in the CSV file.');
        }
      } catch (error) {
        console.error('Error importing CSV:', error);
        alert('Error importing CSV file. Please check the file format.');
      }
      
      // Reset the file input
      event.target.value = '';
    };
    
    reader.onerror = () => {
      alert('Error reading the CSV file.');
      // Reset the file input
      event.target.value = '';
    };
    
    reader.readAsText(file);
  };
  
  // Function to export cart items to CSV
  const exportToCSV = () => {
    // Skip if cart is empty
    if (cartItems.length === 0) return;

    // Create CSV header - exactly 8 columns
    const csvHeader = ['Name', 'Condition', 'Printing', 'Language', 'Quantity', 'Price Type', 'Price', 'Total'];
    
    // Create CSV rows from cart items - ensure exactly 8 columns
    const csvRows = cartItems.map(item => {
      const price = item.isCash ? item.cashPrice : item.creditPrice;
      const totalPrice = (parseFloat(price) * item.quantity).toFixed(2);
      
      // Ensure exactly 8 columns
      return [
        item.name,
        item.condition,
        item.printing,
        item.language || 'EN',
        item.quantity,
        item.isCash ? 'Cash' : 'Credit',
        price, // Just the price without currency symbol
        totalPrice // Just the total price without currency symbol
      ];
    });
    
    // Add a summary row - ensure exactly 8 columns
    csvRows.push(['', '', '', '', '', '', 'Total:', total.toFixed(2)]);
    
    // Convert to CSV string - ensure proper escaping and no extra columns
    const csvContent = [
      csvHeader.join(','),
      ...csvRows.map(row => {
        // Ensure each row has exactly 8 columns
        const paddedRow = row.slice(0, 8); // Take only the first 8 columns
        while (paddedRow.length < 8) paddedRow.push(''); // Pad with empty strings if needed
        
        // Properly escape and quote each cell
        return paddedRow.map(cell => {
          // Convert to string and escape quotes
          const cellStr = String(cell).replace(/"/g, '""');
          return `"${cellStr}"`;
        }).join(',');
      })
    ].join('\n');
    
    // Create a Blob and download link
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    
    // Set up download attributes
    const date = new Date().toISOString().split('T')[0]; // YYYY-MM-DD format
    link.setAttribute('href', url);
    link.setAttribute('download', `cart-export-${date}.csv`);
    link.style.visibility = 'hidden';
    
    // Append to document, trigger download, and clean up
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  // Check if cash limit has been reached
  const isCashLimitReached = userSettings?.cash_limit && 
    cashSubtotal >= userSettings.cash_limit && 
    !cashLimitConfirmed;

  return (
    <div className="alt-cart">
      {showCashLimitPopup && (
        <CashLimitPopup
          onClose={handleCashLimitPopupClose}
          cashLimit={userSettings?.cash_limit}
          currencySymbol={currencySymbol}
        />
      )}
      <div className="alt-cart-header">
        <h2>Your Cart</h2>
        <div className="alt-cart-info">
          <span className="alt-cart-count">{totalItems} items</span>
          <span className="alt-cart-currency">{currency}</span>
        </div>
      </div>
      
      {/* Custom Buylist Items Button and Table removed - now shown in main content area */}
      
      <div className="alt-cart-items">
        {cartItems.length === 0 ? (
          <div className="alt-cart-empty-message">
            <p>Your cart is empty</p>
          </div>
        ) : (
          cartItems.map((item, index) => (
            <div className="alt-cart-item-wrapper" key={`wrapper-${item.cartItemId || index}`}>
              <div className="alt-payment-type-indicator">
                Currently: {item.isCash ? 'Cash' : 'Credit'} (click price to switch)
              </div>
              <div key={`${item._id || item.cartItemId}-${item.condition}-${item.printing}-${index}`} className="alt-cart-item">
                <div className="alt-cart-item-row">
                  <button 
                    className="alt-remove-button"
                    onClick={() => removeFromCart(item)}
                    aria-label={`Remove ${item.name} from cart`}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                      <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                    </svg>
                  </button>
                  
                  <div className="alt-item-thumbnail">
                    {item.image ? (
                      <img src={item.image} alt={item.name} />
                    ) : (
                      <div className="alt-item-thumbnail-placeholder">
                        <span>{item.name.charAt(0)}</span>
                      </div>
                    )}
                  </div>
                  
                  <div className="alt-item-info">
                    <div className="alt-item-name">{item.name}</div>
                    <div className="alt-item-details">
                      {item.isCustomItem ? (
                        <span className="alt-custom-item-label">Custom Item</span>
                      ) : (
                        <>
                          {/* Condition select */}
                          <select 
                            className="alt-item-condition-select"
                            value={item.condition}
                            onChange={(e) => {
                              const newCondition = e.target.value;
                              // Force recalculation with forceRecalculate=true
                              const priceInfo = calculatePrice(item, newCondition, item.printing, item.language, true);
                              console.log(`New price info for ${item.name} (${newCondition}):`, priceInfo);
                              const updatedItem = {
                                ...item,
                                condition: newCondition,
                                cashPrice: priceInfo.cashPrice,
                                creditPrice: priceInfo.creditPrice,
                                basePrice: priceInfo.basePrice
                              };
                              updateCartItemDetails(updatedItem);
                            }}
                          >
                            {allowedConditions.length > 0 
                              ? allowedConditions.map((cond, index) => (
                                  <option key={index} value={cond}>{cond}</option>
                                ))
                              : <option value={item.condition}>{item.condition}</option>
                            }
                          </select>
                          
                          {/* Printing select */}
                          <select 
                            className="alt-item-printing-select"
                            value={item.printing}
                            onChange={(e) => {
                              const newPrinting = e.target.value;
                              // Force recalculation with forceRecalculate=true
                              const priceInfo = calculatePrice(item, item.condition, newPrinting, item.language, true);
                              console.log(`New price info for ${item.name} (printing: ${newPrinting}):`, priceInfo);
                              const updatedItem = {
                                ...item,
                                printing: newPrinting,
                                cashPrice: priceInfo.cashPrice,
                                creditPrice: priceInfo.creditPrice,
                                basePrice: priceInfo.basePrice
                              };
                              updateCartItemDetails(updatedItem);
                            }}
                          >
                            {item.skus && Array.isArray(item.skus) 
                              ? [...new Set(item.skus.map(sku => sku.printingName))].map((printing, index) => (
                                  <option key={index} value={printing}>{printing}</option>
                                ))
                              : <option value={item.printing}>{item.printing}</option>
                            }
                          </select>
                        </>
                      )}
                      
                      <span className="alt-item-set">{item.expansionName || 'Unknown Set'}</span>
                      {item.language && item.language !== 'EN' && (
                        <span className="alt-item-language">{item.language}</span>
                      )}
                      {item.orderNote && (
                        <span className="alt-item-note" title={item.orderNote}>Non-English Card</span>
                      )}
                    </div>
                  </div>
                </div>
                
                <div className="alt-cart-item-row alt-cart-item-controls">
                  <div className="alt-quantity-control">
                    <button 
                      onClick={() => updateQuantity(item, item.quantity - 1)}
                      disabled={item.quantity <= 1}
                      className="alt-quantity-button"
                    >
                      −
                    </button>
                    <span className="alt-item-quantity">{item.quantity}</span>
                    <button 
                      onClick={() => updateQuantity(item, item.quantity + 1)}
                      disabled={item.quantity >= 4}
                      className="alt-quantity-button"
                    >
                      +
                    </button>
                  </div>
                  
                  <div 
                    className="alt-item-price-container" 
                    onClick={() => {
                      // Only allow switching to cash if cash limit is not reached or item is already cash
                      if (!isCashLimitReached || item.isCash) {
                        togglePaymentType(item);
                      } else {
                        alert("Cash limit reached. You cannot add more cash items until you reduce your cart's cash total or confirm the cash limit notification.");
                      }
                    }}
                  >
                    <div className="alt-item-price">
                      {currencySymbol}{item.isCash ? item.cashPrice : item.creditPrice}
                      {item.isDerivedPrice && (
                        <span className="derived-price-indicator" title={`Price calculated as ${item.derivedPercentage}% of EN price`}>*</span>
                      )}
                    </div>
                    <div className="alt-payment-type-indicator">
                      {item.isCash ? 'Cash' : 'Credit'} (click to switch)
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      
      <div className="alt-cart-summary">
        <div className="alt-summary-row">
          <span>Cash Subtotal:</span>
          <span>{currencySymbol}{cashSubtotal.toFixed(2)}</span>
        </div>
        <div className="alt-summary-row">
          <span>Credit Subtotal:</span>
          <span>{currencySymbol}{creditSubtotal.toFixed(2)}</span>
        </div>
        <div className="alt-summary-row alt-total-row">
          <span>Total:</span>
          <span>{currencySymbol}{total.toFixed(2)}</span>
        </div>
        <div className="alt-summary-note">
          <span>All prices in {currency}</span>
        </div>
      </div>
      
      <div className="alt-cart-actions">
        <button 
          className="alt-clear-cart-button"
          onClick={clearCart}
          disabled={cartItems.length === 0}
        >
          Clear Cart
        </button>
        <button 
          className="alt-checkout-button"
          onClick={checkout}
          disabled={cartItems.length === 0}
        >
          Proceed to Checkout
        </button>
        <div className="alt-cart-secondary-actions">
          <button 
            className="alt-export-csv-button"
            onClick={exportToCSV}
            disabled={cartItems.length === 0}
          >
            Export to CSV
          </button>
          <button 
            className="alt-import-csv-button"
            onClick={triggerFileInput}
          >
            Import from CSV
          </button>
          <input 
            type="file" 
            ref={fileInputRef} 
            style={{ display: 'none' }} 
            accept=".csv" 
            onChange={importFromCSV} 
          />
        </div>
        <div className="alt-cart-secondary-actions">
          <button 
            className="alt-save-for-later-button"
            onClick={onSaveForLater}
            disabled={cartItems.length === 0}
          >
            Save for Later
          </button>
          <button 
            className="alt-load-buylist-button"
            onClick={onLoadBuylist}
          >
            Load Saved Buylist
          </button>
        </div>
      </div>
    </div>
  );
};

export default AlternativeCart;
