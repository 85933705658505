import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import MobileHeader from './components/MobileHeader';
import MobileSearchBar from './components/MobileSearchBar';
import MobileExpansionSelector from './components/MobileExpansionSelector';
import MobileTileGrid from './components/MobileTileGrid';
import MobileCardList from './components/MobileCardList';
import MobileCart from './components/MobileCart';
import MobilePagination from './components/MobilePagination';
import MobileCheckoutPage from './components/MobileCheckoutPage';
import MobileGameSelector from './components/MobileGameSelector';
import { BACKEND_URL } from '../config';
import './MobileApp.css';

// Currency symbols for formatting prices
const currencySymbols = {
  USD: '$', EUR: '€', GBP: '£', JPY: '¥', CAD: 'C$', AUD: 'A$', CHF: 'CHF', CNY: '¥', SEK: 'kr', NZD: 'NZ$',
  MXN: 'Mex$', SGD: 'S$', HKD: 'HK$', NOK: 'kr', KRW: '₩', TRY: '₺', RUB: '₽', INR: '₹', BRL: 'R$', ZAR: 'R'
};

const MobileApp = ({
  enabledGames,
  cartItems,
  removeFromCart,
  checkout,
  updateQuantity,
  togglePaymentType,
  clearCart,
  userSettings,
  cards,
  addToCart,
  allowedConditions,
  floorPrice,
  bulkSettings,
  excludedSku,
  calculatePrice,
  exchangeRates,
  handleGameChange,
  handleSetChange,
  searchParams,
  setSearchParams,
  isLoading,
  isInitialLoad,
  setIsInitialLoad,
  customItems,
  fetchCustomItems
}) => {
  const { t } = useTranslation();
  const [activeView, setActiveView] = useState('home'); // 'home', 'search', 'cart', 'checkout', 'customItems'
  const [searchTerm, setSearchTerm] = useState('');
  const [showCart, setShowCart] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedGame, setSelectedGame] = useState(searchParams.game || '');
  const [userData, setUserData] = useState(null);
  
  // Function to fetch user data from the collection
  const fetchUserData = useCallback(async () => {
    if (!userSettings || !userSettings.username) return;
    
    try {
      const response = await fetch(`${BACKEND_URL}/user/${userSettings.username}`);
      if (response.ok) {
        const data = await response.json();
        console.log('User data fetched:', data);
        setUserData(data);
      } else {
        console.error('Failed to fetch user data');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, [userSettings, BACKEND_URL]);
  
  // Fetch user data when userSettings changes
  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  // Toggle cart visibility when items are added
  useEffect(() => {
    if (cartItems.length > 0 && !showCart) {
      // Don't automatically show cart on mobile, just update the badge
    }
  }, [cartItems.length, showCart]);

  // Update selected game when searchParams.game changes
  useEffect(() => {
    if (searchParams.game) {
      setSelectedGame(searchParams.game);
    }
  }, [searchParams.game]);

  const handleSearchSubmit = (term) => {
    setSearchTerm(term);
    setSearchParams(prev => ({
      ...prev,
      term: term,
      page: 1
    }));
    setIsInitialLoad(false);
    setActiveView('search');
  };

  const handlePageChange = (newPage) => {
    setSearchParams(prev => ({
      ...prev,
      page: parseInt(newPage)
    }));
    // Scroll to top when changing pages
    window.scrollTo(0, 0);
  };

  const handleGameSelect = (game) => {
    setSelectedGame(game);
    handleGameChange({ target: { value: game } });
  };

  const toggleCartView = () => {
    setShowCart(!showCart);
    if (!showCart) {
      setActiveView('cart');
    } else {
      setActiveView('search');
    }
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };


  return (
    <div className="mobile-app">
      <MobileHeader 
        cartItemCount={cartItems.reduce((total, item) => total + item.quantity, 0)} 
        onCartClick={toggleCartView}
        onHomeClick={() => setActiveView('home')}
        onFiltersClick={toggleFilters}
        activeView={activeView}
      />

      {activeView === 'home' && (
        <div className="mobile-home-view">
          <MobileSearchBar onSearch={handleSearchSubmit} />
          
          <div className="mobile-game-tiles">
            <h2>{t('Select a Game')}</h2>
            <MobileTileGrid 
              games={enabledGames}
              onGameSelect={(game) => {
                handleGameChange({ target: { value: game } });
                setSelectedGame(game);
                setActiveView('search');
              }}
            />
            
            {customItems && customItems.length > 0 && (
              <div className="mobile-custom-items-tile-container">
                <div 
                  className="mobile-custom-items-tile"
                  onClick={() => setActiveView('customItems')}
                >
                  <div className="mobile-custom-items-tile-content">
                    <div className="mobile-custom-items-tile-name">{userSettings?.custom_buylist_button_label || t('We Also Buy')}</div>
                  </div>
                </div>
              </div>
            )}
            
            {userData && userData.website && (
              <div className="mobile-store-website-tile-container">
                <div 
                  className="mobile-store-website-tile"
                  onClick={() => window.open(userData.website, '_blank')}
                >
                  <div className="mobile-store-website-tile-content">
                    <div className="mobile-store-website-tile-name">{t('Back to Store')}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {activeView === 'search' && (
        <div className="mobile-search-view">
          <MobileSearchBar 
            onSearch={handleSearchSubmit} 
            initialValue={searchTerm}
          />
          
          {selectedGame && (
            <MobileGameSelector 
              games={enabledGames}
              selectedGame={selectedGame}
              onGameChange={handleGameSelect}
            />
          )}
          
          
          {showFilters && (
            <div className="mobile-filters">
              <MobileExpansionSelector 
                allExpansionNames={searchParams.allExpansionNames || []}
                searchParams={searchParams}
                handleSetChange={handleSetChange}
              />
            </div>
          )}
          
          {isLoading ? (
            <div className="mobile-loading">
              <p>{t('Loading...')}</p>
            </div>
          ) : isInitialLoad ? (
            <div className="mobile-initial-load">
              <p>{t('Search for cards to get started')}</p>
            </div>
          ) : (
            <>
              <MobileCardList 
                cards={cards}
                addToCart={addToCart}
                allowedConditions={allowedConditions}
                calculatePrice={calculatePrice}
                userSettings={userSettings}
                excludedSku={excludedSku}
              />
              
              {cards.length > 0 && searchParams.totalPages > 1 && (
                <MobilePagination 
                  currentPage={searchParams.page}
                  totalPages={searchParams.totalPages}
                  onPageChange={handlePageChange}
                />
              )}
            </>
          )}
        </div>
      )}

      {activeView === 'cart' && (
        <MobileCart 
          cartItems={cartItems}
          removeFromCart={removeFromCart}
          updateQuantity={updateQuantity}
          togglePaymentType={togglePaymentType}
          checkout={() => setActiveView('checkout')}
          clearCart={clearCart}
          userSettings={userSettings}
          onClose={() => {
            setActiveView('search');
            setShowCart(false);
          }}
        />
      )}

      {activeView === 'customItems' && (
        <div className="mobile-custom-items-view">
          <div className="mobile-view-header">
            <button 
              className="mobile-back-button"
              onClick={() => setActiveView('home')}
            >
              ← {t('Back')}
            </button>
            <h2>{userSettings?.custom_buylist_button_label || t('We Also Buy')}</h2>
          </div>
          
          <div className="mobile-custom-items-container">
            {customItems.map((item) => (
              <div key={item._id.$oid} className="mobile-custom-item">
                <div className="mobile-custom-item-title">{item.title}</div>
                <div className="mobile-custom-item-description">{item.description}</div>
                
                <div className="mobile-custom-item-price">
                  <div className="mobile-custom-item-price-label">{t('Cash Price')}:</div>
                  <div className="mobile-custom-item-price-value">
                    {currencySymbols[userSettings?.currency] || '$'}{item.cash_price}
                  </div>
                </div>
                
                <div className="mobile-custom-item-price">
                  <div className="mobile-custom-item-price-label">{t('Credit Price')}:</div>
                  <div className="mobile-custom-item-price-value">
                    {currencySymbols[userSettings?.currency] || '$'}{item.credit_price}
                  </div>
                </div>
                
                <div className="mobile-custom-item-quantity">
                  <div className="mobile-custom-item-quantity-label">{t('Quantity')}:</div>
                  <input 
                    type="number" 
                    min="1" 
                    max="999" 
                    defaultValue="1"
                    className="mobile-custom-item-quantity-input"
                    id={`quantity-${item._id.$oid}`}
                  />
                </div>
                
                <div className="mobile-custom-item-actions">
                  <button 
                    className="mobile-custom-item-add-cash"
                    onClick={() => {
                      const quantity = parseInt(document.getElementById(`quantity-${item._id.$oid}`).value);
                      const customItem = {
                        name: item.title,
                        description: item.description,
                        cashPrice: item.cash_price,
                        creditPrice: item.credit_price,
                        quantity: quantity,
                        isCash: true,
                        cartItemId: `custom-${item._id.$oid}-${Date.now()}`,
                        condition: 'N/A',
                        printing: 'N/A',
                        isCustomItem: true
                      };
                      addToCart(customItem);
                    }}
                  >
                    {t('Add for Cash')}
                  </button>
                  <button 
                    className="mobile-custom-item-add-credit"
                    onClick={() => {
                      const quantity = parseInt(document.getElementById(`quantity-${item._id.$oid}`).value);
                      const customItem = {
                        name: item.title,
                        description: item.description,
                        cashPrice: item.cash_price,
                        creditPrice: item.credit_price,
                        quantity: quantity,
                        isCash: false,
                        cartItemId: `custom-${item._id.$oid}-${Date.now()}`,
                        condition: 'N/A',
                        printing: 'N/A',
                        isCustomItem: true
                      };
                      addToCart(customItem);
                    }}
                  >
                    {t('Add for Credit')}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {activeView === 'checkout' && (
        <MobileCheckoutPage
          cartItems={cartItems}
          updateQuantity={updateQuantity}
          removeFromCart={removeFromCart}
          completeCheckout={checkout}
          cancelCheckout={() => setActiveView('cart')}
          goToMainPage={() => setActiveView('home')}
          userSettings={userSettings}
          username={userSettings?.username}
          calculatePrice={calculatePrice}
          togglePaymentType={togglePaymentType}
          resetApp={() => {
            clearCart();
            setActiveView('home');
          }}
          clearCart={clearCart}
        />
      )}

    </div>
  );
};

export default MobileApp;
