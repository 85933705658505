import React from 'react';
import './MobileExpansionSelector.css';

const MobileExpansionSelector = ({ allExpansionNames, searchParams, handleSetChange }) => {
  const handleExpansionClick = (expansion) => {
    // Create a synthetic event object with a target.value property
    const event = { target: { value: expansion } };
    handleSetChange(event);
  };

  return (
    <div className="mobile-expansion-selector">
      <label className="mobile-selector-label">
        Set / Expansion
      </label>
      <div className="mobile-expansion-buttons">
        <button
          className={`mobile-expansion-button ${!searchParams.set ? 'active' : ''}`}
          onClick={() => handleExpansionClick('')}
        >
          All Sets
        </button>
        {allExpansionNames && allExpansionNames.map((expansion, index) => (
          <button
            key={index}
            className={`mobile-expansion-button ${searchParams.set === expansion ? 'active' : ''}`}
            onClick={() => handleExpansionClick(expansion)}
          >
            {expansion}
          </button>
        ))}
      </div>
    </div>
  );
};

export default MobileExpansionSelector;
