import React, { useState, useEffect } from 'react';
import Sidebar from './components/Sidebar';
import GameGrid from './components/GameGrid';
import AlternativeCart from './components/AlternativeCart';
import CardList from '../components/CardList';
import SearchBar from './components/SearchBar';
import ExpansionSelector from './components/ExpansionSelector';
import RaritySelector from './components/RaritySelector';
import Pagination from './components/Pagination';
import SaveBuylistPopup from '../components/SaveBuylistPopup';
import LoadBuylistPopup from '../components/LoadBuylistPopup';
import { useNotification } from '../context/NotificationContext';
import './AlternativeApp.css';

const AlternativeApp = ({ 
  enabledGames, 
  cartItems, 
  removeFromCart, 
  checkout, 
  updateQuantity, 
  togglePaymentType, 
  clearCart, 
  userSettings,
  cards,
  addToCart,
  allowedConditions,
  floorPrice,
  bulkSettings,
  excludedSku,
  calculatePrice,
  exchangeRates,
  handleGameChange,
  handleSetChange,
  searchParams,
  setSearchParams,
  isLoading,
  isInitialLoad,
  setIsInitialLoad,
  customItems = [],
  fetchCustomItems = () => {}
}) => {
  const [showCardList, setShowCardList] = useState(false);
  const [showCustomItemsList, setShowCustomItemsList] = useState(false);
  // These state values are used in CardList component
  const [showBulkItems, setShowBulkItems] = useState(() => {
    const saved = localStorage.getItem('showBulkItems');
    return saved !== null ? JSON.parse(saved) : true;
  });

  // Listen for bulk items toggle events from CardList component
  useEffect(() => {
    const handleBulkItemsToggle = (event) => {
      setShowBulkItems(event.detail.showBulkItems);
    };
    
    window.addEventListener('bulkItemsToggle', handleBulkItemsToggle);
    
    return () => {
      window.removeEventListener('bulkItemsToggle', handleBulkItemsToggle);
    };
  }, []);
  const [isTableView] = useState(() => {
    const saved = localStorage.getItem('isTableView');
    return saved !== null ? JSON.parse(saved) : false;
  });
  const [showSaveBuylistPopup, setShowSaveBuylistPopup] = useState(false);
  const [showLoadBuylistPopup, setShowLoadBuylistPopup] = useState(false);

  const handleGameSelect = (game) => {
    setShowCardList(true);
    setShowCustomItemsList(false);
    setIsInitialLoad(false);
    
    setSearchParams(prev => ({
      ...prev,
      game: game,
      set: '',
      page: 1
    }));
  };

  // This function is kept for future use
  // eslint-disable-next-line no-unused-vars
  const handleBackToGrid = () => {
    setShowCardList(false);
  };

  const handleOpenSaveBuylistPopup = () => {
    setShowSaveBuylistPopup(true);
  };

  const handleCloseSaveBuylistPopup = () => {
    setShowSaveBuylistPopup(false);
  };

  const handleOpenLoadBuylistPopup = () => {
    setShowLoadBuylistPopup(true);
  };

  const handleCloseLoadBuylistPopup = () => {
    setShowLoadBuylistPopup(false);
  };
  
  const handleRarityChange = (e) => {
    // Close the custom buylist section when changing rarity
    const altCart = document.querySelector('.alt-cart');
    if (altCart) {
      const customItemsEvent = new CustomEvent('closeCustomItems');
      altCart.dispatchEvent(customItemsEvent);
    }
    
    setSearchParams(prev => ({
      ...prev,
      rarity: e.target.value,
      page: 1
    }));
  };
  
  // Override the handleSetChange prop to close custom items when changing set
  const handleSetChangeWithClose = (e) => {
    // Close the custom buylist section when changing set
    const altCart = document.querySelector('.alt-cart');
    if (altCart) {
      const customItemsEvent = new CustomEvent('closeCustomItems');
      altCart.dispatchEvent(customItemsEvent);
    }
    
    // Call the original handleSetChange from props
    handleSetChange(e);
  };

  const { showSuccess } = useNotification();

  const handleLoadBuylist = (savedBuylist) => {
    console.log('Loading saved buylist:', savedBuylist);
    
    // Clear the current cart first
    removeFromCart({ cartItemId: 'all' });
    
    // Add each item directly to the cart with original prices
    for (const item of savedBuylist.cart_items) {
      try {
        // Add to cart using the addToCart function from props
        addToCart(
          item,
          item.condition,
          item.printing,
          item.quantity,
          item.skuId,
          item.isCash,
          item.language || 'EN',
          item.orderNote || '',
          item.nonEnglish || null
        );
      } catch (err) {
        console.error(`Error adding item ${item.name} to cart:`, err);
      }
    }
    
    // Close the popup
    setShowLoadBuylistPopup(false);
    
    // Show a success message with item count
    const itemCount = savedBuylist.cart_items.length;
    showSuccess(`Buylist loaded successfully! Added ${itemCount} item${itemCount !== 1 ? 's' : ''} to your cart.`);
  };

  return (
    <div className="alt-app">
      {showSaveBuylistPopup && (
        <SaveBuylistPopup
          onClose={handleCloseSaveBuylistPopup}
          cartItems={cartItems}
          username={userSettings?.username || 'guest'}
        />
      )}
      {showLoadBuylistPopup && (
        <LoadBuylistPopup
          onClose={handleCloseLoadBuylistPopup}
          onLoadBuylist={handleLoadBuylist}
          username={userSettings?.username || 'guest'}
        />
      )}
      {/* Price change notification removed as we're keeping original prices */}
      <div className="alt-main-container">
        <div className="alt-sidebar-container">
          <Sidebar 
            enabledGames={enabledGames} 
            onGameSelect={handleGameSelect}
            customItems={customItems}
            userSettings={userSettings}
            onCustomItemsClick={() => {
              // Show custom items in the main content area
              setShowCardList(false);
              setShowCustomItemsList(true);
            }}
          />
        </div>
        
        <div className="alt-content-container">
          {showCustomItemsList ? (
            <div className="alt-custom-items-view">
              <h2>{userSettings?.custom_buylist_button_label || 'Other Items We Buy'}</h2>
              <div className="alt-custom-items-list">
                <table className="alt-custom-items-table">
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Description</th>
                      <th>Cash Price</th>
                      <th>Credit Price</th>
                      <th>Quantity</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customItems.map((item) => (
                      <tr key={item._id.$oid}>
                        <td>{item.title}</td>
                        <td>{item.description}</td>
                        <td>{userSettings?.currency || 'USD'} {item.cash_price}</td>
                        <td>{userSettings?.currency || 'USD'} {item.credit_price}</td>
                        <td>
                          <input 
                            type="number" 
                            min="1" 
                            max="999" 
                            defaultValue="1"
                            className="alt-custom-item-quantity"
                          />
                        </td>
                        <td>
                          <button 
                            className="alt-add-custom-item-cash"
                            onClick={(e) => {
                              const quantity = parseInt(e.target.parentNode.parentNode.querySelector('.alt-custom-item-quantity').value);
                              const customItem = {
                                name: item.title,
                                description: item.description,
                                cashPrice: item.cash_price,
                                creditPrice: item.credit_price,
                                quantity: quantity,
                                isCash: true,
                                cartItemId: `custom-${item._id.$oid}-${Date.now()}`,
                                condition: 'N/A',
                                printing: 'N/A',
                                isCustomItem: true
                              };
                              addToCart(
                                customItem,
                                customItem.condition,
                                customItem.printing,
                                customItem.quantity,
                                null,
                                customItem.isCash,
                                'EN',
                                '',
                                null
                              );
                              showSuccess(`Added ${item.title} to cart (Cash)`);
                            }}
                          >
                            Add (Cash)
                          </button>
                          <button 
                            className="alt-add-custom-item-credit"
                            onClick={(e) => {
                              const quantity = parseInt(e.target.parentNode.parentNode.querySelector('.alt-custom-item-quantity').value);
                              const customItem = {
                                name: item.title,
                                description: item.description,
                                cashPrice: item.cash_price,
                                creditPrice: item.credit_price,
                                quantity: quantity,
                                isCash: false,
                                cartItemId: `custom-${item._id.$oid}-${Date.now()}`,
                                condition: 'N/A',
                                printing: 'N/A',
                                isCustomItem: true
                              };
                              addToCart(
                                customItem,
                                customItem.condition,
                                customItem.printing,
                                customItem.quantity,
                                null,
                                customItem.isCash,
                                'EN',
                                '',
                                null
                              );
                              showSuccess(`Added ${item.title} to cart (Credit)`);
                            }}
                          >
                            Add (Credit)
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : showCardList ? (
            <div className="alt-card-list-view">
              <div className="alt-search-container">
                <SearchBar 
                  searchParams={searchParams} 
                  setSearchParams={setSearchParams} 
                  setIsInitialLoad={setIsInitialLoad}
                />
                
                <ExpansionSelector 
                  allExpansionNames={searchParams.allExpansionNames || []}
                  searchParams={searchParams}
                  handleSetChange={handleSetChangeWithClose}
                />
                
                <RaritySelector 
                  allRarities={searchParams.allRarities || []}
                  searchParams={searchParams}
                  handleRarityChange={handleRarityChange}
                />
              </div>
              
              {isLoading ? (
                <div className="alt-loading">Loading...</div>
              ) : (
                <>
                  {/* Filter bulk items before rendering if showBulkItems is false */}
                  {(() => {
                    // Calculate prices for all cards to determine which are bulk
                    const cardsWithPrices = cards.map(card => {
                      const cardConditions = [...new Set(card.skus
                        .map(sku => sku.condAbbr)
                        .filter(cond => allowedConditions.includes(cond)))];
                      const uniquePrintings = [...new Set(card.skus.map(sku => sku.printingName))];
                      const condition = cardConditions[0] || '';
                      const printing = uniquePrintings[0] || '';
                      const price = calculatePrice(card, condition, printing);
                      return { ...card, price };
                    });
                    
                    // Filter out bulk items if showBulkItems is false
                    const filteredCards = showBulkItems 
                      ? cardsWithPrices 
                      : cardsWithPrices.filter(card => {
                          // Get the price info for this card
                          const priceInfo = card.price || {};
                          
                          // Check if this is a bulk item
                          // In the UI, a card shows a "Bulk Item" button when:
                          // 1. It has isBulk explicitly set to true, OR
                          // 2. Both cash and credit prices are 0 or not available
                          const isBulkItem = priceInfo.isBulk === true;
                          
                          // Log for debugging
                          console.log(`Card: ${card.name}, isBulk: ${priceInfo.isBulk}`);
                          
                          // Keep the card only if it's NOT a bulk item
                          return !isBulkItem;
                        });
                    
                    // We'll use the server-provided total pages for pagination
                    // This prevents client-side filtering from interfering with server pagination
                    const totalFilteredPages = showBulkItems 
                      ? searchParams.totalPages 
                      : Math.ceil(filteredCards.length / 20); // 20 items per page for client-side filtering
                    
                    return (
                      <>
                        <CardList
                          cards={filteredCards}
                          addToCart={addToCart}
                          allowedConditions={allowedConditions}
                          floorPrice={floorPrice}
                          bulkSettings={bulkSettings}
                          userSettings={userSettings}
                          excludedSku={excludedSku}
                          calculatePrice={calculatePrice}
                          exchangeRates={exchangeRates}
                          showBulkItems={true} // Always true since we've already filtered
                          isTableView={isTableView}
                          isCashLimitReached={userSettings?.cash_limit && 
                            cartItems.reduce((sum, item) => sum + (item.isCash ? parseFloat(item.cashPrice) * item.quantity : 0), 0) >= userSettings.cash_limit}
                        />
                        
                        {/* Pagination or "Showing all results" message */}
                        <div className="alt-pagination-container">
                          {(showBulkItems ? searchParams.totalPages : totalFilteredPages) > 1 ? (
                            <Pagination
                              currentPage={parseInt(searchParams.page) || 1}
                              totalPages={showBulkItems ? searchParams.totalPages : totalFilteredPages}
                              onPageChange={(page) => {
                                // Close the custom buylist section when changing page
                                const altCart = document.querySelector('.alt-cart');
                                if (altCart) {
                                  const customItemsEvent = new CustomEvent('closeCustomItems');
                                  altCart.dispatchEvent(customItemsEvent);
                                }
                                
                                setSearchParams(prev => ({
                                  ...prev,
                                  page: page
                                }));
                              }}
                            />
                          ) : (
                            filteredCards.length > 0 && (
                              <div className="showing-all-results">
                                Showing all {filteredCards.length} results
                              </div>
                            )
                          )}
                        </div>
                      </>
                    );
                  })()}
                </>
              )}
            </div>
          ) : (
            <GameGrid 
              enabledGames={enabledGames} 
              onGameSelect={handleGameSelect} 
            />
          )}
        </div>
        
        <div className="alt-cart-container">
          <AlternativeCart 
            cartItems={cartItems}
            removeFromCart={removeFromCart}
            checkout={checkout}
            updateQuantity={updateQuantity}
            togglePaymentType={togglePaymentType}
            clearCart={clearCart}
            userSettings={userSettings}
            onSaveForLater={handleOpenSaveBuylistPopup}
            onLoadBuylist={handleOpenLoadBuylistPopup}
            customItems={customItems}
            fetchCustomItems={fetchCustomItems}
            setCartItems={setCartItems => {
              // Clear the current cart first
              removeFromCart({ cartItemId: 'all' });
              
              // Add each item to the cart
              for (const item of setCartItems) {
                try {
                  addToCart(
                    item,
                    item.condition,
                    item.printing,
                    item.quantity,
                    item.skuId,
                    item.isCash,
                    item.language || 'EN',
                    item.orderNote || '',
                    item.nonEnglish || null
                  );
                } catch (err) {
                  console.error(`Error adding item ${item.name} to cart:`, err);
                }
              }
            }}
            calculatePrice={calculatePrice}
            allowedConditions={allowedConditions}
            addToCart={addToCart}
          />
        </div>
      </div>
    </div>
  );
};

export default AlternativeApp;
