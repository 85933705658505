import React, { useState, useEffect, useRef } from 'react';
import './Cart.css';
import CashLimitPopup from './CashLimitPopup';
import { BACKEND_URL } from '../config';

const Cart = ({ 
  cartItems, 
  removeFromCart, 
  checkout = () => {}, 
  updateQuantity, 
  togglePaymentType, 
  openEmailValuationForm, 
  userSettings, 
  clearCart, 
  onSaveForLater = () => {}, 
  onLoadBuylist = () => {}, 
  calculatePrice = () => ({}), 
  allowedConditions = [], 
  setCartItems = () => {}, 
  hideCheckoutButton = false 
}) => {
  // Function to update cart item details (condition, printing, etc.)
  const updateCartItemDetails = (updatedItem) => {
    // Create a direct setter function to update the cart items
    setCartItems(prevItems => prevItems.map(item => 
      item.cartItemId === updatedItem.cartItemId ? updatedItem : item
    ));
  };
  const [showCashLimitPopup, setShowCashLimitPopup] = useState(false);
  const [cashLimitConfirmed, setCashLimitConfirmed] = useState(false);
  const [customItems, setCustomItems] = useState([]);
  const [showCustomItems, setShowCustomItems] = useState(false);
  const [isLoadingCustomItems, setIsLoadingCustomItems] = useState(false);
  
  const cashSubtotal = cartItems.reduce((sum, item) => sum + (item.isCash ? parseFloat(item.cashPrice) * item.quantity : 0), 0);
  const creditSubtotal = cartItems.reduce((sum, item) => sum + (!item.isCash ? parseFloat(item.creditPrice) * item.quantity : 0), 0);
  const total = cartItems.reduce((sum, item) => sum + (item.isCash ? parseFloat(item.cashPrice) : parseFloat(item.creditPrice)) * item.quantity, 0);
  
  // Fetch custom buylist items when username changes
  useEffect(() => {
    if (userSettings && userSettings.username) {
      fetchCustomItems(userSettings.username);
    }
  }, [userSettings]);

  // Function to fetch custom buylist items
  const fetchCustomItems = async (username) => {
    try {
      setIsLoadingCustomItems(true);
      const response = await fetch(`${BACKEND_URL}/custom-buylist/${username}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setCustomItems(data.items || []);
    } catch (error) {
      console.error('Error fetching custom buylist items:', error);
    } finally {
      setIsLoadingCustomItems(false);
    }
  };

  // Check if cash limit is exceeded
  useEffect(() => {
    // Only check if we have user settings and there are items in the cart
    if (userSettings && cartItems.length > 0) {
      const cashLimit = userSettings.cash_limit;
      
      // If cash_limit is defined, not 0, and the cash subtotal exceeds it
      if (cashLimit !== undefined && cashLimit !== 0 && cashSubtotal > cashLimit) {
        // Only show the popup if it hasn't been confirmed yet
        if (!cashLimitConfirmed) {
          setShowCashLimitPopup(true);
        }
      } else {
        // Reset if the cash subtotal goes back below the limit
        setShowCashLimitPopup(false);
      }
    }
  }, [cashSubtotal, userSettings, cartItems, cashLimitConfirmed]);
  
  const handleCashLimitPopupClose = (confirmed) => {
    setShowCashLimitPopup(false);
    if (confirmed) {
      setCashLimitConfirmed(true);
    }
  };

  const currencySymbols = {
    USD: '$', EUR: '€', GBP: '£', JPY: '¥', CAD: 'C$', AUD: 'A$', CHF: 'CHF', CNY: '¥', SEK: 'kr', NZD: 'NZ$',
    MXN: 'Mex$', SGD: 'S$', HKD: 'HK$', NOK: 'kr', KRW: '₩', TRY: '₺', RUB: '₽', INR: '₹', BRL: 'R$', ZAR: 'R'
  };

  const currencySymbol = currencySymbols[userSettings?.currency || 'USD'] || '$';

  const handleClearCart = () => {
    console.log('Clear All button clicked');
    clearCart();
  };
  
  // Reference to the file input element
  const fileInputRef = useRef(null);

  // Function to trigger the file input click
  const triggerFileInput = () => {
    fileInputRef.current.click();
  };
  
  // Function to import cart items from CSV
  const importFromCSV = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    
    const reader = new FileReader();
    
    reader.onload = (e) => {
      try {
        const csvContent = e.target.result;
        const lines = csvContent.split('\n');
        
        // Skip header row and empty lines
        const dataRows = lines.filter((line, index) => index > 0 && line.trim() !== '');
        
        // Skip the last row if it's a summary row (contains "Total:")
        const itemRows = dataRows.filter(row => !row.includes('"Total:"'));
        
        // Parse CSV rows into cart items
        const importedItems = itemRows.map((row, index) => {
          // Split by comma, but respect quoted values
          const values = [];
          let inQuotes = false;
          let currentValue = '';
          
          for (let i = 0; i < row.length; i++) {
            const char = row[i];
            
            if (char === '"') {
              if (inQuotes && row[i + 1] === '"') {
                // Handle escaped quotes
                currentValue += '"';
                i++;
              } else {
                // Toggle quote state
                inQuotes = !inQuotes;
              }
            } else if (char === ',' && !inQuotes) {
              // End of value
              values.push(currentValue);
              currentValue = '';
            } else {
              currentValue += char;
            }
          }
          
          // Add the last value
          values.push(currentValue);
          
          // Extract values from the row
          const [name, condition, printing, language, quantity, priceType, price] = values;
          
          // Create a cart item object
          return {
            name: name.replace(/^"|"$/g, ''),
            condition: condition.replace(/^"|"$/g, ''),
            printing: printing.replace(/^"|"$/g, ''),
            language: language.replace(/^"|"$/g, ''),
            quantity: parseInt(quantity.replace(/^"|"$/g, ''), 10) || 1,
            isCash: priceType.replace(/^"|"$/g, '') === 'Cash',
            cashPrice: priceType.replace(/^"|"$/g, '') === 'Cash' ? price.replace(/^"|"$/g, '') : '0',
            creditPrice: priceType.replace(/^"|"$/g, '') === 'Credit' ? price.replace(/^"|"$/g, '') : '0',
            cartItemId: `imported-${index}-${Date.now()}` // Generate a unique ID
          };
        });
        
        // Set the cart items
        if (importedItems.length > 0) {
          setCartItems(importedItems);
          alert(`Successfully imported ${importedItems.length} items from CSV.`);
        } else {
          alert('No valid items found in the CSV file.');
        }
      } catch (error) {
        console.error('Error importing CSV:', error);
        alert('Error importing CSV file. Please check the file format.');
      }
      
      // Reset the file input
      event.target.value = '';
    };
    
    reader.onerror = () => {
      alert('Error reading the CSV file.');
      // Reset the file input
      event.target.value = '';
    };
    
    reader.readAsText(file);
  };
  
  // Function to export cart items to CSV
  const exportToCSV = () => {
    // Skip if cart is empty
    if (cartItems.length === 0) return;

    // Create CSV header - exactly 8 columns
    const csvHeader = ['Name', 'Condition', 'Printing', 'Language', 'Quantity', 'Price Type', 'Price', 'Total'];
    
    // Create CSV rows from cart items - ensure exactly 8 columns
    const csvRows = cartItems.map(item => {
      const price = item.isCash ? item.cashPrice : item.creditPrice;
      const totalPrice = (parseFloat(price) * item.quantity).toFixed(2);
      
      // Ensure exactly 8 columns
      return [
        item.name,
        item.condition,
        item.printing,
        item.language || 'EN',
        item.quantity,
        item.isCash ? 'Cash' : 'Credit',
        price, // Just the price without currency symbol
        totalPrice // Just the total price without currency symbol
      ];
    });
    
    // Add a summary row - ensure exactly 8 columns
    csvRows.push(['', '', '', '', '', '', 'Total:', total.toFixed(2)]);
    
    // Convert to CSV string - ensure proper escaping and no extra columns
    const csvContent = [
      csvHeader.join(','),
      ...csvRows.map(row => {
        // Ensure each row has exactly 8 columns
        const paddedRow = row.slice(0, 8); // Take only the first 8 columns
        while (paddedRow.length < 8) paddedRow.push(''); // Pad with empty strings if needed
        
        // Properly escape and quote each cell
        return paddedRow.map(cell => {
          // Convert to string and escape quotes
          const cellStr = String(cell).replace(/"/g, '""');
          return `"${cellStr}"`;
        }).join(',');
      })
    ].join('\n');
    
    // Create a Blob and download link
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    
    // Set up download attributes
    const date = new Date().toISOString().split('T')[0]; // YYYY-MM-DD format
    link.setAttribute('href', url);
    link.setAttribute('download', `cart-export-${date}.csv`);
    link.style.visibility = 'hidden';
    
    // Append to document, trigger download, and clean up
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="cart">
      {showCashLimitPopup && (
        <CashLimitPopup
          onClose={handleCashLimitPopupClose}
          cashLimit={userSettings?.cash_limit}
          currencySymbol={currencySymbol}
        />
      )}
      <h2 className="cart-title">Your Cart</h2>
      
      {/* Dedicated button for loading saved buylists */}
      <button 
        className="load-buylist-button-large"
        onClick={onLoadBuylist}
        disabled={onLoadBuylist === (() => {})}
      >
        Load Saved Buylist
      </button>
      
      {/* Custom Buylist Items Button */}
      {customItems.length > 0 && (
        <button 
          className="custom-buylist-button"
          onClick={() => setShowCustomItems(!showCustomItems)}
        >
          {showCustomItems ? 'Hide Custom Items' : 'We Also Buy'}
        </button>
      )}
      
      {/* Custom Buylist Items Table */}
      {showCustomItems && customItems.length > 0 && (
        <div className="custom-buylist-container">
          <h3>Custom Items We Buy</h3>
          <table className="custom-buylist-table">
            <thead>
              <tr>
                <th>Item</th>
                <th>Description</th>
                <th>Cash Price</th>
                <th>Credit Price</th>
                <th>Quantity</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {customItems.map((item) => (
                <tr key={item._id.$oid}>
                  <td>{item.title}</td>
                  <td>{item.description}</td>
                  <td>{currencySymbol}{item.cash_price}</td>
                  <td>{currencySymbol}{item.credit_price}</td>
                  <td>
                    <input 
                      type="number" 
                      min="1" 
                      max="999" 
                      defaultValue="1"
                      className="custom-item-quantity"
                    />
                  </td>
                  <td>
                    <button 
                      className="add-custom-item-cash"
                      onClick={(e) => {
                        const quantity = parseInt(e.target.parentNode.parentNode.querySelector('.custom-item-quantity').value);
                        const customItem = {
                          name: item.title,
                          description: item.description,
                          cashPrice: item.cash_price,
                          creditPrice: item.credit_price,
                          quantity: quantity,
                          isCash: true,
                          cartItemId: `custom-${item._id.$oid}-${Date.now()}`,
                          condition: 'N/A',
                          printing: 'N/A',
                          isCustomItem: true
                        };
                        setCartItems(prevItems => [...prevItems, customItem]);
                      }}
                    >
                      Add (Cash)
                    </button>
                    <button 
                      className="add-custom-item-credit"
                      onClick={(e) => {
                        const quantity = parseInt(e.target.parentNode.parentNode.querySelector('.custom-item-quantity').value);
                        const customItem = {
                          name: item.title,
                          description: item.description,
                          cashPrice: item.cash_price,
                          creditPrice: item.credit_price,
                          quantity: quantity,
                          isCash: false,
                          cartItemId: `custom-${item._id.$oid}-${Date.now()}`,
                          condition: 'N/A',
                          printing: 'N/A',
                          isCustomItem: true
                        };
                        setCartItems(prevItems => [...prevItems, customItem]);
                      }}
                    >
                      Add (Credit)
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      
      <div className="cart-actions">
        <button 
          className="import-csv-button"
          onClick={triggerFileInput}
        >
          Import from CSV
        </button>
        <input 
          type="file" 
          ref={fileInputRef} 
          style={{ display: 'none' }} 
          accept=".csv" 
          onChange={importFromCSV} 
        />
      </div>
      {cartItems.length === 0 ? (
        <p className="cart-empty">Your cart is empty</p>
      ) : (
        <>
          <ul className="cart-items">
            {cartItems.map((item, index) => (
              <li key={`${item._id || item.cartItemId}-${item.condition}-${item.printing}-${index}`} className="cart-item">
                <div className="cart-item-header">
                  <div className="item-details">
                    <span className="item-name">{item.name}</span>
                    <div className="item-specs">
                      {item.isCustomItem ? (
                        <span className="custom-item-label">Custom Item</span>
                      ) : (
                        <>
                          <select 
                            className="item-condition-select"
                            value={item.condition}
                            onChange={(e) => {
                              const newCondition = e.target.value;
                              // Force recalculation with forceRecalculate=true
                              const priceInfo = calculatePrice(item, newCondition, item.printing, item.language, true);
                              console.log(`New price info for ${item.name} (${newCondition}):`, priceInfo);
                              const updatedItem = {
                                ...item,
                                condition: newCondition,
                                cashPrice: priceInfo.cashPrice,
                                creditPrice: priceInfo.creditPrice,
                                basePrice: priceInfo.basePrice
                              };
                              updateCartItemDetails(updatedItem);
                            }}
                          >
                            {allowedConditions.length > 0 
                              ? allowedConditions.map((cond, index) => (
                                  <option key={index} value={cond}>{cond}</option>
                                ))
                              : <option value={item.condition}>{item.condition}</option>
                            }
                          </select>
                          <select 
                            className="item-printing-select"
                            value={item.printing}
                            onChange={(e) => {
                              const newPrinting = e.target.value;
                              // Force recalculation with forceRecalculate=true
                              const priceInfo = calculatePrice(item, item.condition, newPrinting, item.language, true);
                              console.log(`New price info for ${item.name} (printing: ${newPrinting}):`, priceInfo);
                              const updatedItem = {
                                ...item,
                                printing: newPrinting,
                                cashPrice: priceInfo.cashPrice,
                                creditPrice: priceInfo.creditPrice,
                                basePrice: priceInfo.basePrice
                              };
                              updateCartItemDetails(updatedItem);
                            }}
                          >
                            {item.skus && Array.isArray(item.skus) 
                              ? [...new Set(item.skus.map(sku => sku.printingName))].map((printing, index) => (
                                  <option key={index} value={printing}>{printing}</option>
                                ))
                              : <option value={item.printing}>{item.printing}</option>
                            }
                          </select>
                        </>
                      )}
                      {item.language && item.language !== 'EN' && (
                        <span className="item-language">{item.language}</span>
                      )}
                      {item.orderNote && (
                        <span className="item-note" title={item.orderNote}>Non-English Card</span>
                      )}
                    </div>
                    <div className={`price-tag ${item.isCash ? 'price-cash' : 'price-credit'}`}>
                      <span className="item-price">
                        {currencySymbol}{item.isCash ? item.cashPrice : item.creditPrice} {item.isCash ? 'Cash' : 'Credit'}
                        {item.isDerivedPrice && (
                          <span className="derived-price-indicator" title={`Price calculated as ${item.derivedPercentage}% of EN price`}>*</span>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="item-actions">
                  <div className="quantity-control">
                    <button 
                      onClick={() => updateQuantity(item, item.quantity - 1)}
                      disabled={item.quantity <= 1}
                      className="quantity-button"
                    >
                      -
                    </button>
                    <span className="item-quantity">{item.quantity}</span>
                    <button 
                      onClick={() => updateQuantity(item, item.quantity + 1)}
                      disabled={item.quantity >= 4}
                      className="quantity-button"
                    >
                      +
                    </button>
                  </div>
                  <button 
                    className="toggle-payment-button"
                    onClick={() => togglePaymentType(item)}
                  >
                    Switch to {item.isCash ? 'Credit' : 'Cash'}
                  </button>
                  <button 
                    className="remove-button"
                    onClick={() => removeFromCart(item)}
                    aria-label={`Remove ${item.name} from cart`}
                  >
                    &times;
                  </button>
                </div>
              </li>
            ))}
          </ul>
          <div className="cart-summary">
            <div className="subtotal">
              <span>Cash Subtotal:</span>
              <span>{currencySymbol}{cashSubtotal.toFixed(2)}</span>
            </div>
            <div className="subtotal">
              <span>Credit Subtotal:</span>
              <span>{currencySymbol}{creditSubtotal.toFixed(2)}</span>
            </div>
            <div className="total">
              <span>Total:</span>
              <span>{currencySymbol}{total.toFixed(2)}</span>
            </div>
          </div>
          {!hideCheckoutButton && (
            <button 
              className="checkout-button"
              onClick={checkout}
            >
              Proceed to Checkout
            </button>
          )}
          <button 
            className="clear-cart-button"
            onClick={handleClearCart}
          >
            Clear All
          </button>
          <div className="cart-actions">
            <button 
              className="export-csv-button"
              onClick={exportToCSV}
              disabled={cartItems.length === 0}
            >
              Export to CSV
            </button>
            {onSaveForLater !== (() => {}) && (
              <button 
                className="save-for-later-button"
                onClick={onSaveForLater}
              >
                Save for Later
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Cart;
