import React, { useState } from 'react';
import './MobileCardList.css';
import { useTranslation } from 'react-i18next';

// Currency symbols for formatting prices
const currencySymbols = {
  USD: '$', EUR: '€', GBP: '£', JPY: '¥', CAD: 'C$', AUD: 'A$', CHF: 'CHF', CNY: '¥', SEK: 'kr', NZD: 'NZ$',
  MXN: 'Mex$', SGD: 'S$', HKD: 'HK$', NOK: 'kr', KRW: '₩', TRY: '₺', RUB: '₽', INR: '₹', BRL: 'R$', ZAR: 'R'
};

const MobileCardList = ({ 
  cards, 
  addToCart, 
  allowedConditions, 
  calculatePrice, 
  userSettings,
  excludedSku
}) => {
  const { t } = useTranslation();
  const [expandedCard, setExpandedCard] = useState(null);
  const [selectedConditions, setSelectedConditions] = useState({});
  const [selectedPrintings, setSelectedPrintings] = useState({});
  const [selectedQuantities, setSelectedQuantities] = useState({});
  const [showNotBuying, setShowNotBuying] = useState(() => {
    const saved = localStorage.getItem('showNotBuying');
    return saved !== null ? JSON.parse(saved) : true;
  });

  const toggleCardExpand = (cardId) => {
    if (expandedCard === cardId) {
      setExpandedCard(null);
    } else {
      setExpandedCard(cardId);
      
      // Initialize condition and quantity if not already set
      if (!selectedConditions[cardId]) {
        setSelectedConditions(prev => ({
          ...prev,
          [cardId]: allowedConditions[0] || 'NM'
        }));
      }
      
      if (!selectedQuantities[cardId]) {
        setSelectedQuantities(prev => ({
          ...prev,
          [cardId]: 1
        }));
      }
    }
  };

  const handleConditionChange = (cardId, condition) => {
    setSelectedConditions(prev => ({
      ...prev,
      [cardId]: condition
    }));
  };

  const handlePrintingChange = (cardId, printing) => {
    setSelectedPrintings(prev => ({
      ...prev,
      [cardId]: printing
    }));
  };

  const handleQuantityChange = (cardId, quantity) => {
    setSelectedQuantities(prev => ({
      ...prev,
      [cardId]: Math.max(1, Math.min(4, parseInt(quantity) || 1))
    }));
  };

  const handleAddToCart = (card, isCash) => {
    const condition = selectedConditions[card.productId] || allowedConditions[0] || 'NM';
    const quantity = selectedQuantities[card.productId] || 1;
    const uniquePrintings = [...new Set(card.skus.map(sku => sku.printingName))];
    const printing = selectedPrintings[card.productId] || uniquePrintings[0] || 'Normal';
    const skuId = card.skus[0]?.skuId || '';
    
    addToCart(card, condition, printing, quantity, skuId, isCash);
    
    // Close the expanded card after adding to cart
    setExpandedCard(null);
  };

  const formatPrice = (price) => {
    if (!price || price === 0) return '-';
    const currencySymbol = currencySymbols[userSettings?.currency] || '$';
    return `${currencySymbol}${price.toFixed(2)}`;
  };

  const toggleNotBuying = () => {
    const newValue = !showNotBuying;
    setShowNotBuying(newValue);
    localStorage.setItem('showNotBuying', JSON.stringify(newValue));
    
    // Notify parent component that we need more cards if we're hiding not buying items
    if (!newValue && typeof window !== 'undefined') {
      window.dispatchEvent(new CustomEvent('needMoreCards', { 
        detail: { showNotBuying: newValue } 
      }));
    }
  };

  if (!cards || cards.length === 0) {
    return (
      <div className="mobile-no-results">
        <p>{t('No cards found. Try a different search.')}</p>
      </div>
    );
  }

  // Filter cards if not showing "not buying" items
  const filteredCards = showNotBuying 
    ? cards 
    : cards.filter(card => {
        const priceInfo = calculatePrice(card, selectedConditions[card.productId] || allowedConditions[0] || 'NM', selectedPrintings[card.productId] || [...new Set(card.skus.map(sku => sku.printingName))][0] || 'Normal');
        const isExcluded = excludedSku?.includes(card.skus[0]?.skuId);
        return !isExcluded && !priceInfo.isBulk && !priceInfo.isRecentRelease && (priceInfo.cashPrice > 0 || priceInfo.creditPrice > 0);
      });

  return (
    <div className="mobile-card-list">
      <div className="mobile-toggle-container">
        <div className="mobile-view-toggle">
          <label className="mobile-switch">
            <input
              type="checkbox"
              checked={showNotBuying}
              onChange={toggleNotBuying}
            />
            <span className="mobile-slider round"></span>
          </label>
          <span className="mobile-toggle-label">{t('Show Not Buying')}</span>
        </div>
      </div>
      
      {filteredCards.map(card => {
        const isExpanded = expandedCard === card.productId;
        const condition = selectedConditions[card.productId] || allowedConditions[0] || 'NM';
        const quantity = selectedQuantities[card.productId] || 1;
        const uniquePrintings = [...new Set(card.skus.map(sku => sku.printingName))];
        const printing = selectedPrintings[card.productId] || uniquePrintings[0] || 'Normal';
        
        // Calculate prices
        const priceInfo = calculatePrice(card, condition, printing);
        const { cashPrice, creditPrice, isBulk, contactForPrice } = priceInfo;
        
        // Check if SKU is excluded
        const isExcluded = excludedSku?.includes(card.skus[0]?.skuId);
        
        return (
          <div 
            key={card.productId}
            className={`mobile-card-item ${isExpanded ? 'expanded' : ''} ${isExcluded || isBulk ? 'excluded' : ''}`}
          >
            <div className="mobile-card-header" onClick={() => toggleCardExpand(card.productId)}>
              <div className="mobile-card-thumbnail">
                <img 
                  src={card.image || process.env.PUBLIC_URL + '/flags/No-Image-Placeholder.svg.png'} 
                  alt={card.name} 
                  className="mobile-card-thumbnail-img"
                  onError={(e) => {
                    e.target.onerror = null; // Prevent infinite loop
                    e.target.src = process.env.PUBLIC_URL + '/flags/No-Image-Placeholder.svg.png';
                  }}
                />
              </div>
              <div className="mobile-card-info">
                <div className="mobile-card-name">{card.name}</div>
                <div className="mobile-card-set">{card.expansionName}</div>
                {card.number && <div className="mobile-card-number">#{card.number}</div>}
                {!card.number && card.productId && <div className="mobile-card-number">ID: {card.productId}</div>}
                {(isExcluded || isBulk) && (
                  <div className="mobile-card-not-buying">We are not buying</div>
                )}
              </div>
              <div className="mobile-card-expand-icon">
                {isExpanded ? '▲' : '▼'}
              </div>
            </div>
            
            {isExpanded && !isExcluded && !isBulk && (
              <div className="mobile-card-details">
                <div className="mobile-card-selectors">
                  <div className="mobile-card-condition-selector">
                    <label>Condition:</label>
                    <div className="mobile-condition-buttons">
                      {allowedConditions.map(cond => (
                        <button
                          key={cond}
                          className={`mobile-condition-button ${condition === cond ? 'active' : ''}`}
                          onClick={() => handleConditionChange(card.productId, cond)}
                        >
                          {cond}
                        </button>
                      ))}
                    </div>
                  </div>
                  
                  {uniquePrintings.length > 1 && (
                    <div className="mobile-card-printing-selector">
                      <label>Print Type:</label>
                      <div className="mobile-printing-buttons">
                        {uniquePrintings.map((print, index) => (
                          <button
                            key={index}
                            className={`mobile-printing-button ${printing === print ? 'active' : ''}`}
                            onClick={() => handlePrintingChange(card.productId, print)}
                          >
                            {print}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                
                <div className="mobile-card-quantity">
                  <label>Quantity:</label>
                  <div className="mobile-quantity-control">
                    <button 
                      className="mobile-quantity-button"
                      onClick={() => handleQuantityChange(card.productId, quantity - 1)}
                      disabled={quantity <= 1}
                    >
                      -
                    </button>
                    <span className="mobile-quantity-value">{quantity}</span>
                    <button 
                      className="mobile-quantity-button"
                      onClick={() => handleQuantityChange(card.productId, quantity + 1)}
                      disabled={quantity >= 4}
                    >
                      +
                    </button>
                  </div>
                </div>
                
                <div className="mobile-card-actions">
                  {!contactForPrice && cashPrice > 0 && (
                    <button 
                      className="mobile-add-cash"
                      onClick={() => handleAddToCart(card, true)}
                    >
                      Add for Cash ({formatPrice(cashPrice * quantity)})
                    </button>
                  )}
                  
                  {!contactForPrice && creditPrice > 0 && (
                    <button 
                      className="mobile-add-credit"
                      onClick={() => handleAddToCart(card, false)}
                    >
                      Add for Credit ({formatPrice(creditPrice * quantity)})
                    </button>
                  )}
                  
                  {contactForPrice && (
                    <div className="mobile-contact-for-price">
                      Contact us for pricing on this item
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default MobileCardList;
