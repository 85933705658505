import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './BulkSearchTable.css';

const BulkSearchTable = ({ rows, onAddRow, onRemoveRow, onRowChange, onBulkInput }) => {
  const { t } = useTranslation();
  const [showBulkInput, setShowBulkInput] = useState(false);
  const [bulkText, setBulkText] = useState('');

  const handleInputChange = (id, field, value) => {
    onRowChange(id, field, value);
  };

  const handleBulkTextChange = (e) => {
    setBulkText(e.target.value);
  };

  const handleBulkSubmit = () => {
    onBulkInput(bulkText);
    setBulkText('');
    setShowBulkInput(false);
  };

  const handleBulkCancel = () => {
    setBulkText('');
    setShowBulkInput(false);
  };

  return (
    <div className="bulk-search-table-container">
      {!showBulkInput ? (
        <>
          <table className="bulk-search-table">
            <thead>
              <tr>
                <th>{t('Card Name')}</th>
                <th>{t('Set')}</th>
                <th>{t('Number')}</th>
                <th>{t('Actions')}</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row) => (
                <tr key={row.id}>
                  <td>
                    <input
                      type="text"
                      className="bulk-search-input"
                      value={row.name}
                      onChange={(e) => handleInputChange(row.id, 'name', e.target.value)}
                      placeholder={t('Enter card name')}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="bulk-search-input"
                      value={row.abbreviation}
                      onChange={(e) => handleInputChange(row.id, 'abbreviation', e.target.value)}
                      placeholder={t('Set code (optional)')}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="bulk-search-input"
                      value={row.number}
                      onChange={(e) => handleInputChange(row.id, 'number', e.target.value)}
                      placeholder={t('Card # (optional)')}
                    />
                  </td>
                  <td>
                    <button
                      className="remove-row-button"
                      onClick={() => onRemoveRow(row.id)}
                      aria-label={t('Remove row')}
                    >
                      {t('Remove')}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="bulk-search-table-actions">
            <button className="add-row-button" onClick={onAddRow}>
              {t('Add Row')}
            </button>
            <button className="add-row-button" onClick={() => setShowBulkInput(true)}>
              {t('Bulk Input')}
            </button>
          </div>
        </>
      ) : (
        <div className="bulk-input-container">
          <h3>{t('Bulk Input')}</h3>
          <p className="bulk-input-instructions">
            {t('Enter one card per line. Format: "Card Name [Set] #Number" (Set and Number are optional)')}
          </p>
          <p className="bulk-input-example">
            {t('Example:')} Mana Crypt<br />
            {t('Example:')} Counterspell [7ED] #67<br />
            {t('Example:')} Lightning Bolt [M10]
          </p>
          <textarea
            className="bulk-input-textarea"
            value={bulkText}
            onChange={handleBulkTextChange}
            placeholder={t('Enter cards, one per line')}
            rows={10}
          />
          <div className="bulk-input-actions">
            <button className="bulk-input-submit" onClick={handleBulkSubmit}>
              {t('Submit')}
            </button>
            <button className="bulk-input-cancel" onClick={handleBulkCancel}>
              {t('Cancel')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BulkSearchTable;
