import React, { useState } from 'react';
import './MobileGameSelector.css';

const MobileGameSelector = ({ games, selectedGame, onGameChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleGameSelect = (game) => {
    onGameChange(game);
    setIsOpen(false);
  };

  // Get the game colors from the MobileTileGrid component
  const gameColors = {
    'Magic: The Gathering': 'var(--wp8-blue)',
    'Pokemon': '#FFB900', // Yellow
    'Yu-Gi-Oh!': 'var(--wp8-purple)',
    'Flesh and Blood': 'var(--wp8-red)',
    'Lorcana': 'var(--wp8-teal)',
    'One Piece': 'var(--wp8-orange)',
    'Star Wars': 'var(--wp8-lime)',
    'Sorcery': 'var(--wp8-magenta)',
    'default': 'var(--wp8-gray)'
  };

  // Function to get a random color for games not in the map
  const getRandomColor = (gameName) => {
    // Generate a color based on the game name to ensure consistency
    let hash = 0;
    for (let i = 0; i < gameName.length; i++) {
      hash = gameName.charCodeAt(i) + ((hash << 5) - hash);
    }
    
    // Convert to a hex color
    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xFF;
      color += ('00' + value.toString(16)).substr(-2);
    }
    
    return color;
  };

  const getGameColor = (game) => {
    return gameColors[game] || getRandomColor(game);
  };

  return (
    <div className="mobile-game-selector">
      <div 
        className="mobile-selected-game" 
        onClick={toggleDropdown}
        style={{ backgroundColor: getGameColor(selectedGame) }}
      >
        <span>{selectedGame || 'Select Game'}</span>
        <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}>▼</span>
      </div>
      
      {isOpen && (
        <div className="mobile-game-dropdown">
          {games.map((game, index) => (
            <div 
              key={index} 
              className="mobile-game-option"
              style={{ backgroundColor: getGameColor(game) }}
              onClick={() => handleGameSelect(game)}
            >
              {game}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MobileGameSelector;
