
import React, { useState } from 'react';
import './Sidebar.css';

const Sidebar = ({ enabledGames, onGameSelect, customItems = [], onCustomItemsClick, userSettings }) => {
  const [activeGame, setActiveGame] = useState(null);
  const [customItemsActive, setCustomItemsActive] = useState(false);

  const handleGameClick = (game) => {
    setActiveGame(game);
    setCustomItemsActive(false);
    onGameSelect(game);
  };

  const handleCustomItemsClick = () => {
    setActiveGame(null);
    setCustomItemsActive(true);
    if (onCustomItemsClick) {
      onCustomItemsClick();
    }
  };

  return (
    <div className="alt-sidebar">
      <div className="alt-sidebar-games">
        <h3>Games</h3>
        <ul className="alt-game-list">
          {enabledGames.map((game, index) => (
            <li 
              key={index} 
              className={`alt-game-item ${activeGame === game ? 'active' : ''}`}
              onClick={() => handleGameClick(game)}
            >
              {game === 'Star Wars Unlimited' ? (
                <>
                  <span className="alt-star-icon">★</span>
                  <span>{game}</span>
                </>
              ) : (
                <span>{game}</span>
              )}
            </li>
          ))}
          
          {/* Other Items We Buy button - only show if we have custom items */}
          {customItems.length > 0 && (
            <li 
              className={`alt-game-item alt-custom-items ${customItemsActive ? 'active' : ''}`}
              onClick={handleCustomItemsClick}
            >
              <span>{userSettings?.custom_buylist_button_label || 'Other Items We Buy'}</span>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
