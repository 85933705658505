import React, { useState } from 'react';
import './Popup.css';
import './CashLimitPopup.css';

const CashLimitPopup = ({ onClose, cashLimit, currencySymbol }) => {
  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleConfirmChange = (e) => {
    setIsConfirmed(e.target.checked);
  };

  const handleClose = () => {
    if (isConfirmed) {
      onClose(true); // Pass true to indicate the user confirmed
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup">
        <button 
          onClick={handleClose} 
          className="close-x-button"
          disabled={!isConfirmed}
          aria-label="Close"
        >
          ×
        </button>
        <div className="popup-content">
          <h2>Cash Limit Notification</h2>
          <p>
            Your cart has exceeded the cash payment limit of {currencySymbol}{cashLimit}.
          </p>
          <p>
            Any amount over this threshold will be paid in store credit instead of cash.
          </p>
          <p>
            The cash payment option will be disabled for new items until you reduce your cart's cash total.
          </p>
          <div className="confirmation-checkbox">
            <label>
              <input
                type="checkbox"
                checked={isConfirmed}
                onChange={handleConfirmChange}
              />
              I understand and agree to continue with this payment method
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashLimitPopup;
