import React from 'react';
import './ExpansionSelector.css';

const ExpansionSelector = ({ allExpansionNames, searchParams, handleSetChange }) => {
  return (
    <div className="alt-filter-row">
      <div className="alt-filter-label">Filter by Set:</div>
      <div className="alt-filter-select-container">
        <select 
          className="alt-filter-select" 
          value={searchParams.set} 
          onChange={handleSetChange}
          disabled={!searchParams.game}
        >
          <option value="">All Sets</option>
          {allExpansionNames.map((set, index) => (
            <option key={index} value={set}>{set}</option>
          ))}
        </select>
        <div className="alt-select-arrow"></div>
      </div>
    </div>
  );
};

export default ExpansionSelector;
