import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import CardList from './components/CardList';
import Cart from './components/Cart';
import CheckoutPage from './components/CheckoutPage';
import Infographics from './components/Infographics';
import EnquiryPopup from './components/EnquiryPopup';
import SaveBuylistPopup from './components/SaveBuylistPopup';
import LoadBuylistPopup from './components/LoadBuylistPopup';
import BulkSearchPage from './components/BulkSearch/BulkSearchPage';
import AlternativeApp from './alternative-ui/AlternativeApp';
import MobileApp from './mobile-ui/MobileApp';
import { translateText, isTranslationAvailable } from './utils/openaiTranslate';
import { BACKEND_URL } from './config';
import { useNotification } from './context/NotificationContext';
import './App.css';

// Debounce function
const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

console.log('Backend URL:', BACKEND_URL);

const DEFAULT_USER_SETTINGS = {
  conditions: ['NM', 'LP', 'MP', 'HP'],
  defaultCashPercentage: '50',
  defaultCreditPercentage: '60',
  enabledGames: ['Magic: The Gathering'],
  excludedSku: [],
  floorPrice: '0',
  bulkSettings: '',
  box1: '',
  currency: 'USD',
};

  // Cache for storing fetched prices
const priceCache = new Map();

// Cache for storing inventory levels
const inventoryCache = new Map();

// Clear the price cache - used when we need to force recalculation
const clearPriceCache = () => {
  priceCache.clear();
  inventoryCache.clear();
  console.log('Price cache cleared');
};

// Disable price caching completely - uncomment this line to disable caching
// const priceCache = { get: () => null, set: () => {}, clear: () => {} };

// Cache for storing games and sets
const gamesCache = new Map();
const setsCache = new Map();

// Error boundary component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong. Please refresh the page.</h1>;
    }

    return this.props.children;
  }
}

const App = () => {
  const { t, i18n } = useTranslation();
  const [cards, setCards] = useState([]);
  const [cartItems, setCartItems] = useState(() => {
    const savedCartItems = localStorage.getItem('cartItems');
    return savedCartItems ? JSON.parse(savedCartItems) : [];
  });
  const [userSettings, setUserSettings] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [error, setError] = useState(null);
  const [enabledGames, setEnabledGames] = useState([]);
  const [allowedConditions, setAllowedConditions] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [allExpansionNames, setAllExpansionNames] = useState([]);
  const [allRarities, setAllRarities] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchAbbr, setSearchAbbr] = useState('');
  const [searchNumber, setSearchNumber] = useState('');
  const [floorPrice, setFloorPrice] = useState(0);
  const [bulkSettings, setBulkSettings] = useState('');
  const [isCheckingOut, setIsCheckingOut] = useState(false);
  const [username, setUsername] = useState('');
  const [box1Content, setBox1Content] = useState('');
  const [showEnquiryPopup, setShowEnquiryPopup] = useState(false);
  const [recognizedCard, setRecognizedCard] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [excludedSkus, setExcludedSkus] = useState([]);
  const [exchangeRates, setExchangeRates] = useState(null);
  const [userCurrency, setUserCurrency] = useState('USD');
  const [searchError, setSearchError] = useState(null);
  const [useAlternativeUI, setUseAlternativeUI] = useState(false);
  const [showSaveBuylistPopup, setShowSaveBuylistPopup] = useState(false);
  const [showLoadBuylistPopup, setShowLoadBuylistPopup] = useState(false);
  const [priceChangeInfo, setPriceChangeInfo] = useState(null);
  const [showBulkSearch, setShowBulkSearch] = useState(false);
  // Initialize cart as hidden
  const [isCartVisible, setIsCartVisible] = useState(false);
  // Custom buylist items state
  const [customItems, setCustomItems] = useState([]);
  const [showCustomItems, setShowCustomItems] = useState(false);
  const [isLoadingCustomItems, setIsLoadingCustomItems] = useState(false);
  
  // Currency symbols for formatting prices
  const currencySymbols = {
    USD: '$', EUR: '€', GBP: '£', JPY: '¥', CAD: 'C$', AUD: 'A$', CHF: 'CHF', CNY: '¥', SEK: 'kr', NZD: 'NZ$',
    MXN: 'Mex$', SGD: 'S$', HKD: 'HK$', NOK: 'kr', KRW: '₩', TRY: '₺', RUB: '₽', INR: '₹', BRL: 'R$', ZAR: 'R'
  };
  
  // Show cart when items are added
  useEffect(() => {
    if (cartItems.length > 0 && !isCartVisible) {
      setIsCartVisible(true);
    }
  }, [cartItems.length]);
  
  const [searchParams, setSearchParams] = useState({
    term: '',
    abbr: '',
    number: '',
    game: '',
    set: '',
    rarity: '',
    page: 1,
    sort: 'name_asc' // Default sort: A to Z
  });
  
  // Close custom items section when search params change
  useEffect(() => {
    setShowCustomItems(false);
  }, [searchParams]);
  const isFetchingRef = useRef(false);

  // Helper function to normalize number
  const normalizeNumber = (number) => {
    if (!number) return '';
    return number.replace(/^0+/, '').split(/[^0-9]/)[0];
  };

  const handleOpenEnquiryPopup = useCallback(() => {
    setShowEnquiryPopup(true);
  }, []);

  const handleCloseEnquiryPopup = useCallback(() => {
    setShowEnquiryPopup(false);
  }, []);

  const handleContinueEnquiry = useCallback(() => {
    setShowEnquiryPopup(false);
    if (userSettings && userSettings.email) {
      const subject = encodeURIComponent('Please assess my cards');
      const body = encodeURIComponent('I would like to request an assessment of my cards.');
      window.open(`mailto:${userSettings.email}?subject=${subject}&body=${body}`, '_blank');
    }
  }, [userSettings]);

  useEffect(() => {
    const path = window.location.pathname;
    const extractedUsername = path.split('/')[1];
    setUsername(extractedUsername || 'admintcg');
  }, []);

  useEffect(() => {
    if (username) {
      fetchUserSettings().catch(err => {
        console.error("Error fetching user settings:", err);
        setError("Failed to load user settings. Please refresh the page.");
      });
      fetchCustomItems(username);
    }
  }, [username]);

  // Function to fetch custom buylist items
  const fetchCustomItems = useCallback(async (username) => {
    try {
      setIsLoadingCustomItems(true);
      const url = `${BACKEND_URL}/custom-buylist/${username}`;
      console.log('Fetching custom buylist items from URL:', url);
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log('Custom buylist items:', data.items);
      setCustomItems(data.items || []);
    } catch (error) {
      console.error('Error fetching custom buylist items:', error);
    } finally {
      setIsLoadingCustomItems(false);
    }
  }, [BACKEND_URL]);

  const fetchExchangeRates = useCallback(() => {
    const hardcodedRates = {
      CAD: 1.350255,
      GBP: 0.753671,
      AUD: 1.452544,
      NZD: 1.607852,
      MXN: 19.415903,
      USD: 1
    };
    setExchangeRates(hardcodedRates);
  }, []);

  // Function to fetch inventory level for a product
  const fetchInventoryLevel = useCallback(async (productId) => {
    try {
      const url = `${BACKEND_URL}/inventory-level/${username}/${productId}`;
      console.log(`Fetching inventory level from: ${url}`);
      
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log(`Inventory level for product ${productId}: ${data.inventoryCount}`);
      return data.inventoryCount;
    } catch (error) {
      console.error(`Error fetching inventory level for product ${productId}:`, error);
      return 0; // Default to 0 if there's an error
    }
  }, [username]);

  useEffect(() => {
    fetchExchangeRates();
    
    // Listen for the needMoreCards event
    const handleNeedMoreCards = (event) => {
      console.log('Need more cards event received:', event.detail);
      // Fetch more cards by incrementing the page number
      // This will continue fetching until we have enough visible cards
      setSearchParams(prev => ({
        ...prev,
        page: prev.page + 1
      }));
    };
    
    window.addEventListener('needMoreCards', handleNeedMoreCards);
    
    return () => {
      window.removeEventListener('needMoreCards', handleNeedMoreCards);
    };
  }, [fetchExchangeRates]);

  const fetchCatalog = useCallback(async (params) => {
    if (isFetchingRef.current || !params.game) {
      console.log('Fetch already in progress or no game selected, skipping');
      return;
    }

    isFetchingRef.current = true;
    setIsLoading(true);
    setSearchError(null);
    console.log('Fetching catalog with params:', params);

    try {
      const url = new URL(`${BACKEND_URL}/catalog`);
      
      // Use pagination with 25 results per page
      // Get the showNotBuying preference from localStorage
      const showNotBuying = localStorage.getItem('showNotBuying') !== null ? 
        JSON.parse(localStorage.getItem('showNotBuying')) : true;
      
      const searchParams = {
        game: params.game,
        page: params.page.toString(),
        limit: "25", // Fetch 25 results per page
        set: params.set,
        rarity: params.rarity,
        search: params.term,
        abbr: params.abbr,
        number: params.number,
        username: username,
        showNotBuying: showNotBuying.toString() // Add parameter to indicate whether to include "not buying" cards
      };
      
      url.search = new URLSearchParams(searchParams).toString();

      console.log('Fetching from URL:', url.toString());

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Received data:', data);

      if (data.items.length === 0) {
        setSearchError(t('No results found. Please check your spelling and try again.'));
      }

      setCards(data.items || []);
      setTotalPages(data.totalPages || 1);

      // Always update expansionNames
      const expansions = data.expansionNames || [];
      setAllExpansionNames(expansions);
      
      // For rarities, we need to handle differently
      // If we're filtering by rarity, we need to make sure all rarities are still available
      if (params.rarity) {
        // If we're filtering by rarity, we'll keep the existing rarities
        // but make sure the current rarity is included
        setAllRarities(prevRarities => {
          // If we don't have any rarities yet, just use what we got from the response
          if (!prevRarities || prevRarities.length === 0) {
            return data.rarities || [];
          }
          
          // Make sure the current rarity is included
          if (!prevRarities.includes(params.rarity) && params.rarity) {
            return [...prevRarities, params.rarity];
          }
          
          return prevRarities;
        });
      } else {
        // If we're not filtering by rarity, update with the full list
        const rarities = data.rarities || [];
        setAllRarities(rarities);
      }

    } catch (error) {
      console.error('Error fetching catalog:', error);
      setSearchError(t('No results found. Please check your spelling and try again.'));
    } finally {
      setIsLoading(false);
      isFetchingRef.current = false;
    }
  }, [itemsPerPage, t, username]);

  const debouncedFetchCatalog = useMemo(
    () => debounce((params) => {
      if (!isInitialLoad && userSettings && params.game) {
        fetchCatalog(params);
      }
    }, 300),
    [fetchCatalog, isInitialLoad, userSettings]
  );

  useEffect(() => {
    console.log('Search params changed:', searchParams);
    if (!isInitialLoad) {
      // Clear the price cache when search params change to force recalculation
      clearPriceCache();
      debouncedFetchCatalog(searchParams);
    }
  }, [searchParams, debouncedFetchCatalog, isInitialLoad]);

  const fetchUserSettings = useCallback(async () => {
    try {
      const url = `${BACKEND_URL}/user-settings/${username}`;
      console.log('Fetching user settings from URL:', url);
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        const data = await response.json();
        updateUserSettings(data);
        setUserCurrency(data.currency || 'USD');
      } else {
        const text = await response.text();
        console.error("Received non-JSON response:", text);
        throw new Error(`Expected JSON, but received ${contentType}: ${text.substring(0, 100)}...`);
      }
    } catch (error) {
      console.error('Error fetching user settings:', error);
      if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
        setError(`Failed to fetch user settings: Network error. Please check your internet connection and try again.`);
      } else {
        setError(`Failed to fetch user settings: ${error.message}`);
      }
      updateUserSettings(DEFAULT_USER_SETTINGS);
      setUserCurrency('USD');
    } finally {
      setIsLoading(false);
    }
  }, [username]);

const updateUserSettings = (settings) => {
  // Clear the price cache when user settings are updated to ensure new rules are applied
  clearPriceCache();
  console.log('Price cache cleared due to user settings update');
  
// Make sure pricing_rules is properly initialized as an array if it exists
if (settings.pricing_rules === undefined || Array.isArray(settings.pricing_rules) === false) {
  console.log('Initializing pricing_rules as an empty array');
  settings.pricing_rules = [];
}

  // Make sure inventory_modifiers is properly initialized as an array if it exists
  if (settings.inventory_modifiers === undefined || Array.isArray(settings.inventory_modifiers) === false) {
    console.log('Initializing inventory_modifiers as an empty array');
    settings.inventory_modifiers = [];
  }
  
  // Make sure expansionRules is properly initialized as an array if it exists
  if (settings.expansionRules === undefined || Array.isArray(settings.expansionRules) === false) {
    console.log('Initializing expansionRules as an empty array');
    settings.expansionRules = [];
  }

  // Log the pricing rules, inventory modifiers, and expansion rules to verify they're properly set
  console.log('Pricing rules in updateUserSettings:', settings.pricing_rules);
  console.log('Inventory modifiers in updateUserSettings:', settings.inventory_modifiers);
  console.log('Expansion rules in updateUserSettings:', settings.expansionRules);
  
  setUserSettings(settings);

    let games = [];
    if (Array.isArray(settings.enabledGames)) {
      games = settings.enabledGames;
    } else if (typeof settings.enabledGames === 'string') {
      games = settings.enabledGames.split(',').map(game => game.trim());
    } else {
      games = DEFAULT_USER_SETTINGS.enabledGames;
    }
    
    games.sort((a, b) => a.localeCompare(b));
    setEnabledGames(games);

    gamesCache.set('enabledGames', {
      games: games,
      timestamp: Date.now()
    });

    if (settings.conditions) {
      setAllowedConditions(settings.conditions);
    }

    if (settings.floorPrice) {
      const parsedFloorPrice = parseFloat(settings.floorPrice);
      if (!isNaN(parsedFloorPrice)) {
        setFloorPrice(parsedFloorPrice);
      } else {
        setFloorPrice(0);
      }
    } else {
      setFloorPrice(0);
    }

    if (settings.bulkSettings) {
      setBulkSettings(settings.bulkSettings);
    }

    // Make sure excludedSku is properly initialized as an array
    if (settings.excludedSku === undefined || !Array.isArray(settings.excludedSku)) {
      settings.excludedSku = [];
    }
    setExcludedSkus(settings.excludedSku.map(String) || []);

  const socialSharingContent = (
    <div>
      <div className="social-share-buttons">
        <a href={`https://www.facebook.com/sharer/sharer.php?u=https://buylist.tcgsync.com/${username}`} target="_blank" rel="noopener noreferrer" className="facebook-share" aria-label="Share on Facebook"></a>
        <a href={`https://twitter.com/intent/tweet?text=I%20just%20sold%20my%20cards%20to%20${username}!&url=https://buylist.tcgsync.com/${username}`} target="_blank" rel="noopener noreferrer" className="twitter-share" aria-label="Share on Twitter"></a>
        <a href={`https://www.tiktok.com/upload?url=https://buylist.tcgsync.com/${username}&title=I%20just%20sold%20my%20cards%20to%20${username}!`} target="_blank" rel="noopener noreferrer" className="tiktok-share" aria-label="Share on TikTok"></a>
      </div>
    </div>
  );
  setBox1Content(socialSharingContent);
};

  const calculatePrice = useCallback((card, condition, printing, language = 'EN', forceRecalculate = true) => {
    console.log(`Calculating price for: ${card.name} (${card.game})`);
    console.log(`Condition: ${condition}, Printing: ${printing}, Language: ${language}`);
    console.log('Game:', card.gameName);
    console.log('Expansion:', card.expansionName);
    console.log('Rarity:', card.rarity);
    
    // Log expansion rules to help with debugging
    console.log('Expansion rules available in userSettings:', userSettings?.expansionRules);
    console.log('Is expansionRules an array:', userSettings?.expansionRules ? Array.isArray(userSettings.expansionRules) : false);

    if (!userSettings || !exchangeRates) {
      console.log('User settings or exchange rates not available');
      return { cashPrice: 0, creditPrice: 0, cashPercentage: 0, creditPercentage: 0, isBulk: false, timestamp: null, cashAvailable: false, creditAvailable: false, contactForPrice: true };
    }
    
    // Check if card is too close to release date
    if (userSettings.release_date_enabled && card.presaleInfo && card.presaleInfo.releasedOn) {
      const releasedOn = new Date(card.presaleInfo.releasedOn);
      const currentDate = new Date();
      
      // Add the specified number of days to the release date
      const cutoffDate = new Date(releasedOn);
      cutoffDate.setDate(cutoffDate.getDate() + parseInt(userSettings.release_date_days || 30));
      
      // If the cutoff date is in the future, this card is too new to purchase
      if (cutoffDate > currentDate) {
        console.log(`Card ${card.name} is too close to release date. Released on: ${releasedOn.toLocaleDateString()}, Cutoff: ${cutoffDate.toLocaleDateString()}`);
        return { 
          cashPrice: 0, 
          creditPrice: 0, 
          cashPercentage: 0, 
          creditPercentage: 0, 
          isBulk: false, 
          isRecentRelease: true,
          releasedOn: releasedOn,
          cutoffDate: cutoffDate,
          timestamp: Date.now(), 
          cashAvailable: false, 
          creditAvailable: false, 
          contactForPrice: false 
        };
      }
    }

    // Get the language percentage from user settings
    let langPercent = 50; // Default to 50% if not set
    
    if (userSettings.langPercent !== undefined) {
      if (typeof userSettings.langPercent === 'object') {
        // It's a map of language-specific percentages
        const specificPercent = userSettings.langPercent[language];
        if (specificPercent !== undefined) {
          // This language has a defined percentage
          langPercent = typeof specificPercent === 'string' ? parseFloat(specificPercent) : specificPercent;
        } else if (language !== 'EN') {
          // For non-EN languages without a defined percentage, we don't derive a price
          console.log(`No percentage defined for ${language} language, not deriving price`);
          return { cashPrice: 0, creditPrice: 0, cashPercentage: 0, creditPercentage: 0, isBulk: false, timestamp: null, cashAvailable: false, creditAvailable: false, contactForPrice: true };
        }
      } else {
        // Legacy support for single percentage value
        langPercent = typeof userSettings.langPercent === 'string' ? parseFloat(userSettings.langPercent) : userSettings.langPercent;
      }
    }
    
    console.log(`Language percentage for ${language}: ${langPercent}%`);

    // Filter SKUs by the selected printing and language
    const relevantSkus = card.skus.filter(sku =>
      sku.langAbbr === language && sku.printingName === printing
    );

    // Check if we have relevant SKUs for the requested language
    if (relevantSkus.length === 0) {
      console.log('No relevant SKUs found for the given printing and language');
      
      // If we're looking for a non-EN language and don't find any SKUs, check if we can calculate based on EN
      if (language !== 'EN') {
        console.log(`Checking if EN SKUs are available for percentage-based calculation for ${language}`);
        
        // First check if we already have a cached EN price to avoid recursion
        const enCacheKey = `${card.productId}-${condition}-${printing}-EN-${userCurrency}`;
        let enPrice = priceCache.get(enCacheKey);
        
        if (!enPrice) {
          // Check if there are EN SKUs available
          const enSkus = card.skus.filter(sku => 
            sku.langAbbr === 'EN' && sku.printingName === printing
          );
          
          if (enSkus.length > 0) {
            console.log('EN SKUs found, will calculate price as percentage of EN price');
            // Calculate EN price
            enPrice = calculatePrice(card, condition, printing, 'EN');
          }
        } else {
          console.log('Using cached EN price for derivation:', enPrice);
        }
        
        // If we have a valid EN price, derive the non-EN price from it
        if (enPrice && !enPrice.contactForPrice) {
          console.log(`Deriving ${language} price as ${langPercent}% of EN price`);
          
          // Calculate price as percentage of EN price
          const derivedCashPrice = enPrice.cashPrice * (langPercent / 100);
          const derivedCreditPrice = enPrice.creditPrice * (langPercent / 100);
          const derivedBasePrice = enPrice.basePrice * (langPercent / 100);
          
          const result = {
            cashPrice: parseFloat(derivedCashPrice.toFixed(2)),
            creditPrice: parseFloat(derivedCreditPrice.toFixed(2)),
            cashPercentage: enPrice.cashPercentage,
            creditPercentage: enPrice.creditPercentage,
            isBulk: enPrice.isBulk,
            timestamp: Date.now(),
            cashAvailable: derivedCashPrice >= parseFloat(userSettings.floorPrice || DEFAULT_USER_SETTINGS.floorPrice),
            creditAvailable: derivedCreditPrice >= parseFloat(userSettings.floorPrice || DEFAULT_USER_SETTINGS.floorPrice),
            contactForPrice: false,
            basePrice: derivedBasePrice,
            isDerivedPrice: true,
            derivedFrom: 'EN',
            derivedPercentage: langPercent
          };
          
          // Cache this derived price
          const derivedCacheKey = `${card.productId}-${condition}-${printing}-${language}-${userCurrency}`;
          priceCache.set(derivedCacheKey, result);
          
          console.log(`Derived ${language} price:`, result);
          return result;
        }
      }
      
      // If we couldn't derive a price, return contact for price
      return { cashPrice: 0, creditPrice: 0, cashPercentage: 0, creditPercentage: 0, isBulk: false, timestamp: null, cashAvailable: false, creditAvailable: false, contactForPrice: true };
    }

    const cacheKey = `${card.productId}-${condition}-${printing}-${language}-${userCurrency}`;
    const cachedPrice = priceCache.get(cacheKey);
    const currentTime = Date.now();

    if (!forceRecalculate && cachedPrice && (currentTime - cachedPrice.timestamp) < 3600000) {
      // Check if there's a fixed-value pricing rule that applies to this cached price
      if (userSettings.pricing_rules && Array.isArray(userSettings.pricing_rules)) {
        const basePrice = cachedPrice.basePrice;
        // Make sure we check rarity when applying rules to cached prices too
        const applicableRule = userSettings.pricing_rules.find(rule => {
          const isMatch = rule.game === card.gameName &&
            basePrice >= rule.min_value &&
            basePrice <= rule.max_value &&
            rule.use_fixed_value &&
            (rule.rarity === undefined || !rule.rarity || (card.rarity && rule.rarity === card.rarity));
          
          if (rule.rarity) {
            console.log(`Checking cached price rule with rarity "${rule.rarity}" against card rarity "${card.rarity}": ${isMatch ? 'MATCH' : 'NO MATCH'}`);
          }
          
          return isMatch;
        });
        
        if (applicableRule) {
          console.log('Found applicable fixed-value pricing rule for cached price:', applicableRule);
          // Update the cached price with the fixed values
          const cashPrice = applicableRule.cash_value;
          const creditPrice = applicableRule.credit_value;
          
          // Fixed prices from user_settings are already in the user's currency
          // No need to apply exchange rate
          const adjustedCashPrice = cashPrice;
          const adjustedCreditPrice = creditPrice;
          console.log('Using fixed prices from user settings without currency conversion');
          
          const updatedCachedPrice = {
            ...cachedPrice,
            cashPrice: parseFloat(adjustedCashPrice.toFixed(2)),
            creditPrice: parseFloat(adjustedCreditPrice.toFixed(2)),
            cashPercentage: 0,
            creditPercentage: 0,
            isFixedValue: true,
            isBulk: false,
            cashAvailable: adjustedCashPrice > 0,
            creditAvailable: adjustedCreditPrice > 0
          };
          
          // Update the cache with the new values
          priceCache.set(cacheKey, updatedCachedPrice);
          console.log('Updated cached price with fixed values:', updatedCachedPrice);
          return updatedCachedPrice;
        }
      }
      
      console.log('Returning cached price:', cachedPrice);
      return cachedPrice;
    }

    const conditionSku = relevantSkus.find(sku => sku.condAbbr === condition);
    const tcgPlayerPrice = conditionSku && conditionSku.lowPrice ? parseFloat(conditionSku.lowPrice) : null;
    console.log(`TCGPlayer Price for ${condition} (${language}): ${tcgPlayerPrice !== null ? `$${tcgPlayerPrice}` : 'Not available'}`);

    const nmSku = relevantSkus.find(sku => sku.condAbbr === 'NM');
    const basePrice = nmSku && nmSku.lowPrice ? parseFloat(nmSku.lowPrice) : null;
    console.log(`Base Price (NM, ${language}): ${basePrice !== null ? `$${basePrice}` : 'Not available'}`);

    // Check if we have trend prices available
    const hasTrendPrice = conditionSku && conditionSku.lowPrice && conditionSku.lowPrice > 0;
    console.log(`Trend price available for ${language}: ${hasTrendPrice ? 'Yes' : 'No'}`);

    if (tcgPlayerPrice === null || basePrice === null) {
      console.log('No valid price available, contact for price');
      return { cashPrice: 0, creditPrice: 0, cashPercentage: 0, creditPercentage: 0, isBulk: false, timestamp: currentTime, cashAvailable: false, creditAvailable: false, contactForPrice: true };
    }

    // Check if we have a valid trend price (lowPrice) for this SKU
    const trendPrice = conditionSku && conditionSku.lowPrice ? parseFloat(conditionSku.lowPrice) : null;
    console.log(`Trend Price for ${condition} (${language}): ${trendPrice !== null ? `$${trendPrice}` : 'Not available'}`);

    // Always calculate the stepped price regardless of trend price availability
    let steppedPrice;
    switch (condition) {
      case 'NM':
        steppedPrice = basePrice;
        break;
      case 'LP':
        steppedPrice = basePrice * 0.8;
        break;
      case 'MP':
        steppedPrice = basePrice * 0.64;
        break;
      case 'HP':
        steppedPrice = basePrice * 0.512;
        break;
      case 'DM':
        steppedPrice = basePrice * 0.4096;
        break;
      default:
        steppedPrice = basePrice;
    }

    console.log(`Stepped Price for ${condition}: $${steppedPrice.toFixed(2)}`);
    
    // Determine which price to use as the base for calculations based on user settings
    let adjustedBasePrice;
    let priceSource;
    
    // Function to get price based on preference
    const getPriceByPreference = (preference) => {
      switch (preference) {
        case 'marketPrice':
          if (conditionSku && conditionSku.marketPrice && conditionSku.marketPrice > 0) {
            return { price: parseFloat(conditionSku.marketPrice), source: 'Market Price' };
          }
          break;
        case 'lowPrice':
          if (conditionSku && conditionSku.lowPrice && conditionSku.lowPrice > 0) {
            return { price: parseFloat(conditionSku.lowPrice), source: 'Low Price' };
          }
          break;
        case 'midPrice':
          if (conditionSku && conditionSku.midPrice && conditionSku.midPrice > 0) {
            return { price: parseFloat(conditionSku.midPrice), source: 'Mid Price' };
          }
          break;
        case 'highPrice':
          if (conditionSku && conditionSku.highPrice && conditionSku.highPrice > 0) {
            return { price: parseFloat(conditionSku.highPrice), source: 'High Price' };
          }
          break;
        case 'lowestListedPrice':
        case 'lowestListingPrice':
          if (conditionSku && conditionSku.lowestListingPrice && conditionSku.lowestListingPrice > 0) {
            return { price: parseFloat(conditionSku.lowestListingPrice), source: 'Lowest Listing Price' };
          }
          break;
      }
      return null;
    };
    
    // Check if user has a price_preference_order
    if (userSettings.price_preference_order && Array.isArray(userSettings.price_preference_order) && userSettings.price_preference_order.length > 0) {
      console.log(`User has price_preference_order: ${userSettings.price_preference_order.join(', ')}`);
      
      // Try each preference in order
      let foundPrice = false;
      for (const preference of userSettings.price_preference_order) {
        const priceResult = getPriceByPreference(preference);
        if (priceResult) {
          adjustedBasePrice = priceResult.price;
          priceSource = priceResult.source;
          foundPrice = true;
          console.log(`Found price using preference order (${preference}): ${priceSource} = $${adjustedBasePrice}`);
          break;
        }
      }
      
      // If no price found in preference order, fall back to stepped price
      if (!foundPrice) {
        adjustedBasePrice = steppedPrice;
        priceSource = 'Stepped Price (No prices available in preference order)';
        console.log(`No prices available in preference order, using stepped price: $${adjustedBasePrice}`);
      }
    } 
    // If no price_preference_order, check if price_point exists
    else if (userSettings.price_point) {
      console.log(`No price_preference_order, but user has price_point setting: ${userSettings.price_point}`);
      
      // Map price_point to the corresponding preference key
      let primaryPreference;
      switch (userSettings.price_point) {
        case 'Market Price':
          primaryPreference = 'marketPrice';
          break;
        case 'Low Price':
          primaryPreference = 'lowPrice';
          break;
        case 'Lowest Listing Price':
          primaryPreference = 'lowestListingPrice';
          break;
        default:
          primaryPreference = 'lowPrice'; // Default to lowPrice if unknown
      }
      
      // Try to get price based on primary preference
      const primaryPrice = getPriceByPreference(primaryPreference);
      
      if (primaryPrice) {
        // Primary price is available
        adjustedBasePrice = primaryPrice.price;
        priceSource = primaryPrice.source;
        console.log(`Using primary preference (${primaryPreference}): ${priceSource} = $${adjustedBasePrice}`);
      } else {
        // Primary price not available, fall back to stepped price
        adjustedBasePrice = steppedPrice;
        priceSource = `Stepped Price (${primaryPreference} not available)`;
        console.log(`Primary price (${primaryPreference}) not available, using stepped price: $${adjustedBasePrice}`);
      }
    } else {
      // No price_preference_order or price_point, default to stepped price
      adjustedBasePrice = steppedPrice;
      priceSource = 'Stepped Price';
      console.log(`No price_preference_order or price_point, using stepped price: $${adjustedBasePrice}`);
    }
    
    console.log(`Using ${priceSource} as base price: $${adjustedBasePrice.toFixed(2)}`);

    let cashPercentage = parseFloat(userSettings.defaultCashPercentage || DEFAULT_USER_SETTINGS.defaultCashPercentage) / 100;
    let creditPercentage = parseFloat(userSettings.defaultCreditPercentage || DEFAULT_USER_SETTINGS.defaultCreditPercentage) / 100;
    let cashPrice;
    let creditPrice;

    console.log('Default percentages:', { cashPercentage: cashPercentage * 100, creditPercentage: creditPercentage * 100 });

    if (userSettings.expansionRules && Array.isArray(userSettings.expansionRules)) {
      console.log('Checking expansion rules for:', card.gameName, card.expansionName);
      console.log('Available expansion rules:', userSettings.expansionRules);
      
      // First, check for a specific rule that matches both game and expansion
      let expansionRule = userSettings.expansionRules.find(rule => 
        rule.game === card.gameName && 
        rule.expansion === card.expansionName
      );
      
      // If no specific rule is found, check for a game-only rule
      if (!expansionRule) {
        console.log('No specific expansion rule found, checking for game-only rule');
        expansionRule = userSettings.expansionRules.find(rule => 
          rule.game === card.gameName && 
          (
            !rule.expansion || 
            rule.expansion === '' || 
            rule.expansion === 'All Expansions'
          )
        );
        
        if (expansionRule) {
          console.log('Found game-only rule, applying to all expansions of this game:', expansionRule);
        }
      }

      if (expansionRule) {
        console.log('Found matching expansion rule:', expansionRule);
        
        if (expansionRule.conditions && expansionRule.conditions[condition]) {
          const ruleCondition = expansionRule.conditions[condition];
          console.log('Applying expansion rule condition:', ruleCondition);
          
          if (ruleCondition.cash_percentage !== undefined && ruleCondition.credit_percentage !== undefined) {
            cashPercentage = parseFloat(ruleCondition.cash_percentage) / 100;
            creditPercentage = parseFloat(ruleCondition.credit_percentage) / 100;
            console.log(`Applied expansion-specific percentages: Cash ${cashPercentage * 100}%, Credit ${creditPercentage * 100}%`);
          } else {
            console.log('Missing percentages in expansion rule condition, falling back to general pricing rules');
            applyGeneralPricingRules();
          }
        } else {
          console.log('No matching condition in expansion rule, falling back to general pricing rules');
          applyGeneralPricingRules();
        }
      } else {
        console.log('No matching expansion rule found, falling back to general pricing rules');
        applyGeneralPricingRules();
      }
    } else {
      console.log('No expansion rules available, falling back to general pricing rules');
      applyGeneralPricingRules();
    }

function applyGeneralPricingRules() {
      console.log('User settings:', userSettings);
      console.log('Pricing rules available:', userSettings.pricing_rules);
      console.log('Is pricing_rules an array:', Array.isArray(userSettings.pricing_rules));
      
      if (userSettings.pricing_rules && Array.isArray(userSettings.pricing_rules)) {
        console.log('Checking pricing rules:', userSettings.pricing_rules);
        
        // Filter rules for the current game
        const gameRules = userSettings.pricing_rules.filter(rule => rule.game === card.gameName);
        console.log(`Found ${gameRules.length} rules for game ${card.gameName}`);
        
        if (gameRules.length === 0) {
          console.log('No rules found for this game, using default percentages');
          return;
        }
        
        // First, check for rules with exact matching rarity (these take highest priority)
        // Also include "All Rarities" rules but with lower priority
        const exactRarityRules = gameRules.filter(rule => 
          (rule.rarity && card.rarity && rule.rarity === card.rarity) || 
          rule.rarity === "All Rarities"
        );
        
        const exactMatchCount = gameRules.filter(rule => 
          rule.rarity && card.rarity && rule.rarity === card.rarity
        ).length;
        
        const allRaritiesCount = gameRules.filter(rule => 
          rule.rarity === "All Rarities"
        ).length;
        
        console.log(`Found ${exactMatchCount} rules with exact rarity "${card.rarity}" and ${allRaritiesCount} "All Rarities" rules`);
        
        if (exactRarityRules.length > 0) {
          // We have rules with matching rarity, check if any match the price range
          // Special case: if min_value and max_value are both 0, treat it as a rule that applies to all price ranges
          const priceMatchingRarityRules = exactRarityRules.filter(rule =>
            (rule.min_value === 0 && rule.max_value === 0) || 
            (adjustedBasePrice >= rule.min_value && adjustedBasePrice <= rule.max_value)
          );
          
          console.log(`Found ${priceMatchingRarityRules.length} rarity rules matching price range`);
          
          if (priceMatchingRarityRules.length > 0) {
            // We have rarity rules matching the price range
            // Use the first rule in the prioritized list (which will be an exact match if one exists)
            const selectedRule = priceMatchingRarityRules[0];
            console.log('Using prioritized rarity rule:', selectedRule);
            
            if (selectedRule.use_fixed_value === true) {
              // Use fixed value pricing
              cashPrice = selectedRule.cash_value;
              creditPrice = selectedRule.credit_value;
              // Set percentages to 0 since we're using fixed values
              cashPercentage = 0;
              creditPercentage = 0;
              console.log(`Applied rarity-based fixed pricing rule for ${card.rarity}: Cash $${cashPrice}, Credit $${creditPrice}`);
            } else {
              // Use percentage-based pricing
              cashPercentage = selectedRule.cash_percentage / 100;
              creditPercentage = selectedRule.credit_percentage / 100;
              console.log(`Applied rarity-based percentage pricing rule for ${card.rarity}: Cash ${cashPercentage * 100}%, Credit ${creditPercentage * 100}%`);
              console.log('Rule details:', selectedRule);
            }
            return; // Exit early since we found a matching rule
          } else {
            // We have rarity rules but none match the price range
            // Instead of using a rarity rule outside its price range, continue to check non-rarity rules
            console.log(`No rarity rules match the price range of $${adjustedBasePrice.toFixed(2)}, checking non-rarity rules`);
            // Don't return here, fall through to check non-rarity rules
          }
        }
        
        // If we get here, there are no matching rarity rules
        // Check for rules without rarity or with null/empty rarity
        // Don't include "All Rarities" rules here since they're already in exactRarityRules
        const nonRarityRules = gameRules.filter(rule => 
          !rule.rarity || rule.rarity === ''
        );
        
        console.log(`Found ${nonRarityRules.length} rules without rarity specification or with "All Rarities"`);
        
        if (nonRarityRules.length > 0) {
          // Filter by price range
          // Special case: if min_value and max_value are both 0, treat it as a rule that applies to all price ranges
          const priceMatchingRules = nonRarityRules.filter(rule =>
            (rule.min_value === 0 && rule.max_value === 0) || 
            (adjustedBasePrice >= rule.min_value && adjustedBasePrice <= rule.max_value)
          );
          
          console.log(`Found ${priceMatchingRules.length} non-rarity rules matching price range`);
          
          if (priceMatchingRules.length > 0) {
            // We have non-rarity rules matching the price range
            // Check if any of them use fixed values
            const fixedValueRule = priceMatchingRules.find(rule => rule.use_fixed_value === true);
            
            if (fixedValueRule) {
              // Use fixed value pricing
              console.log('Using fixed value pricing rule:', fixedValueRule);
              cashPrice = fixedValueRule.cash_value;
              creditPrice = fixedValueRule.credit_value;
              cashPercentage = 0;
              creditPercentage = 0;
              console.log(`Applied fixed pricing rule: Cash $${cashPrice}, Credit $${creditPrice}`);
              return;
            } else {
              // Use percentage-based pricing from the first matching rule
              const percentageRule = priceMatchingRules[0];
              cashPercentage = percentageRule.cash_percentage / 100;
              creditPercentage = percentageRule.credit_percentage / 100;
              console.log(`Applied percentage pricing rule: Cash ${cashPercentage * 100}%, Credit ${creditPercentage * 100}%`);
              console.log('Rule details:', percentageRule);
              return;
            }
          }
        }
        
        console.log('No applicable pricing rule found, using default percentages');
      } else {
        console.log('No pricing rules available, using default percentages');
      }
    }

    const floorPrice = parseFloat(userSettings.floorPrice || DEFAULT_USER_SETTINGS.floorPrice);
    console.log('Floor price:', floorPrice);

    // Check if cashPrice and creditPrice have already been set by a fixed value rule
    if (typeof cashPrice === 'undefined' && typeof creditPrice === 'undefined') {
      // Calculate prices using percentages (traditional method)
      // Make sure we're using the adjustedBasePrice (chosen base) for calculations
      console.log(`Calculating prices based on adjustedBasePrice: $${adjustedBasePrice}`);
      console.log(`Cash percentage: ${cashPercentage * 100}%, Credit percentage: ${creditPercentage * 100}%`);
      
      cashPrice = cashPercentage > 0 ? adjustedBasePrice * cashPercentage : 0;
      creditPrice = creditPercentage > 0 ? adjustedBasePrice * creditPercentage : 0;
      
      console.log(`Calculated prices: Cash $${cashPrice.toFixed(2)}, Credit $${creditPrice.toFixed(2)}`);
    }

    // Check if this is using fixed-value pricing
    const isFixedValue = typeof cashPrice !== 'undefined' && typeof creditPrice !== 'undefined' && cashPercentage === 0 && creditPercentage === 0;
    
    // Apply exchange rate only if not using fixed-value pricing
    // Fixed prices from user_settings are already in the user's currency
    if (!isFixedValue) {
      const exchangeRate = exchangeRates[userCurrency] || 1;
      cashPrice *= exchangeRate;
      creditPrice *= exchangeRate;
    } else {
      console.log('Skipping exchange rate conversion for fixed prices as they are already in user currency');
    }
    
    // For fixed-value pricing, we determine availability based on whether the price is > 0
    // For percentage-based pricing, we check against the floor price
    const cashAvailable = isFixedValue ? cashPrice > 0 : cashPrice >= floorPrice;
    const creditAvailable = isFixedValue ? creditPrice > 0 : creditPrice >= floorPrice;
    
    // An item is bulk only if both cash and credit are unavailable AND it's not using fixed-value pricing
    // If we're using fixed-value pricing, it's never bulk
    const isBulk = isFixedValue ? false : (!cashAvailable && !creditAvailable);
    
    // For percentage-based pricing, set prices to 0 if they're below the floor price
    // For fixed-value pricing, keep the prices as they are
    if (!isFixedValue) {
      if (isBulk) {
        cashPrice = 0;
        creditPrice = 0;
        console.log('Item is bulk, both prices set to 0');
      } else {
        if (!cashAvailable) {
          cashPrice = 0;
          console.log('Cash option not available, price set to 0');
        }
        if (!creditAvailable) {
          creditPrice = 0;
          console.log('Credit option not available, price set to 0');
        }
      }
    }
    
    console.log(`Final pricing: isFixedValue=${isFixedValue}, cashPrice=${cashPrice}, creditPrice=${creditPrice}, isBulk=${isBulk}`);

    let result = {
      cashPrice: parseFloat(cashPrice.toFixed(2)),
      creditPrice: parseFloat(creditPrice.toFixed(2)),
      cashPercentage: cashPercentage,
      creditPercentage: creditPercentage,
      isBulk: isBulk,
      timestamp: currentTime,
      currency: userCurrency,
      cashAvailable: cashAvailable,
      creditAvailable: creditAvailable,
      contactForPrice: false,
      basePrice: adjustedBasePrice,
      isFixedValue: isFixedValue
    };

    // Apply inventory modifier if available
    if (userSettings.inventory_modifiers && Array.isArray(userSettings.inventory_modifiers)) {
      // Check if we have a cached inventory level
      const inventoryCacheKey = `${username}-${card.productId}`;
      let inventoryCount = inventoryCache.get(inventoryCacheKey);
      
      // If not cached, fetch it
      if (inventoryCount === undefined) {
        // Fetch inventory level synchronously to ensure we always use the modified prices
        try {
          // Make a synchronous XMLHttpRequest to get inventory level
          const xhr = new XMLHttpRequest();
          const url = `${BACKEND_URL}/inventory-level/${username}/${card.productId}`;
          xhr.open('GET', url, false); // false makes the request synchronous
          xhr.send();
          
          if (xhr.status === 200) {
            const data = JSON.parse(xhr.responseText);
            inventoryCount = data.inventoryCount;
            console.log(`Inventory level for product ${card.productId}: ${inventoryCount}`);
            inventoryCache.set(inventoryCacheKey, inventoryCount);
          } else {
            console.error(`Error fetching inventory level: ${xhr.status}`);
            inventoryCount = 0; // Default to 0 if there's an error
            inventoryCache.set(inventoryCacheKey, inventoryCount);
          }
        } catch (error) {
          console.error(`Error fetching inventory level: ${error}`);
          inventoryCount = 0; // Default to 0 if there's an error
          inventoryCache.set(inventoryCacheKey, inventoryCount);
        }
      }
      
      // Always apply the inventory modifier with the inventory count we have
      result = applyInventoryModifier(inventoryCount, {...result});
      
      // Update the cache with the modified result
      priceCache.set(cacheKey, result);
    }

    // Helper function to apply inventory modifier
    function applyInventoryModifier(inventoryCount, resultObj) {
      // Create a copy of the result object to modify
      const modifiedResult = {...resultObj};
      
      // Find the applicable inventory modifier
      const inventoryModifier = userSettings.inventory_modifiers.find(modifier => 
        inventoryCount >= modifier.min_value && inventoryCount <= modifier.max_value
      );
      
      if (inventoryModifier) {
        // Apply the percentage modifier to both cash and credit prices
        const modifierPercentage = inventoryModifier.percentage / 100;
        const modifiedCashPrice = parseFloat((modifiedResult.cashPrice * modifierPercentage).toFixed(2));
        const modifiedCreditPrice = parseFloat((modifiedResult.creditPrice * modifierPercentage).toFixed(2));
        
        console.log(`Applying inventory modifier: ${inventoryModifier.percentage}% based on inventory count: ${inventoryCount}`);
        console.log(`Original prices: Cash $${modifiedResult.cashPrice}, Credit $${modifiedResult.creditPrice}`);
        console.log(`Modified prices: Cash $${modifiedCashPrice}, Credit $${modifiedCreditPrice}`);
        
        // Update the result with the modified prices
        modifiedResult.cashPrice = modifiedCashPrice;
        modifiedResult.creditPrice = modifiedCreditPrice;
        
        // Add inventory modifier info to the result
        modifiedResult.inventoryCount = inventoryCount;
        modifiedResult.inventoryModifier = inventoryModifier.percentage;
      }
      
      // Return the modified result
      return modifiedResult;
    }

    priceCache.set(cacheKey, result);
    console.log(`Final calculated prices for ${card.name}:`, result);
    return result;
  }, [userSettings, exchangeRates, userCurrency]);

  const addToCart = useCallback((card, condition, printing, quantity, skuId, isCash, language = 'EN', orderNote = '', nonEnglish = null) => {
    // Check if this is a custom item (which already has prices)
    if (card.isCustomItem) {
      console.log('Adding custom item to cart:', card);
      setCartItems(prevItems => [...prevItems, card]);
      return;
    }
    
    // For regular cards, calculate the price
    const priceInfo = calculatePrice(card, condition, printing, language);
    const { cashPrice, creditPrice, isBulk, timestamp, basePrice, isDerivedPrice, derivedPercentage } = priceInfo;
    
    const newItem = {
      ...card,
      condition,
      printing,
      language,
      quantity: Math.min(quantity, 4),
      originalBasePrice: basePrice,
      basePrice,
      cashPrice,
      creditPrice,
      skuId,
      isCash,
      isBulk,
      timestamp,
      isDerivedPrice,
      derivedPercentage,
      cartItemId: Date.now(),
      orderNote,
      nonEnglish // Include the nonEnglish field if provided
    };
    console.log('Adding item to cart:', {
      name: newItem.name,
      condition: newItem.condition,
      printing: newItem.printing,
      originalBasePrice: newItem.originalBasePrice,
      basePrice: newItem.basePrice,
      cashPrice: newItem.cashPrice,
      creditPrice: newItem.creditPrice
    });
    setCartItems(prevItems => [...prevItems, newItem]);
  }, [calculatePrice]);

  const removeFromCart = useCallback((item) => {
    if (item.cartItemId === 'all') {
      // Special case to clear the entire cart
      setCartItems([]);
      return;
    }
    setCartItems(prevItems => prevItems.filter(cartItem => cartItem.cartItemId !== item.cartItemId));
  }, []);

  const updateQuantity = useCallback((item, newQuantity) => {
    setCartItems(prevItems => prevItems.map(cartItem =>
      cartItem.cartItemId === item.cartItemId
        ? { ...cartItem, quantity: Math.min(Math.max(newQuantity, 1), 4) }
        : cartItem
    ));
  }, []);

  const togglePaymentType = useCallback((item) => {
    setCartItems(prevItems => prevItems.map(cartItem =>
      cartItem.cartItemId === item.cartItemId
        ? { ...cartItem, isCash: !cartItem.isCash }
        : cartItem
    ));
  }, []);

  const startCheckout = useCallback(() => {
    setIsCheckingOut(true);
  }, []);

  const cancelCheckout = useCallback(() => {
    setIsCheckingOut(false);
  }, []);

  const goToMainPage = useCallback(() => {
    setIsCheckingOut(false);
  }, []);

  const completeCheckout = useCallback((checkoutData) => {
    setCartItems([]);
    localStorage.removeItem('cartItems');
    console.log('Cart cleared. New cart state:', []);
  }, []);

  const clearCart = useCallback(() => {
    console.log('Clearing cart...');
    setCartItems([]);
    localStorage.removeItem('cartItems');
    console.log('Cart cleared. New cart state:', []);
  }, []);

  // Save buylist functions
  const handleOpenSaveBuylistPopup = useCallback(() => {
    setShowSaveBuylistPopup(true);
  }, []);

  const handleCloseSaveBuylistPopup = useCallback(() => {
    setShowSaveBuylistPopup(false);
  }, []);

  // Load buylist functions
  const handleOpenLoadBuylistPopup = useCallback(() => {
    setShowLoadBuylistPopup(true);
  }, []);

  const handleCloseLoadBuylistPopup = useCallback(() => {
    setShowLoadBuylistPopup(false);
  }, []);

  const { showSuccess } = useNotification();

  const handleLoadBuylist = useCallback((savedBuylist) => {
    console.log('Loading saved buylist:', savedBuylist);
    
    // Clear the current cart first
    setCartItems([]);
    
    // Add each item directly to the cart with original prices
    const cartItemsToAdd = savedBuylist.cart_items.map(item => ({
      ...item,
      cartItemId: Date.now() + Math.random() // Generate a new unique ID
    }));
    
    // Update the cart with the saved items
    setCartItems(cartItemsToAdd);
    
    // Close the popup
    setShowLoadBuylistPopup(false);
    
    // Show a success message
    const itemCount = savedBuylist.cart_items.length;
    showSuccess(`Buylist loaded successfully! Added ${itemCount} item${itemCount !== 1 ? 's' : ''} to your cart.`);
  }, [showSuccess]);

  const handleSearchChange = useCallback((e) => {
    setSearchTerm(e.target.value);
  }, []);

  const handleAbbrChange = useCallback((e) => {
    setSearchAbbr(e.target.value);
  }, []);

  const handleNumberChange = useCallback((e) => {
    setSearchNumber(e.target.value);
  }, []);

  const handleSearchSubmit = useCallback(async (e) => {
    e.preventDefault();
    setIsSearchLoading(true);
    setIsInitialLoad(false);
    
    // Close the custom buylist section when performing a search
    setShowCustomItems(false);
    
    try {
      let translatedTerm = searchTerm;
      if (isTranslationAvailable() && i18n.language !== 'en') {
        translatedTerm = await translateText(searchTerm, i18n.language, 'en');
      } else if (!isTranslationAvailable()) {
        console.warn("Translation is not available. Using original search term.");
      }

      const normalizedNumber = normalizeNumber(searchNumber);
      
      setSearchParams(prev => ({
        ...prev,
        term: translatedTerm,
        abbr: searchAbbr.toLowerCase(),
        number: normalizedNumber,
        page: 1
      }));
    } catch (error) {
      console.error("Error translating search term:", error);
      setSearchParams(prev => ({
        ...prev,
        term: searchTerm,
        abbr: searchAbbr.toLowerCase(),
        number: normalizeNumber(searchNumber),
        page: 1
      }));
    } finally {
      setIsSearchLoading(false);
    }
  }, [searchTerm, searchAbbr, searchNumber, i18n.language]);

  const handleGameChange = useCallback((e) => {
    const newGame = e.target.value;
    setSearchParams(prev => ({
      ...prev,
      game: newGame,
      set: '',
      page: 1
    }));
    setIsInitialLoad(false);

    if (!newGame) {
      setAllExpansionNames([]);
    }
  }, []);

  const handleSetChange = useCallback((e) => {
    // Close the custom buylist section when changing set
    setShowCustomItems(false);
    
    setSearchParams(prev => ({
      ...prev,
      set: e.target.value,
      page: 1
    }));
    setIsInitialLoad(false);
  }, []);

  const handleRarityChange = useCallback((e) => {
    // Close the custom buylist section when changing rarity
    setShowCustomItems(false);
    
    setSearchParams(prev => ({
      ...prev,
      rarity: e.target.value,
      page: 1
    }));
    setIsInitialLoad(false);
  }, []);

  const handlePageChange = useCallback((newPage) => {
    console.log('Changing to page:', newPage);
    
    // Close the custom buylist section when changing page
    setShowCustomItems(false);
    
    setSearchParams(prev => ({
      ...prev,
      page: newPage
    }));
    setIsInitialLoad(false);
  }, []);

  const handleImageRecognized = useCallback(async (productIds) => {
    setIsLoading(true);
    setIsInitialLoad(false);
    try {
      console.log("Image recognized, productIds:", productIds);
      const recognizedCards = [];
      for (const productId of productIds) {
        const cardData = await fetchCatalog({ ...searchParams, term: '', productId });
        if (cardData && cardData.items && cardData.items.length > 0) {
          recognizedCards.push(cardData.items[0]);
        }
      }
      setRecognizedCard(recognizedCards.length > 0 ? recognizedCards[0] : null);
      setCards(recognizedCards);
    } catch (error) {
      console.error("Error fetching catalog for recognized images:", error);
      setError(`Failed to fetch catalog for recognized images. Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [fetchCatalog, searchParams]);

  // State for mobile detection
  const [isMobile, setIsMobile] = useState(false);
  
  // Detect mobile devices and check for checkout view in URL
  useEffect(() => {
    const checkMobile = () => {
      const mobileCheck = window.innerWidth <= 768;
      setIsMobile(mobileCheck);
    };
    
    // Check on initial load
    checkMobile();
    
    // Check for view=checkout in URL
    const urlParams = new URLSearchParams(window.location.search);
    const view = urlParams.get('view');
    if (view === 'checkout') {
      setIsCheckingOut(true);
    }
    
    // Add resize listener
    window.addEventListener('resize', checkMobile);
    
    // Clean up
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Check user settings for template preference
  useEffect(() => {
    if (userSettings) {
      setUseAlternativeUI(userSettings.use_v2_template === true);
    }
  }, [userSettings]);

  // Calculate if cash limit has been reached
  const isCashLimitReached = useMemo(() => {
    if (!userSettings || !userSettings.cash_limit) return false;
    
    const cashSubtotal = cartItems.reduce((sum, item) => 
      sum + (item.isCash ? parseFloat(item.cashPrice) * item.quantity : 0), 0);
    
    return cashSubtotal >= userSettings.cash_limit;
  }, [cartItems, userSettings]);

  const memoizedCardList = useMemo(() => (
    <CardList
      cards={cards}
      addToCart={addToCart}
      allowedConditions={allowedConditions}
      floorPrice={floorPrice}
      bulkSettings={bulkSettings}
      userSettings={userSettings}
      excludedSku={excludedSkus}
      calculatePrice={calculatePrice}
      exchangeRates={exchangeRates}
      isCashLimitReached={isCashLimitReached}
    />
  ), [cards, addToCart, allowedConditions, floorPrice, bulkSettings, userSettings, excludedSkus, calculatePrice, exchangeRates, isCashLimitReached]);

  if (error) {
    return <div>{t('Error')}: {error}</div>;
  }

  if (isCheckingOut) {
    return (
      <CheckoutPage
        cartItems={cartItems}
        updateQuantity={updateQuantity}
        removeFromCart={removeFromCart}
        completeCheckout={completeCheckout}
        cancelCheckout={cancelCheckout}
        goToMainPage={goToMainPage}
        userSettings={userSettings}
        username={username}
        calculatePrice={calculatePrice}
        togglePaymentType={togglePaymentType}
      />
    );
  }

  return (
    <ErrorBoundary>
      {isMobile ? (
        <MobileApp
          enabledGames={enabledGames}
          cartItems={cartItems}
          removeFromCart={removeFromCart}
          checkout={startCheckout}
          updateQuantity={updateQuantity}
          togglePaymentType={togglePaymentType}
          clearCart={clearCart}
          userSettings={userSettings}
          cards={cards}
          addToCart={addToCart}
          allowedConditions={allowedConditions}
          floorPrice={floorPrice}
          bulkSettings={bulkSettings}
          excludedSku={excludedSkus}
          calculatePrice={calculatePrice}
          exchangeRates={exchangeRates}
          handleGameChange={handleGameChange}
          handleSetChange={handleSetChange}
          searchParams={{
            ...searchParams,
            allExpansionNames: allExpansionNames,
            allRarities: allRarities,
            totalPages: totalPages
          }}
          setSearchParams={setSearchParams}
          isLoading={isLoading}
          isInitialLoad={isInitialLoad}
          setIsInitialLoad={setIsInitialLoad}
          customItems={customItems}
          fetchCustomItems={fetchCustomItems}
        />
      ) : useAlternativeUI ? (
        <AlternativeApp
          enabledGames={enabledGames}
          cartItems={cartItems}
          removeFromCart={removeFromCart}
          checkout={startCheckout}
          updateQuantity={updateQuantity}
          togglePaymentType={togglePaymentType}
          clearCart={clearCart}
          userSettings={userSettings}
          cards={cards}
          addToCart={addToCart}
          allowedConditions={allowedConditions}
          floorPrice={floorPrice}
          bulkSettings={bulkSettings}
          excludedSku={excludedSkus}
          calculatePrice={calculatePrice}
          exchangeRates={exchangeRates}
          handleGameChange={handleGameChange}
          handleSetChange={handleSetChange}
          searchParams={{
            ...searchParams,
            allExpansionNames: allExpansionNames,
            allRarities: allRarities,
            totalPages: totalPages
          }}
          setSearchParams={setSearchParams}
          isLoading={isLoading}
          isInitialLoad={isInitialLoad}
          setIsInitialLoad={setIsInitialLoad}
          customItems={customItems}
          fetchCustomItems={fetchCustomItems}
        />
      ) : (
        <div className="app">
          {showEnquiryPopup && (
            <EnquiryPopup
              onClose={handleCloseEnquiryPopup}
              onContinue={handleContinueEnquiry}
            />
          )}
          {showSaveBuylistPopup && (
            <SaveBuylistPopup
              onClose={handleCloseSaveBuylistPopup}
              cartItems={cartItems}
              username={username}
              userSettings={userSettings}
            />
          )}
          {showLoadBuylistPopup && (
            <LoadBuylistPopup
              onClose={handleCloseLoadBuylistPopup}
              onLoadBuylist={handleLoadBuylist}
              username={username}
              userSettings={userSettings}
            />
          )}
          {/* Price change notification removed as we're keeping original prices */}
          {/* Left sidebar removed */}
          <div className="main-content">
            <div className="filters">
            <div className="search-and-upload">
              <div className="search-mode-toggle">
                <button 
                  className={`search-mode-button ${!showBulkSearch ? 'active' : ''}`}
                  onClick={() => setShowBulkSearch(false)}
                >
                  {t('Single Search')}
                </button>
                <button 
                  className={`search-mode-button ${showBulkSearch ? 'active' : ''}`}
                  onClick={() => setShowBulkSearch(true)}
                >
                  {t('Bulk Search')}
                </button>
                <button 
                  className="search-mode-button load-buylist-button-main"
                  onClick={handleOpenLoadBuylistPopup}
                >
                  {t('Load Buylist')}
                </button>
                {customItems.length > 0 && (
                  <button 
                    className="search-mode-button custom-buylist-button-main"
                    onClick={() => setShowCustomItems(!showCustomItems)}
                  >
                    {showCustomItems ? t('Hide Custom Items') : (userSettings?.custom_buylist_button_label || t('We Also Buy'))}
                  </button>
                )}
              </div>
              {!showBulkSearch ? (
                <form onSubmit={handleSearchSubmit} className="search-container">
                  <input
                    type="text"
                    placeholder={t('Search by Name')}
                    className="search-input"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  <input
                    type="text"
                    placeholder={t('Search by Abbreviation')}
                    className="search-input"
                    value={searchAbbr}
                    onChange={handleAbbrChange}
                  />
                  <input
                    type="text"
                    placeholder={t('Search by Number')}
                    className="search-input"
                    value={searchNumber}
                    onChange={handleNumberChange}
                  />
                  <button type="submit" className="search-button" disabled={!searchParams.game}>
                    {searchParams.game ? t('Search') : t('Select a game first')}
                  </button>
                </form>
              ) : null}
            </div>
              {!isTranslationAvailable() && (
                <p className="translation-warning">
                  {t('Translation is not available. Searches will be performed with the original text.')}
                </p>
              )}
              <select className="filter-select" value={searchParams.game} onChange={handleGameChange}>
                <option value="">{t('Please select a game')}</option>
                {enabledGames.map((game, index) => (
                  <option key={index} value={game}>{game}</option>
                ))}
              </select>
              <select 
                className="filter-select" 
                value={searchParams.set} 
                onChange={handleSetChange}
                disabled={!searchParams.game}
              >
                <option value="">{t('All Sets')}</option>
                {allExpansionNames.map((set, index) => (
                  <option key={index} value={set}>{set}</option>
                ))}
              </select>
              <select 
                className="filter-select" 
                value={searchParams.rarity} 
                onChange={handleRarityChange}
                disabled={!searchParams.game}
              >
                <option value="">{t('All Rarities')}</option>
                {allRarities.map((rarity, index) => (
                  <option key={index} value={rarity}>{rarity}</option>
                ))}
              </select>
            </div>
            {searchError && (
              <div className="search-error">
                <p>{searchError}</p>
              </div>
            )}
            
            {/* Custom Buylist Items Table */}
            {showCustomItems && customItems.length > 0 && (
              <div className="custom-buylist-container main-custom-buylist">
                <h3>Custom Items We Buy</h3>
                <table className="custom-buylist-table">
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Description</th>
                      <th>Cash Price</th>
                      <th>Credit Price</th>
                      <th>Quantity</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customItems.map((item) => (
                      <tr key={item._id.$oid}>
                        <td>{item.title}</td>
                        <td>{item.description}</td>
                        <td>{currencySymbols[userCurrency] || '$'}{item.cash_price}</td>
                        <td>{currencySymbols[userCurrency] || '$'}{item.credit_price}</td>
                        <td>
                          <input 
                            type="number" 
                            min="1" 
                            max="999" 
                            defaultValue="1"
                            className="custom-item-quantity"
                          />
                        </td>
                        <td>
                          <button 
                            className="add-custom-item-cash"
                            onClick={(e) => {
                              const quantity = parseInt(e.target.parentNode.parentNode.querySelector('.custom-item-quantity').value);
                              const customItem = {
                                name: item.title,
                                description: item.description,
                                cashPrice: item.cash_price,
                                creditPrice: item.credit_price,
                                quantity: quantity,
                                isCash: true,
                                cartItemId: `custom-${item._id.$oid}-${Date.now()}`,
                                condition: 'N/A',
                                printing: 'N/A',
                                isCustomItem: true
                              };
                              setCartItems(prevItems => [...prevItems, customItem]);
                            }}
                          >
                            Add (Cash)
                          </button>
                          <button 
                            className="add-custom-item-credit"
                            onClick={(e) => {
                              const quantity = parseInt(e.target.parentNode.parentNode.querySelector('.custom-item-quantity').value);
                              const customItem = {
                                name: item.title,
                                description: item.description,
                                cashPrice: item.cash_price,
                                creditPrice: item.credit_price,
                                quantity: quantity,
                                isCash: false,
                                cartItemId: `custom-${item._id.$oid}-${Date.now()}`,
                                condition: 'N/A',
                                printing: 'N/A',
                                isCustomItem: true
                              };
                              setCartItems(prevItems => [...prevItems, customItem]);
                            }}
                          >
                            Add (Credit)
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {isSearchLoading ? (
              <div className="loading-screen">
                <p>{t('Just fetching you the best prices...')}</p>
              </div>
            ) : isLoading ? (
              <div>{t('Loading')}...</div>
            ) : (
              <>
                {showBulkSearch ? (
                  <BulkSearchPage
                    addToCart={addToCart}
                    userSettings={userSettings}
                    allowedConditions={allowedConditions}
                    calculatePrice={calculatePrice}
                    exchangeRates={exchangeRates}
                  />
                ) : isInitialLoad ? (
                  <Infographics />
                ) : (
                  userSettings && memoizedCardList
                )}
                {!isInitialLoad && (
                  <div className="pagination">
                    {cards.length > 0 && (
                      <>
                        {totalPages > 1 ? (
                          <>
                            <button onClick={() => handlePageChange(searchParams.page - 1)} disabled={searchParams.page === 1}>{t('Previous')}</button>
                            <span>{t('Page')} {searchParams.page} {t('of')} {totalPages}</span>
                            <button onClick={() => handlePageChange(searchParams.page + 1)} disabled={searchParams.page === totalPages}>{t('Next')}</button>
                          </>
                        ) : (
                          <span className="showing-all-results">
                            {t('Showing all')} {cards.length} {t('results')}
                          </span>
                        )}
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
          <div className={`cart-container ${isCartVisible ? 'visible' : 'hidden'}`}>
            <Cart
              cartItems={cartItems}
              removeFromCart={removeFromCart}
              checkout={startCheckout}
              updateQuantity={updateQuantity}
              togglePaymentType={togglePaymentType}
              userSettings={userSettings}
              clearCart={clearCart}
              onSaveForLater={handleOpenSaveBuylistPopup}
              onLoadBuylist={handleOpenLoadBuylistPopup}
              calculatePrice={calculatePrice}
              allowedConditions={allowedConditions}
              setCartItems={setCartItems}
            />
          </div>
          <button 
            className="cart-toggle-button"
            onClick={() => setIsCartVisible(!isCartVisible)}
          >
            {isCartVisible ? t('Hide Cart') : t('Show Cart')} ({cartItems.length})
          </button>
        </div>
      )}
    </ErrorBoundary>
  );
};

export default App;
