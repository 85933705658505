import React from 'react';
import './MobileHeader.css';

const MobileHeader = ({ cartItemCount, onCartClick, onHomeClick, onFiltersClick, activeView }) => {
  return (
    <header className="mobile-header">
      <div className="mobile-header-left">
        <button 
          className={`mobile-header-button ${activeView === 'home' ? 'active' : ''}`} 
          onClick={onHomeClick}
          aria-label="Home"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
            <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
          </svg>
        </button>
      </div>
      
      <div className="mobile-header-title">
        {activeView === 'home' && 'Buylist'}
        {activeView === 'search' && 'Search'}
        {activeView === 'cart' && 'Cart'}
      </div>
      
      <div className="mobile-header-right">
        {activeView === 'search' && (
          <button 
            className="mobile-header-button" 
            onClick={onFiltersClick}
            aria-label="Filters"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
              <path d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z" />
            </svg>
          </button>
        )}
        
        <button 
          className={`mobile-header-button cart-button ${activeView === 'cart' ? 'active' : ''}`} 
          onClick={onCartClick}
          aria-label="Cart"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
            <path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z" />
          </svg>
          {cartItemCount > 0 && (
            <span className="cart-badge">{cartItemCount}</span>
          )}
        </button>
      </div>
    </header>
  );
};

export default MobileHeader;
