import React, { useEffect } from 'react';
import './MobileOrderCompletionPopup.css';

const MobileOrderCompletionPopup = ({ onClose, isProcessing, orderCompleted, onResetApp }) => {
  useEffect(() => {
    // Disable scrolling on the body when the popup is open
    document.body.style.overflow = 'hidden';
    return () => {
      // Re-enable scrolling when the component unmounts
      document.body.style.overflow = 'unset';
    };
  }, []);

  const renderContent = () => {
    if (isProcessing) {
      return (
        <div className="mobile-order-processing">
          <div className="mobile-loading-spinner"></div>
          <h2>Processing Order</h2>
          <p>Please wait while we process your order...</p>
        </div>
      );
    } else if (orderCompleted) {
      return (
        <div className="mobile-order-success">
          <div className="mobile-success-icon">✓</div>
          <h2>Order Received</h2>
          <div className="mobile-order-message">
            <p>Thank you for your order! It has been successfully received.</p>
            <p>A confirmation email has been sent to your email address.</p>
          </div>
          <div className="mobile-important-notice">
            <strong>Important:</strong> Please do not send any items at this time. 
            Await further emails for instructions on packaging and shipping.
          </div>
          <button 
            className="mobile-close-button"
            onClick={() => { onClose(); onResetApp(); }}
          >
            CONTINUE SHOPPING
          </button>
        </div>
      );
    } else {
      return (
        <div className="mobile-order-error">
          <div className="mobile-error-icon">✗</div>
          <h2>Error</h2>
          <p>There was an error processing your order. Please try again or contact support.</p>
          <button 
            className="mobile-close-button"
            onClick={onClose}
          >
            TRY AGAIN
          </button>
        </div>
      );
    }
  };

  return (
    <div className="mobile-order-completion-overlay">
      <div className="mobile-order-completion-popup">
        {renderContent()}
      </div>
    </div>
  );
};

export default MobileOrderCompletionPopup;
