import React, { useState, useEffect } from 'react';
import MobileCart from './MobileCart';
import TermsAndConditionsPopup from '../../components/TermsAndConditionsPopup';
import MobileOrderCompletionPopup from './MobileOrderCompletionPopup';
import { BACKEND_URL } from '../../config';
import './MobileCheckoutPage.css';

// Define currency symbols
const currencySymbols = {
  USD: '$', EUR: '€', GBP: '£', JPY: '¥', CAD: 'C$', AUD: 'A$', CHF: 'CHF', CNY: '¥', SEK: 'kr', NZD: 'NZ$',
  MXN: 'Mex$', SGD: 'S$', HKD: 'HK$', NOK: 'kr', KRW: '₩', TRY: '₺', RUB: '₽', INR: '₹', BRL: 'R$', ZAR: 'R'
};

const MobileCheckoutPage = ({
  cartItems: initialCartItems,
  updateQuantity,
  removeFromCart,
  completeCheckout,
  cancelCheckout,
  goToMainPage,
  userSettings,
  username,
  calculatePrice,
  togglePaymentType,
  resetApp,
  clearCart
}) => {
  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [orderNotes, setOrderNotes] = useState('');
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [localCartItems, setLocalCartItems] = useState(initialCartItems);
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [showCompletionPopup, setShowCompletionPopup] = useState(false);
  const [orderCompleted, setOrderCompleted] = useState(false);
  const [showCart, setShowCart] = useState(false);

  useEffect(() => {
    setLocalCartItems(initialCartItems);
  }, [initialCartItems]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!termsAgreed) {
      setError('You must agree to the terms and conditions.');
      return;
    }

    setIsSubmitting(true);
    setError(null);

    const orderData = {
      customer_name: customerName,
      customer_email: customerEmail,
      termsAgreed,
      orderNotes,
      username,
      line_items: localCartItems.map(item => {
        const offeredPrice = item.isCash ? item.cashPrice : item.creditPrice;
        const lowPrice = item.originalBasePrice || item.basePrice;
        
        // Ensure skuId is a number or null
        let skuId = null;
        if (item.skuId !== undefined && item.skuId !== null) {
          skuId = Number(item.skuId);
          if (isNaN(skuId)) {
            console.warn(`Invalid skuId for item ${item.name}: ${item.skuId}`);
            skuId = null;
          }
        }

        // Collect any order notes from items
        if (item.orderNote && !orderNotes.includes(item.orderNote)) {
          if (orderNotes) {
            orderNotes += "\n" + item.orderNote;
          } else {
            orderNotes = item.orderNote;
          }
        }
        
        return {
          productId: item.productId,
          skuId: skuId,
          name: item.name,
          printType: item.printing,
          condition: item.condition,
          language: 'EN',
          quantity: item.quantity,
          offeredCashPrice: item.cashPrice,
          offeredCreditPrice: item.creditPrice,
          type: item.isCash ? 'Cash' : 'Credit',
          offeredPrice: offeredPrice,
          lowPrice: lowPrice,
        };
      }),
    };

    try {
      const response = await fetch(`${BACKEND_URL}/orders`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(orderData),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const data = await response.json();
      setOrderCompleted(true);
      setShowCompletionPopup(true);
      
      window.parent.postMessage({ type: 'ORDER_COMPLETED', orderData: data }, '*');
    } catch (err) {
      console.error('Error submitting order:', err);
      setError(`An error occurred during checkout: ${err.message}. Please try again or contact support if the issue persists.`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUpdateQuantity = (item, newQuantity) => {
    const updatedItems = localCartItems.map(cartItem =>
      cartItem.cartItemId === item.cartItemId
        ? { ...cartItem, quantity: Math.min(Math.max(newQuantity, 1), 4) }
        : cartItem
    );
    setLocalCartItems(updatedItems);
    updateQuantity(item, newQuantity);
    
    window.parent.postMessage({ type: 'CART_UPDATED', cartItems: updatedItems }, '*');
  };

  const handleRemoveFromCart = (item) => {
    const updatedItems = localCartItems.filter(cartItem => cartItem.cartItemId !== item.cartItemId);
    setLocalCartItems(updatedItems);
    removeFromCart(item);
    
    window.parent.postMessage({ type: 'CART_UPDATED', cartItems: updatedItems }, '*');
  };

  const handleTogglePaymentType = (item) => {
    const updatedItems = localCartItems.map(cartItem =>
      cartItem.cartItemId === item.cartItemId
        ? { ...cartItem, isCash: !cartItem.isCash }
        : cartItem
    );
    setLocalCartItems(updatedItems);
    togglePaymentType(item);
    
    window.parent.postMessage({ type: 'CART_UPDATED', cartItems: updatedItems }, '*');
  };

  const openTermsAndConditions = (e) => {
    e.preventDefault();
    setShowTermsPopup(true);
  };

  const calculateTotal = () => {
    return localCartItems.reduce((total, item) => {
      const price = item.isCash ? item.cashPrice : item.creditPrice;
      return total + (price * item.quantity);
    }, 0);
  };

  const handleCompletionPopupClose = () => {
    setShowCompletionPopup(false);
    setCustomerName('');
    setCustomerEmail('');
    setOrderNotes('');
    setTermsAgreed(false);
    setLocalCartItems([]);
    setOrderCompleted(false);
    
    window.parent.postMessage({ type: 'RESET_APP' }, '*');
  };

  const toggleCartView = () => {
    setShowCart(!showCart);
  };

  const renderCheckoutContent = () => {
    if (orderCompleted) {
      return null;
    }
    return (
      <div className="mobile-checkout-page">
        <div className="mobile-checkout-header">
          <button onClick={goToMainPage} className="mobile-home-button">
            <span className="mobile-home-icon">&#8962;</span>
          </button>
          <div className="mobile-cart-icon">&#128722;</div>
        </div>
        
        {error && <div className="mobile-error" role="alert">{error}</div>}
        
        <div className="mobile-checkout-content">
          <div className="mobile-total">
            <span>Total:</span> <strong>{userSettings && userSettings.currency ? 
              (currencySymbols[userSettings.currency] || '$') : '$'}
              {calculateTotal().toFixed(2)}</strong>
          </div>
          
          <div className="mobile-checkout-form">
            <h2>CUSTOMER INFORMATION</h2>
            <form onSubmit={handleSubmit}>
              <div className="mobile-form-group">
                <label htmlFor="customerName">Name</label>
                <input
                  id="customerName"
                  type="text"
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                  required
                  placeholder="Enter your full name"
                  aria-required="true"
                />
              </div>
              <div className="mobile-form-group">
                <label htmlFor="customerEmail">Email</label>
                <input
                  id="customerEmail"
                  type="email"
                  value={customerEmail}
                  onChange={(e) => setCustomerEmail(e.target.value)}
                  required
                  placeholder="Enter your email address"
                  aria-required="true"
                />
              </div>
              <div className="mobile-form-group">
                <label htmlFor="orderNotes">Order Notes (optional)</label>
                <textarea
                  id="orderNotes"
                  value={orderNotes}
                  onChange={(e) => setOrderNotes(e.target.value)}
                  placeholder="Any special instructions or comments?"
                />
              </div>
              <div className="mobile-form-group mobile-checkbox">
                <label>
                  <input
                    type="checkbox"
                    checked={termsAgreed}
                    onChange={(e) => setTermsAgreed(e.target.checked)}
                    required
                    aria-required="true"
                  />
                  <span>I agree to the <a href="#" onClick={openTermsAndConditions}>terms and conditions</a></span>
                </label>
              </div>
              <div className="mobile-button-group">
                <button type="submit" className="mobile-submit-button" disabled={isSubmitting}>
                  {isSubmitting ? 'Processing...' : 'SUBMIT ORDER'}
                </button>
              </div>
            </form>
          </div>
        </div>
        
        <div className="mobile-checkout-footer">
          <p>Secure checkout powered by <a href="https://tcgsync.com" target="_blank" rel="noopener noreferrer">TCGSync</a></p>
          <div className="mobile-security-badges">
            <span className="mobile-security-badge">SSL Secured</span>
            <span className="mobile-security-badge">256-bit Encryption</span>
          </div>
        </div>
        
        {showTermsPopup && (
          <TermsAndConditionsPopup
            termsAndConditions={userSettings.tncS}
            onClose={() => setShowTermsPopup(false)}
          />
        )}
      </div>
    );
  };

  return (
    <>
      {showCompletionPopup && (
        <MobileOrderCompletionPopup 
          onClose={handleCompletionPopupClose} 
          isProcessing={isSubmitting}
          orderCompleted={orderCompleted}
          onResetApp={() => window.parent.postMessage({ type: 'RESET_APP' }, '*')}
        />
      )}
      {renderCheckoutContent()}
    </>
  );
};

export default MobileCheckoutPage;
