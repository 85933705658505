import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './MobileCart.css';

// Currency symbols for formatting prices
const currencySymbols = {
  USD: '$', EUR: '€', GBP: '£', JPY: '¥', CAD: 'C$', AUD: 'A$', CHF: 'CHF', CNY: '¥', SEK: 'kr', NZD: 'NZ$',
  MXN: 'Mex$', SGD: 'S$', HKD: 'HK$', NOK: 'kr', KRW: '₩', TRY: '₺', RUB: '₽', INR: '₹', BRL: 'R$', ZAR: 'R'
};

const MobileCart = ({
  cartItems,
  removeFromCart,
  updateQuantity,
  togglePaymentType,
  checkout,
  clearCart,
  userSettings,
  onClose
}) => {
  const { t } = useTranslation();
  const [subtotal, setSubtotal] = useState({ cash: 0, credit: 0 });
  const [expandedItem, setExpandedItem] = useState(null);

  // Helper function to format prices with the correct currency symbol
  const formatPrice = (price) => {
    if (!price && price !== 0) return '-';
    const currencySymbol = currencySymbols[userSettings?.currency] || '$';
    return `${currencySymbol}${price.toFixed(2)}`;
  };

  useEffect(() => {
    calculateSubtotal();
  }, [cartItems]);

  const calculateSubtotal = () => {
    const totals = cartItems.reduce(
      (acc, item) => {
        const price = item.isCash ? item.cashPrice : item.creditPrice;
        return {
          cash: acc.cash + (item.isCash ? price * item.quantity : 0),
          credit: acc.credit + (!item.isCash ? price * item.quantity : 0),
        };
      },
      { cash: 0, credit: 0 }
    );

    setSubtotal(totals);
  };

  const handleQuantityChange = (item, newQuantity) => {
    updateQuantity(item, Math.max(1, Math.min(4, newQuantity)));
  };

  const handleRemoveItem = (item) => {
    removeFromCart(item);
  };

  const handleTogglePaymentType = (item) => {
    togglePaymentType(item);
  };

  const handleCheckout = () => {
    // Call the checkout function to navigate to the checkout page
    if (typeof checkout === 'function') {
      checkout();
    }
  };

  const handleClearCart = () => {
    clearCart();
  };

  const toggleItemExpand = (itemId) => {
    if (expandedItem === itemId) {
      setExpandedItem(null);
    } else {
      setExpandedItem(itemId);
    }
  };

  if (cartItems.length === 0) {
    return (
      <div className="mobile-cart-empty">
        <p>{t('Your cart is empty')}</p>
        <button className="mobile-cart-back-button" onClick={onClose}>
          {t('Back to Search')}
        </button>
      </div>
    );
  }

  return (
    <div className="mobile-cart">
      <div className="mobile-cart-items">
        {cartItems.map((item) => {
          const isExpanded = expandedItem === item.cartItemId;
          const price = item.isCash ? item.cashPrice : item.creditPrice;
          const totalPrice = price * item.quantity;
          
          return (
            <div 
              key={item.cartItemId} 
              className={`mobile-cart-item ${isExpanded ? 'expanded' : ''}`}
            >
              <div 
                className="mobile-cart-item-header" 
                onClick={() => toggleItemExpand(item.cartItemId)}
              >
                <div className="mobile-cart-item-thumbnail">
                  <img 
                    src={item.image || process.env.PUBLIC_URL + '/flags/No-Image-Placeholder.svg.png'} 
                    alt={item.name} 
                    className="mobile-cart-item-thumbnail-img"
                    onError={(e) => {
                      e.target.onerror = null; // Prevent infinite loop
                      e.target.src = process.env.PUBLIC_URL + '/flags/No-Image-Placeholder.svg.png';
                    }}
                  />
                </div>
                <div className="mobile-cart-item-info">
                  <div className="mobile-cart-item-name">{item.name}</div>
                  <div className="mobile-cart-item-details">
                    <span className="mobile-cart-item-condition">{item.condition}</span>
                    <span className="mobile-cart-item-price">{formatPrice(totalPrice)}</span>
                  </div>
                </div>
                <div className="mobile-cart-item-expand-icon">
                  {isExpanded ? '▲' : '▼'}
                </div>
              </div>
              
              {isExpanded && (
                <div className="mobile-cart-item-expanded">
                  <div className="mobile-cart-item-info">
                  <div className="mobile-cart-item-set">{item.expansionName}</div>
                  <div className="mobile-cart-item-printing">Print Type: {item.printing || 'Normal'}</div>
                  <div className="mobile-cart-item-type">
                    {item.isCash ? 'Cash' : 'Credit'} - {formatPrice(price)} each
                  </div>
                  </div>
                  
                  <div className="mobile-cart-item-controls">
                    <div className="mobile-cart-quantity">
                      <button 
                        className="mobile-cart-quantity-button"
                        onClick={() => handleQuantityChange(item, item.quantity - 1)}
                        disabled={item.quantity <= 1}
                      >
                        -
                      </button>
                      <span className="mobile-cart-quantity-value">{item.quantity}</span>
                      <button 
                        className="mobile-cart-quantity-button"
                        onClick={() => handleQuantityChange(item, item.quantity + 1)}
                        disabled={item.quantity >= 4}
                      >
                        +
                      </button>
                    </div>
                    
                    <div className="mobile-cart-item-actions">
                      <button 
                        className="mobile-cart-toggle-type"
                        onClick={() => handleTogglePaymentType(item)}
                      >
                        {item.isCash ? 'Switch to Credit' : 'Switch to Cash'}
                      </button>
                      <button 
                        className="mobile-cart-remove"
                        onClick={() => handleRemoveItem(item)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      
      <div className="mobile-cart-summary">
        <div className="mobile-cart-totals">
          <div className="mobile-cart-subtotal">
            <span>Cash Subtotal:</span>
            <span>{formatPrice(subtotal.cash)}</span>
          </div>
          <div className="mobile-cart-subtotal">
            <span>Credit Subtotal:</span>
            <span>{formatPrice(subtotal.credit)}</span>
          </div>
          <div className="mobile-cart-total">
            <span>Total:</span>
            <span>{formatPrice(subtotal.cash + subtotal.credit)}</span>
          </div>
        </div>
        
        <div className="mobile-cart-actions">
          <button 
            className="mobile-cart-checkout"
            onClick={handleCheckout}
            disabled={cartItems.length === 0}
          >
            {t('Checkout')}
          </button>
          <button 
            className="mobile-cart-clear"
            onClick={handleClearCart}
            disabled={cartItems.length === 0}
          >
            {t('Clear Cart')}
          </button>
          <button 
            className="mobile-cart-continue"
            onClick={onClose}
          >
            {t('Continue Shopping')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobileCart;
