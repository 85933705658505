import React from 'react';
import './MobileTileGrid.css';

// Define colors for each game
const gameColors = {
  'Magic: The Gathering': 'var(--wp8-blue)',
  'Pokemon': '#FFB900', // Yellow
  'Yu-Gi-Oh!': 'var(--wp8-purple)',
  'Flesh and Blood': 'var(--wp8-red)',
  'Lorcana': 'var(--wp8-teal)',
  'One Piece': 'var(--wp8-orange)',
  'Star Wars': 'var(--wp8-lime)',
  'Sorcery': 'var(--wp8-magenta)',
  'Digimon': '#00B7C3', // Cyan
  'Weiss Schwarz': '#FF4081', // Pink
  'Final Fantasy': '#673AB7', // Deep Purple
  'Transformers': '#FF5722', // Deep Orange
  'Warhammer 40k': '#795548', // Brown
  'My Little Pony': '#E91E63', // Pink
  'MetaZoo': '#4CAF50', // Green
  'Dragon Ball Super': '#FFA000', // Amber
  'Cardfight Vanguard': '#3F51B5', // Indigo
  'Bakugan': '#9C27B0', // Purple
  'Naruto': '#FF9800', // Orange
  'Dragonball Z': '#F44336', // Red
  // Default color for other games
  'default': 'var(--wp8-gray)'
};

// Function to get a random color for games not in the map
const getRandomColor = (gameName) => {
  // Generate a color based on the game name to ensure consistency
  let hash = 0;
  for (let i = 0; i < gameName.length; i++) {
    hash = gameName.charCodeAt(i) + ((hash << 5) - hash);
  }
  
  // Convert to a hex color
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xFF;
    color += ('00' + value.toString(16)).substr(-2);
  }
  
  return color;
};


const MobileTileGrid = ({ games, onGameSelect }) => {
  // Function to determine tile size
  const getTileSize = (index) => {
    // Make the first 2 games large tiles, others small
    return index < 2 ? 'large' : 'small';
  };

  return (
    <div className="mobile-tile-grid">
      {games.map((game, index) => {
        const tileSize = getTileSize(index);
        const backgroundColor = gameColors[game] || getRandomColor(game);
        return (
          <div 
            key={index}
            className={`mobile-tile ${tileSize}`}
            style={{ backgroundColor }}
            onClick={() => onGameSelect(game)}
          >
            <div className="mobile-tile-content">
              <div className="mobile-tile-name">{game}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MobileTileGrid;
