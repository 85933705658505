import React, { useState, useEffect } from 'react';
import './Notification.css';

const Notification = ({ 
  message, 
  type = 'success', 
  duration = 5000, 
  onClose,
  showCloseButton = true
}) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (duration > 0) {
      const timer = setTimeout(() => {
        handleClose();
      }, duration);
      
      return () => clearTimeout(timer);
    }
  }, [duration]);

  const handleClose = () => {
    setIsVisible(false);
    if (onClose) {
      setTimeout(() => {
        onClose();
      }, 300); // Wait for fade-out animation
    }
  };

  if (!isVisible) return null;

  return (
    <div className={`notification-overlay ${isVisible ? 'visible' : ''}`}>
      <div className={`notification ${type} ${isVisible ? 'visible' : ''}`}>
        <div className="notification-content">
          {type === 'success' && (
            <div className="notification-icon success">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path d="M0 0h24v24H0V0z" fill="none"/>
                <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"/>
              </svg>
            </div>
          )}
          {type === 'error' && (
            <div className="notification-icon error">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path d="M0 0h24v24H0V0z" fill="none"/>
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"/>
              </svg>
            </div>
          )}
          {type === 'info' && (
            <div className="notification-icon info">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path d="M0 0h24v24H0V0z" fill="none"/>
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"/>
              </svg>
            </div>
          )}
          <div className="notification-message">{message}</div>
        </div>
        {showCloseButton && (
          <button className="notification-close" onClick={handleClose}>
            OK
          </button>
        )}
      </div>
    </div>
  );
};

export default Notification;
