import React, { useState } from 'react';
import './SearchBar.css';

const SearchBar = ({ searchParams, setSearchParams, setIsInitialLoad }) => {
  const [searchTerm, setSearchTerm] = useState(searchParams.term || '');
  const [searchAbbr, setSearchAbbr] = useState(searchParams.abbr || '');
  const [searchNumber, setSearchNumber] = useState(searchParams.number || '');

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleAbbrChange = (e) => {
    setSearchAbbr(e.target.value);
  };

  const handleNumberChange = (e) => {
    setSearchNumber(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setIsInitialLoad(false);
    
    // Close the custom buylist section when performing a search
    const altCart = document.querySelector('.alt-cart');
    if (altCart) {
      const customItemsEvent = new CustomEvent('closeCustomItems');
      altCart.dispatchEvent(customItemsEvent);
    }
    
    // Normalize number (remove leading zeros and non-numeric characters)
    const normalizedNumber = searchNumber ? searchNumber.replace(/^0+/, '').split(/[^0-9]/)[0] : '';
    
    setSearchParams(prev => ({
      ...prev,
      term: searchTerm,
      abbr: searchAbbr.toLowerCase(),
      number: normalizedNumber,
      page: 1
    }));
  };

  return (
    <div className="alt-search-section">
      <form onSubmit={handleSearchSubmit} className="alt-search-form">
        <div className="alt-search-inputs">
          <input
            type="text"
            placeholder="Search by Name"
            className="alt-search-input"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <input
            type="text"
            placeholder="Search by Abbreviation"
            className="alt-search-input"
            value={searchAbbr}
            onChange={handleAbbrChange}
          />
          <input
            type="text"
            placeholder="Search by Number"
            className="alt-search-input"
            value={searchNumber}
            onChange={handleNumberChange}
          />
          <button type="submit" className="alt-search-button">Search</button>
        </div>
      </form>
    </div>
  );
};

export default SearchBar;
