import React, { useState, useEffect } from 'react';
import './LoadBuylistPopup.css';
import { BACKEND_URL } from '../config';

const LoadBuylistPopup = ({ onClose, onLoadBuylist, username, userSettings }) => {
  // Define currency symbols
  const currencySymbols = {
    USD: '$', EUR: '€', GBP: '£', JPY: '¥', CAD: 'C$', AUD: 'A$', CHF: 'CHF', CNY: '¥', SEK: 'kr', NZD: 'NZ$',
    MXN: 'Mex$', SGD: 'S$', HKD: 'HK$', NOK: 'kr', KRW: '₩', TRY: '₺', RUB: '₽', INR: '₹', BRL: 'R$', ZAR: 'R'
  };

  // Get the currency symbol based on user settings
  const currencySymbol = currencySymbols[userSettings?.currency || 'USD'] || '$';
  const [email, setEmail] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [savedBuylists, setSavedBuylists] = useState([]);
  const [selectedBuylist, setSelectedBuylist] = useState(null);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [selectedItems, setSelectedItems] = useState({});
  const [selectionMode, setSelectionMode] = useState('all'); // 'all' or 'manual'

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    
    // Validate email
    if (!validateEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }
    
    setIsSearching(true);
    setError('');
    setSearchPerformed(true);
    
    try {
      // Fetch saved buylists for this email
      const response = await fetch(`${BACKEND_URL}/saved-buylists/${email}`);
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to fetch saved buylists');
      }
      
      const data = await response.json();
      console.log('Saved buylists:', data);
      
      if (data.length === 0) {
        setError('No saved buylists found for this email address');
      } else {
        setSavedBuylists(data);
      }
      
    } catch (err) {
      console.error('Error fetching saved buylists:', err);
      setError(err.message || 'Failed to fetch saved buylists. Please try again.');
    } finally {
      setIsSearching(false);
    }
  };

  const handleSelectBuylist = async (buylistId) => {
    setIsLoading(true);
    setError('');
    setSelectionMode('all'); // Reset to 'all' mode when selecting a new buylist
    
    try {
      // Fetch the specific buylist
      const response = await fetch(`${BACKEND_URL}/saved-buylist/${buylistId}`);
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to fetch buylist details');
      }
      
      const data = await response.json();
      console.log('Selected buylist details:', data);
      
      setSelectedBuylist(data);
      
      // Initialize all items as selected
      const initialSelectedItems = {};
      data.cart_items.forEach((item, index) => {
        initialSelectedItems[index] = true;
      });
      setSelectedItems(initialSelectedItems);
      
    } catch (err) {
      console.error('Error fetching buylist details:', err);
      setError(err.message || 'Failed to fetch buylist details. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoadBuylist = () => {
    if (selectedBuylist) {
      if (selectionMode === 'all') {
        // Load all items
        onLoadBuylist(selectedBuylist);
      } else {
        // Load only selected items
        const filteredBuylist = {
          ...selectedBuylist,
          cart_items: selectedBuylist.cart_items.filter((_, index) => selectedItems[index])
        };
        onLoadBuylist(filteredBuylist);
      }
    }
  };

  const handleItemCheckboxChange = (index) => {
    setSelectedItems(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const handleSelectionModeChange = (mode) => {
    setSelectionMode(mode);
    
    // If switching to 'all' mode, select all items
    if (mode === 'all' && selectedBuylist) {
      const allSelected = {};
      selectedBuylist.cart_items.forEach((_, index) => {
        allSelected[index] = true;
      });
      setSelectedItems(allSelected);
    }
  };

  const toggleSelectAll = () => {
    if (selectedBuylist) {
      const allSelected = {};
      const allAreSelected = selectedBuylist.cart_items.every((_, index) => selectedItems[index]);
      
      selectedBuylist.cart_items.forEach((_, index) => {
        allSelected[index] = !allAreSelected;
      });
      
      setSelectedItems(allSelected);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  };

  const getSelectedItemsCount = () => {
    return Object.values(selectedItems).filter(Boolean).length;
  };

  const getSelectedItemsTotal = () => {
    if (!selectedBuylist) return 0;
    
    return selectedBuylist.cart_items.reduce((sum, item, index) => {
      if (selectedItems[index]) {
        const price = item.isCash ? parseFloat(item.cashPrice) : parseFloat(item.creditPrice);
        return sum + (price * item.quantity);
      }
      return sum;
    }, 0);
  };

  return (
    <div className="popup-overlay">
      <div className="load-buylist-popup">
        <button className="close-button" onClick={onClose}>×</button>
        <h2>Load Saved Buylist</h2>
        
        {!selectedBuylist ? (
          <>
            <p>Enter your email address to find your saved buylists. Buylists are saved for 48 hours with the exact same prices as when they were saved.</p>
            
            {error && <div className="error-message">{error}</div>}
            
            <form onSubmit={handleSearch}>
              <div className="form-group">
                <label htmlFor="email">Email Address:</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Enter your email"
                  required
                />
              </div>
              
              <div className="form-actions">
                <button 
                  type="button" 
                  className="cancel-button"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button 
                  type="submit" 
                  className="search-button"
                  disabled={isSearching}
                >
                  {isSearching ? 'Searching...' : 'Search Buylists'}
                </button>
              </div>
            </form>
            
            {searchPerformed && savedBuylists.length > 0 && (
              <div className="buylist-results">
                <h3>Saved Buylists</h3>
                <ul className="buylist-list">
                  {savedBuylists.map(buylist => (
                    <li key={buylist._id} className="buylist-item">
                      <div className="buylist-info">
                        <span className="buylist-date">{formatDate(buylist.created_at)}</span>
                        <span className="buylist-value">{currencySymbol}{buylist.original_total.toFixed(2)}</span>
                      </div>
                      <button 
                        className="select-button"
                        onClick={() => handleSelectBuylist(buylist._id)}
                      >
                        Select
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </>
        ) : (
          <div className="buylist-details">
            {isLoading ? (
              <div className="loading">Loading buylist details...</div>
            ) : (
              <>
                <h3>Buylist Details</h3>
                <div className="buylist-summary">
                  <p><strong>Created:</strong> {formatDate(selectedBuylist.created_at)}</p>
                  <p><strong>Items:</strong> {selectedBuylist.cart_items.length}</p>
                  <p><strong>Original Total:</strong> {currencySymbol}{selectedBuylist.original_total.toFixed(2)}</p>
                </div>
                
                <div className="selection-options">
                  <div className="selection-mode">
                    <label className={`selection-option ${selectionMode === 'all' ? 'active' : ''}`}>
                      <input
                        type="radio"
                        name="selectionMode"
                        value="all"
                        checked={selectionMode === 'all'}
                        onChange={() => handleSelectionModeChange('all')}
                      />
                      <span>Load All Items</span>
                    </label>
                    <label className={`selection-option ${selectionMode === 'manual' ? 'active' : ''}`}>
                      <input
                        type="radio"
                        name="selectionMode"
                        value="manual"
                        checked={selectionMode === 'manual'}
                        onChange={() => handleSelectionModeChange('manual')}
                      />
                      <span>Select Items Manually</span>
                    </label>
                  </div>
                  
                  {selectionMode === 'manual' && (
                    <div className="selection-controls">
                      <button 
                        type="button" 
                        className="select-all-button"
                        onClick={toggleSelectAll}
                      >
                        {selectedBuylist.cart_items.every((_, index) => selectedItems[index]) 
                          ? 'Deselect All' 
                          : 'Select All'}
                      </button>
                      <div className="selection-summary">
                        <span>{getSelectedItemsCount()} of {selectedBuylist.cart_items.length} items selected</span>
                        <span>{currencySymbol}{getSelectedItemsTotal().toFixed(2)}</span>
                      </div>
                    </div>
                  )}
                </div>
                
                <div className="buylist-items">
                  <h4>Items in this Buylist</h4>
                  <ul className="item-list">
                    {selectedBuylist.cart_items.map((item, index) => (
                      <li key={index} className={`item ${selectionMode === 'manual' && selectedItems[index] ? 'selected' : ''}`}>
                        {selectionMode === 'manual' && (
                          <label className="item-checkbox">
                            <input
                              type="checkbox"
                              checked={selectedItems[index] || false}
                              onChange={() => handleItemCheckboxChange(index)}
                            />
                            <span className="checkmark"></span>
                          </label>
                        )}
                        <div className="item-content">
                          <span className="item-name">{item.name}</span>
                          <div className="item-details">
                            <span className="item-condition">{item.condition}</span>
                            <span className="item-printing">{item.printing}</span>
                            <span className="item-quantity">Qty: {item.quantity}</span>
                            <span className="item-price">{currencySymbol}{(item.isCash ? item.cashPrice : item.creditPrice).toFixed(2)} {item.isCash ? 'Cash' : 'Credit'}</span>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                
                <div className="form-actions">
                  <button 
                    type="button" 
                    className="back-button"
                    onClick={() => setSelectedBuylist(null)}
                  >
                    Back to List
                  </button>
                  <button 
                    type="button" 
                    className="load-button"
                    onClick={handleLoadBuylist}
                  >
                    {selectionMode === 'all' 
                      ? 'Load All Items' 
                      : `Load ${getSelectedItemsCount()} Selected Items`}
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default LoadBuylistPopup;
