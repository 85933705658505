import React, { useState, useEffect } from 'react';
import './SkuDetailsPopup.css';

const SkuDetailsPopup = ({ card, sku, onClose, userSettings }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [skuDetails, setSkuDetails] = useState(null);
  const [error, setError] = useState(null);

  // Currency symbols for formatting prices
  const currencySymbols = {
    USD: '$', EUR: '€', GBP: '£', JPY: '¥', CAD: 'C$', AUD: 'A$', CHF: 'CHF', CNY: '¥', SEK: 'kr', NZD: 'NZ$',
    MXN: 'Mex$', SGD: 'S$', HKD: 'HK$', NOK: 'kr', KRW: '₩', TRY: '₺', RUB: '₽', INR: '₹', BRL: 'R$', ZAR: 'R'
  };

  const currencySymbol = currencySymbols[userSettings?.currency] || '$';

  useEffect(() => {
    if (card && sku) {
      setIsLoading(false);
      setSkuDetails({
        name: card.name,
        expansion: card.expansionName,
        condition: sku.condAbbr,
        printing: sku.printingName,
        language: sku.langAbbr,
        marketPrice: sku.marketPrice,
        lowPrice: sku.lowPrice,
        lowestListingPrice: sku.lowestListingPrice,
        lowestShipping: sku.lowestShipping
      });
    } else {
      setError('No SKU details available');
      setIsLoading(false);
    }
  }, [card, sku]);

  const formatPrice = (price) => {
    if (price === null || price === undefined) return 'N/A';
    return parseFloat(price).toFixed(2);
  };

  // Function to calculate stepped price based on condition
  const calculateSteppedPrice = (condition, nmPrice) => {
    if (nmPrice === null || nmPrice === undefined) return null;
    
    switch (condition) {
      case 'NM':
        return nmPrice;
      case 'LP':
        return nmPrice * 0.8;
      case 'MP':
        return nmPrice * 0.64;
      case 'HP':
        return nmPrice * 0.512;
      case 'DM':
        return nmPrice * 0.4096;
      default:
        return nmPrice;
    }
  };

  // Function to get price based on preference
  const getPriceByPreference = (preference) => {
    switch (preference) {
      case 'marketPrice':
        if (skuDetails && skuDetails.marketPrice && skuDetails.marketPrice > 0) {
          return { price: parseFloat(skuDetails.marketPrice), source: 'Market Price' };
        }
        break;
      case 'lowPrice':
        if (skuDetails && skuDetails.lowPrice && skuDetails.lowPrice > 0) {
          return { price: parseFloat(skuDetails.lowPrice), source: 'Low Price' };
        }
        break;
      case 'midPrice':
        if (skuDetails && skuDetails.midPrice && skuDetails.midPrice > 0) {
          return { price: parseFloat(skuDetails.midPrice), source: 'Mid Price' };
        }
        break;
      case 'highPrice':
        if (skuDetails && skuDetails.highPrice && skuDetails.highPrice > 0) {
          return { price: parseFloat(skuDetails.highPrice), source: 'High Price' };
        }
        break;
      case 'lowestListedPrice':
      case 'lowestListingPrice':
        if (skuDetails && skuDetails.lowestListingPrice && skuDetails.lowestListingPrice > 0) {
          return { price: parseFloat(skuDetails.lowestListingPrice), source: 'Lowest Listing Price' };
        }
        break;
    }
    return null;
  };

  // Function to choose which price to use as the base price based on user settings
  const chooseBasePrice = (tcgPlayerPrice, steppedPrice) => {
    // Default to stepped price if no specific setting
    if (!userSettings) {
      return { price: steppedPrice, source: 'Stepped Price' };
    }

    // Check if user has a price_preference_order
    if (userSettings.price_preference_order && Array.isArray(userSettings.price_preference_order) && userSettings.price_preference_order.length > 0) {
      // Try each preference in order
      for (const preference of userSettings.price_preference_order) {
        const priceResult = getPriceByPreference(preference);
        if (priceResult) {
          return priceResult;
        }
      }
      
      // If no price found in preference order, fall back to stepped price
      return { 
        price: steppedPrice, 
        source: 'Stepped Price (No prices available in preference order)' 
      };
    } 
    // If no price_preference_order, check if price_point exists
    else if (userSettings.price_point) {
      // Map price_point to the corresponding preference key
      let primaryPreference;
      switch (userSettings.price_point) {
        case 'Market Price':
          primaryPreference = 'marketPrice';
          break;
        case 'Low Price':
          primaryPreference = 'lowPrice';
          break;
        case 'Lowest Listing Price':
          primaryPreference = 'lowestListingPrice';
          break;
        default:
          primaryPreference = 'lowPrice'; // Default to lowPrice if unknown
      }
      
      // Try to get price based on primary preference
      const primaryPrice = getPriceByPreference(primaryPreference);
      
      if (primaryPrice) {
        // Primary price is available
        return primaryPrice;
      } else {
        // Primary price not available, fall back to stepped price
        return { 
          price: steppedPrice, 
          source: `Stepped Price (${userSettings.price_point} not available)` 
        };
      }
    } else {
      // No price_preference_order or price_point, default to stepped price
      return { price: steppedPrice, source: 'Stepped Price' };
    }
  };

  // Function to calculate price based on percentages
  const calculatePriceFromBase = (basePrice) => {
    if (!userSettings || !basePrice) return { cashPrice: 0, creditPrice: 0 };
    
    const cashPercentage = parseFloat(userSettings.defaultCashPercentage || 50) / 100;
    const creditPercentage = parseFloat(userSettings.defaultCreditPercentage || 60) / 100;
    
    return {
      cashPrice: basePrice * cashPercentage,
      creditPrice: basePrice * creditPercentage,
      cashPercentage,
      creditPercentage,
      basePrice
    };
  };

  if (isLoading) {
    return (
      <div className="sku-details-popup">
        <div className="sku-details-content">
          <h2>Loading SKU Details...</h2>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="sku-details-popup">
        <div className="sku-details-content">
          <h2>Error</h2>
          <p>{error}</p>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    );
  }

  if (!skuDetails) {
    return (
      <div className="sku-details-popup">
        <div className="sku-details-content">
          <h2>No SKU Details Available</h2>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    );
  }

  const { condition, printing, language } = skuDetails;
  const nmPrice = skuDetails.lowPrice;
  const steppedPrice = calculateSteppedPrice(condition, nmPrice);
  const { price: basePrice, source } = chooseBasePrice(skuDetails.lowPrice, steppedPrice);
  const priceInfo = calculatePriceFromBase(basePrice);
  const { cashPrice, creditPrice, cashPercentage, creditPercentage } = priceInfo;

  return (
    <div className="sku-details-popup">
      <div className="sku-details-content">
        <button className="close-button" onClick={onClose}>×</button>
        <h2>SKU Details</h2>
        <div className="sku-details-grid">
          <div className="sku-details-section">
            <h3>Card Information</h3>
            <table>
              <tbody>
                <tr>
                  <td>Name:</td>
                  <td>{skuDetails.name}</td>
                </tr>
                <tr>
                  <td>Expansion:</td>
                  <td>{skuDetails.expansion}</td>
                </tr>
                <tr>
                  <td>Condition:</td>
                  <td>{condition}</td>
                </tr>
                <tr>
                  <td>Printing:</td>
                  <td>{printing}</td>
                </tr>
                <tr>
                  <td>Language:</td>
                  <td>{language}</td>
                </tr>
              </tbody>
            </table>
          </div>
          
          <div className="sku-details-section">
            <h3>Price Information</h3>
            <table>
              <tbody>
                <tr>
                  <td>Market Price:</td>
                  <td>{`${currencySymbol}${formatPrice(skuDetails.marketPrice)}`}</td>
                </tr>
                <tr>
                  <td>Low Price:</td>
                  <td>{`${currencySymbol}${formatPrice(skuDetails.lowPrice)}`}</td>
                </tr>
                <tr>
                  <td>Lowest Listing Price:</td>
                  <td>{`${currencySymbol}${formatPrice(skuDetails.lowestListingPrice)}`}</td>
                </tr>
                <tr>
                  <td>Stepped Price:</td>
                  <td>{`${currencySymbol}${formatPrice(steppedPrice)}`}</td>
                </tr>
                <tr>
                  <td>Base Price:</td>
                  <td>{`${currencySymbol}${formatPrice(basePrice)} (${source})`}</td>
                </tr>
                <tr>
                  <td>Cash Price:</td>
                  <td>{`${currencySymbol}${formatPrice(cashPrice)} (${(cashPercentage * 100).toFixed(0)}%)`}</td>
                </tr>
                <tr>
                  <td>Credit Price:</td>
                  <td>{`${currencySymbol}${formatPrice(creditPrice)} (${(creditPercentage * 100).toFixed(0)}%)`}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="sku-details-footer">
          <p className="price-point-info">
            Price Point Setting: {userSettings?.price_point || 'Not set'}
          </p>
          {userSettings?.price_preference_order && userSettings.price_preference_order.length > 0 && (
            <p className="price-preference-order">
              Price Preference Order: {userSettings.price_preference_order.join(' → ')}
            </p>
          )}
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default SkuDetailsPopup;
