import React from 'react';
import './MobilePagination.css';

const MobilePagination = ({ currentPage, totalPages, onPageChange }) => {
  // Convert to number to ensure proper comparisons
  currentPage = parseInt(currentPage);
  
  // Generate page numbers to display
  const getPageNumbers = () => {
    const pageNumbers = [];
    
    // Always show first page
    pageNumbers.push(1);
    
    // Show current page and surrounding pages
    for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
      if (pageNumbers.indexOf(i) === -1) {
        pageNumbers.push(i);
      }
    }
    
    // Always show last page if there's more than one page
    if (totalPages > 1) {
      pageNumbers.push(totalPages);
    }
    
    // Add ellipsis where needed
    const result = [];
    let prev = 0;
    
    for (const num of pageNumbers) {
      if (num - prev > 1) {
        result.push('...');
      }
      result.push(num);
      prev = num;
    }
    
    return result;
  };

  return (
    <div className="mobile-pagination">
      <button 
        className="mobile-pagination-button mobile-pagination-prev"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage <= 1}
        aria-label="Previous page"
      >
        ←
      </button>
      
      <div className="mobile-pagination-pages">
        {getPageNumbers().map((page, index) => (
          page === '...' ? (
            <span key={`ellipsis-${index}`} className="mobile-pagination-ellipsis">...</span>
          ) : (
            <button
              key={page}
              className={`mobile-pagination-page ${currentPage === page ? 'active' : ''}`}
              onClick={() => onPageChange(page)}
              disabled={currentPage === page}
            >
              {page}
            </button>
          )
        ))}
      </div>
      
      <button 
        className="mobile-pagination-button mobile-pagination-next"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage >= totalPages}
        aria-label="Next page"
      >
        →
      </button>
    </div>
  );
};

export default MobilePagination;
