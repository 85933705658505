import React from 'react';
import './GameGrid.css';

const GameGrid = ({ enabledGames, onGameSelect }) => {
  // Function to get the image path for a game
  const getGameImage = (gameName) => {
    // Direct mapping of game names to image files
    const gameImageMap = {
      'flesh and blood': '/Fleshandblood.webp',
      'fleshandblood': '/Fleshandblood.webp',
      'lorcana': '/Lorcana.webp',
      'one piece': '/Onepiece.webp',
      'onepiece': '/Onepiece.webp',
      'sorcery': '/Sorcery.webp',
      'pokemon': '/Pokemon.webp',
      'yugioh': '/Yogioh.webp',
      'yu-gi-oh': '/Yogioh.webp',
      'yu gi oh': '/Yogioh.webp',
      'star wars': '/Starwars.webp',
      'starwars': '/Starwars.webp',
      'magic the gathering': '/Magic.webp',
      'magic': '/Magic.webp',
      'mtg': '/Magic.webp',
      'union arena': '/unionarena.jpg',
      'unionarena': '/unionarena.jpg',
      'dragon ball': '/Cover_dragonball.webp',
      'dragonball': '/Cover_dragonball.webp',
      'digimon': '/digimon.webp'
    };
    
    // Normalize the game name (remove spaces, special characters, and convert to lowercase)
    const normalizedName = gameName
      .toLowerCase()
      .replace(/[^\w\s]/gi, '') // Remove special characters
      .replace(/\s+/g, '');      // Remove spaces
    
    // Try to find a direct match first
    if (gameImageMap[normalizedName]) {
      return gameImageMap[normalizedName];
    }
    
    // If no direct match, try to find a partial match
    for (const [key, value] of Object.entries(gameImageMap)) {
      if (normalizedName.includes(key.replace(/\s+/g, '')) || 
          key.replace(/\s+/g, '').includes(normalizedName)) {
        return value;
      }
    }
    
    // If no match found, check for specific cases
    if (normalizedName.includes('yugi') || normalizedName.includes('ygo')) {
      return '/Yogioh.webp';
    }
    
    if (normalizedName.includes('magic') || normalizedName.includes('mtg') || normalizedName.includes('gathering')) {
      return '/Magic.webp';
    }
    
    return null;
  };
  
  // Function to format game name for display
  const formatGameName = (gameName) => {
    // Special case formatting for specific games
    const specialFormatting = {
      'mtg': 'Magic: The Gathering',
      'magic': 'Magic: The Gathering',
      'magic the gathering': 'Magic: The Gathering',
      'yugioh': 'Yu-Gi-Oh!',
      'yu-gi-oh': 'Yu-Gi-Oh!',
      'yu gi oh': 'Yu-Gi-Oh!',
      'onepiece': 'One Piece',
      'one piece': 'One Piece',
      'fleshandblood': 'Flesh and Blood',
      'flesh and blood': 'Flesh and Blood',
      'starwars': 'Star Wars',
      'star wars': 'Star Wars',
      'dragonball': 'Dragon Ball',
      'dragon ball': 'Dragon Ball',
      'unionarena': 'Union Arena'
    };
    
    const lowerName = gameName.toLowerCase();
    
    if (specialFormatting[lowerName]) {
      return specialFormatting[lowerName];
    }
    
    // Default formatting: capitalize each word
    return gameName
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };
  
  // Function to generate a placeholder color based on the game name (fallback)
  const getPlaceholderColor = (gameName) => {
    // Simple hash function to generate a color based on the game name
    const hash = gameName.split('').reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);
    
    // Generate a hue between 0 and 360
    const hue = Math.abs(hash % 360);
    
    // Return an HSL color with fixed saturation and lightness
    return `hsl(${hue}, 70%, 65%)`;
  };

  return (
    <div className="alt-game-grid">
      <h2>Which cards do you want to sell?</h2>
      <div className="alt-grid-container">
        {enabledGames.map((game, index) => {
          const imagePath = getGameImage(game);
          const hasImage = imagePath !== null;
          const displayName = formatGameName(game);
          
          return (
            <div 
              key={index} 
              className="alt-game-card"
              onClick={() => onGameSelect(game)}
            >
              <div className="alt-game-image-container">
                <div className="alt-game-image">
                  {hasImage ? (
                    <img 
                      src={imagePath} 
                      alt={`${displayName} logo`}
                      className="alt-game-image-inner"
                    />
                  ) : (
                    <div 
                      className="alt-game-image-inner"
                      style={{ 
                        backgroundColor: getPlaceholderColor(game),
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '8px'
                      }}
                    >
                      <span style={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }}>
                        {displayName.charAt(0)}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="alt-game-title">
                {displayName}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GameGrid;
