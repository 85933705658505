import React, { useState } from 'react';
import './BulkSearchResults.css';
import { useTranslation } from 'react-i18next';

const BulkSearchResults = ({ 
  searchResults, 
  rows, 
  onSelectResult, 
  onAddToCart,
  allowedConditions,
  calculatePrice,
  userSettings,
  exchangeRates
}) => {
  const { t } = useTranslation();
  const [selectedConditions, setSelectedConditions] = useState({});
  const [selectedPrintings, setSelectedPrintings] = useState({});
  const [expandedRows, setExpandedRows] = useState({});
  const [addedToCartRows, setAddedToCartRows] = useState({});

  const handleConditionChange = (cardId, condition) => {
    setSelectedConditions(prev => ({ ...prev, [cardId]: condition }));
  };

  const handlePrintingChange = (cardId, printing) => {
    setSelectedPrintings(prev => ({ ...prev, [cardId]: printing }));
  };

  const toggleRowExpansion = (rowId) => {
    setExpandedRows(prev => ({
      ...prev,
      [rowId]: !prev[rowId]
    }));
  };

  const handleAddToCartWithCollapse = (card, condition, printing, isCash) => {
    // Call the original onAddToCart function
    onAddToCart(card, condition, printing, isCash);
    
    // Find the row ID for this card
    const rowId = Object.keys(searchResults).find(id => {
      const result = searchResults[id];
      return result.selectedResult?._id === card._id || 
             (result.results && result.results.length === 1 && result.results[0]._id === card._id);
    });
    
    if (rowId) {
      // Mark this row as added to cart
      setAddedToCartRows(prev => ({
        ...prev,
        [rowId]: true
      }));
      
      // Collapse the row
      setExpandedRows(prev => ({
        ...prev,
        [rowId]: false
      }));
    }
  };

  const currencySymbols = {
    USD: '$', EUR: '€', GBP: '£', JPY: '¥', CAD: 'C$', AUD: 'A$', CHF: 'CHF', CNY: '¥', SEK: 'kr', NZD: 'NZ$',
    MXN: 'Mex$', SGD: 'S$', HKD: 'HK$', NOK: 'kr', KRW: '₩', TRY: '₺', RUB: '₽', INR: '₹', BRL: 'R$', ZAR: 'R'
  };

  const currencySymbol = currencySymbols[userSettings?.currency || 'USD'] || '$';

  // Helper function to get price for a card
  const getCardPrice = (card, condition, printing, isCash) => {
    if (!card || !card.skus) return { price: 0, available: false };
    
    // Find the SKU that matches the condition and printing
    const matchingSku = card.skus.find(sku => 
      sku.condAbbr === condition && 
      sku.printingName === printing
    );
    
    if (!matchingSku) return { price: 0, available: false };
    
    // Get the base price from the SKU
    const basePrice = matchingSku.lowPrice || 0;
    
    // Calculate cash and credit prices based on user settings
    const cashPercentage = parseFloat(userSettings?.defaultCashPercentage || 50);
    const creditPercentage = parseFloat(userSettings?.defaultCreditPercentage || 60);
    const floorPrice = parseFloat(userSettings?.floorPrice || 0);
    
    const cashPrice = parseFloat((basePrice * (cashPercentage / 100)).toFixed(2));
    const creditPrice = parseFloat((basePrice * (creditPercentage / 100)).toFixed(2));
    
    const price = isCash ? cashPrice : creditPrice;
    const available = price >= floorPrice;
    
    return { price, available };
  };

  if (Object.keys(searchResults).length === 0) {
    return null;
  }

  return (
    <div className="bulk-search-results">
      <h3 className="results-title">{t('Search Results')}</h3>
      
      {rows.map(row => {
        const rowResult = searchResults[row.id];
        if (!rowResult) return null;
        
        const isExpanded = expandedRows[row.id] !== false; // Default to expanded
        
        return (
          <div key={row.id} className="search-result-group">
            <div 
              className={`search-query ${addedToCartRows[row.id] ? 'added-to-cart' : ''}`} 
              onClick={() => toggleRowExpansion(row.id)}
            >
              {addedToCartRows[row.id] && (
                <span className="added-to-cart-badge">{t('Added')}</span>
              )}
              <div className="search-query-text">
                <strong>{t('Searched for')}:</strong> {row.name} {row.abbreviation} {row.number}
              </div>
              <div>
                <span className={`status-badge status-${rowResult.status}`}>
                  {rowResult.status === 'pending' && t('Pending')}
                  {rowResult.status === 'searching' && t('Searching...')}
                  {rowResult.status === 'complete' && 
                    `${rowResult.results.length} ${rowResult.results.length === 1 ? t('result') : t('results')}`
                  }
                  {rowResult.status === 'error' && t('Error')}
                </span>
                <span className={`collapse-icon ${isExpanded ? 'expanded' : ''}`}>
                  {isExpanded ? '▲' : '▼'}
                </span>
              </div>
            </div>
            
            <div className={`search-result-content ${!isExpanded ? 'collapsed' : ''}`}>
              {rowResult.status === 'error' && (
                <div className="error-message">
                  {rowResult.message || t('An error occurred while searching')}
                </div>
              )}
              
              {rowResult.status === 'complete' && (
                <>
                  {rowResult.results.length === 0 ? (
                    <div className="no-results-message">
                      {t('No cards found matching these criteria')}
                    </div>
                  ) : rowResult.results.length > 1 ? (
                    <div className="multiple-results">
                      <div className="result-selection">
                        <label htmlFor={`select-result-${row.id}`}>{t('Select card')}:</label>
                        <select 
                          id={`select-result-${row.id}`}
                          value={rowResult.selectedResult?._id || ''}
                          onChange={(e) => onSelectResult(row.id, e.target.value)}
                          className="result-dropdown"
                        >
                          <option value="">{t('-- Select a card --')}</option>
                          {rowResult.results.map(card => (
                            <option key={card._id} value={card._id}>
                              {card.name} ({card.expansionName || t('Unknown Set')}) 
                              {card.number ? `#${card.number}` : ''}
                            </option>
                          ))}
                        </select>
                      </div>
                      
                      {rowResult.selectedResult && (
                        <div className="selected-card">
                          <div className="card-details">
                            <div className="card-image-container">
                              <img 
                                src={rowResult.selectedResult.image || process.env.PUBLIC_URL + '/flags/No-Image-Placeholder.svg.png'} 
                                alt={rowResult.selectedResult.name} 
                                className="card-image"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = process.env.PUBLIC_URL + '/flags/No-Image-Placeholder.svg.png';
                                }}
                              />
                            </div>
                            <div className="card-info">
                              <h4 className="card-name">{rowResult.selectedResult.name}</h4>
                              <p className="card-set">{rowResult.selectedResult.expansionName || t('Unknown Set')}</p>
                              {rowResult.selectedResult.number && (
                                <p className="card-number">#{rowResult.selectedResult.number}</p>
                              )}
                              {rowResult.selectedResult.rarity && (
                                <p className="card-rarity">{rowResult.selectedResult.rarity}</p>
                              )}
                              
                              <div className="card-options">
                                <div className="option-group">
                                  <label htmlFor={`condition-${row.id}`}>{t('Condition')}:</label>
                                  <select 
                                    id={`condition-${row.id}`}
                                    value={selectedConditions[rowResult.selectedResult._id] || 
                                      (rowResult.selectedResult.skus && rowResult.selectedResult.skus[0]?.condAbbr) || 'NM'}
                                    onChange={(e) => handleConditionChange(rowResult.selectedResult._id, e.target.value)}
                                    className="condition-select"
                                  >
                                    {allowedConditions.map(condition => (
                                      <option key={condition} value={condition}>{condition}</option>
                                    ))}
                                  </select>
                                </div>
                                
                                <div className="option-group">
                                  <label htmlFor={`printing-${row.id}`}>{t('Printing')}:</label>
                                  <select 
                                    id={`printing-${row.id}`}
                                    value={selectedPrintings[rowResult.selectedResult._id] || 
                                      (rowResult.selectedResult.skus && rowResult.selectedResult.skus[0]?.printingName) || 'Normal'}
                                    onChange={(e) => handlePrintingChange(rowResult.selectedResult._id, e.target.value)}
                                    className="printing-select"
                                  >
                                    {[...new Set(rowResult.selectedResult.skus?.map(sku => sku.printingName) || ['Normal'])].map(printing => (
                                      <option key={printing} value={printing}>{printing}</option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              
                              <div className="card-actions">
                                {(() => {
                                  const condition = selectedConditions[rowResult.selectedResult._id] || 
                                    (rowResult.selectedResult.skus && rowResult.selectedResult.skus[0]?.condAbbr) || 'NM';
                                  const printing = selectedPrintings[rowResult.selectedResult._id] || 
                                    (rowResult.selectedResult.skus && rowResult.selectedResult.skus[0]?.printingName) || 'Normal';
                                  
                                  const cashPriceInfo = getCardPrice(rowResult.selectedResult, condition, printing, true);
                                  const creditPriceInfo = getCardPrice(rowResult.selectedResult, condition, printing, false);
                                  
                                  return (
                                    <>
                                      {creditPriceInfo.available && (
                                        <button 
                                          className="add-to-cart-button credit"
                                          onClick={() => handleAddToCartWithCollapse(rowResult.selectedResult, condition, printing, false)}
                                        >
                                          {t('Credit')} {currencySymbol}{creditPriceInfo.price.toFixed(2)}
                                        </button>
                                      )}
                                      
                                      {cashPriceInfo.available ? (
                                        <button 
                                          className="add-to-cart-button cash"
                                          onClick={() => handleAddToCartWithCollapse(rowResult.selectedResult, condition, printing, true)}
                                        >
                                          {t('Cash')} {currencySymbol}{cashPriceInfo.price.toFixed(2)}
                                        </button>
                                      ) : (
                                        <button className="add-to-cart-button not-buying" disabled>
                                          {t('Not Buying Cash')}
                                        </button>
                                      )}
                                    </>
                                  );
                                })()}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    // Single result
                    <div className="single-result">
                      <div className="card-details">
                        <div className="card-image-container">
                          <img 
                            src={rowResult.results[0].image || process.env.PUBLIC_URL + '/flags/No-Image-Placeholder.svg.png'} 
                            alt={rowResult.results[0].name} 
                            className="card-image"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = process.env.PUBLIC_URL + '/flags/No-Image-Placeholder.svg.png';
                            }}
                          />
                        </div>
                        <div className="card-info">
                          <h4 className="card-name">{rowResult.results[0].name}</h4>
                          <p className="card-set">{rowResult.results[0].expansionName || t('Unknown Set')}</p>
                          {rowResult.results[0].number && (
                            <p className="card-number">#{rowResult.results[0].number}</p>
                          )}
                          {rowResult.results[0].rarity && (
                            <p className="card-rarity">{rowResult.results[0].rarity}</p>
                          )}
                          
                          <div className="card-options">
                            <div className="option-group">
                              <label htmlFor={`condition-${row.id}`}>{t('Condition')}:</label>
                              <select 
                                id={`condition-${row.id}`}
                                value={selectedConditions[rowResult.results[0]._id] || 
                                  (rowResult.results[0].skus && rowResult.results[0].skus[0]?.condAbbr) || 'NM'}
                                onChange={(e) => handleConditionChange(rowResult.results[0]._id, e.target.value)}
                                className="condition-select"
                              >
                                {allowedConditions.map(condition => (
                                  <option key={condition} value={condition}>{condition}</option>
                                ))}
                              </select>
                            </div>
                            
                            <div className="option-group">
                              <label htmlFor={`printing-${row.id}`}>{t('Printing')}:</label>
                              <select 
                                id={`printing-${row.id}`}
                                value={selectedPrintings[rowResult.results[0]._id] || 
                                  (rowResult.results[0].skus && rowResult.results[0].skus[0]?.printingName) || 'Normal'}
                                onChange={(e) => handlePrintingChange(rowResult.results[0]._id, e.target.value)}
                                className="printing-select"
                              >
                                {[...new Set(rowResult.results[0].skus?.map(sku => sku.printingName) || ['Normal'])].map(printing => (
                                  <option key={printing} value={printing}>{printing}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                          
                          <div className="card-actions">
                            {(() => {
                              const condition = selectedConditions[rowResult.results[0]._id] || 
                                (rowResult.results[0].skus && rowResult.results[0].skus[0]?.condAbbr) || 'NM';
                              const printing = selectedPrintings[rowResult.results[0]._id] || 
                                (rowResult.results[0].skus && rowResult.results[0].skus[0]?.printingName) || 'Normal';
                              
                              const cashPriceInfo = getCardPrice(rowResult.results[0], condition, printing, true);
                              const creditPriceInfo = getCardPrice(rowResult.results[0], condition, printing, false);
                              
                              return (
                                <>
                                  {creditPriceInfo.available && (
                                    <button 
                                      className="add-to-cart-button credit"
                                      onClick={() => handleAddToCartWithCollapse(rowResult.results[0], condition, printing, false)}
                                    >
                                      {t('Credit')} {currencySymbol}{creditPriceInfo.price.toFixed(2)}
                                    </button>
                                  )}
                                  
                                  {cashPriceInfo.available ? (
                                    <button 
                                      className="add-to-cart-button cash"
                                      onClick={() => handleAddToCartWithCollapse(rowResult.results[0], condition, printing, true)}
                                    >
                                      {t('Cash')} {currencySymbol}{cashPriceInfo.price.toFixed(2)}
                                    </button>
                                  ) : (
                                    <button className="add-to-cart-button not-buying" disabled>
                                      {t('Not Buying Cash')}
                                    </button>
                                  )}
                                </>
                              );
                            })()}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BulkSearchResults;
