import React from 'react';
import './RaritySelector.css';

const RaritySelector = ({ allRarities, searchParams, handleRarityChange }) => {
  return (
    <div className="alt-filter-row">
      <div className="alt-filter-label">Filter by Rarity:</div>
      <div className="alt-filter-select-container">
        <select 
          className="alt-filter-select" 
          value={searchParams.rarity} 
          onChange={handleRarityChange}
          disabled={!searchParams.game}
        >
          <option value="">All Rarities</option>
          {allRarities.map((rarity, index) => (
            <option key={index} value={rarity}>{rarity}</option>
          ))}
        </select>
        <div className="alt-select-arrow"></div>
      </div>
    </div>
  );
};

export default RaritySelector;
