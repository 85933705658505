import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import './i18n'; // Import the i18n configuration
import { NotificationProvider } from './context/NotificationContext';

// Import mobile UI styles
import './mobile-ui/MobileApp.css';
import './mobile-ui/components/MobileHeader.css';
import './mobile-ui/components/MobileSearchBar.css';
import './mobile-ui/components/MobileTileGrid.css';
import './mobile-ui/components/MobileCardList.css';
import './mobile-ui/components/MobileCart.css';
import './mobile-ui/components/MobileExpansionSelector.css';
import './mobile-ui/components/MobilePagination.css';
import './mobile-ui/components/MobileLoadBuylistPopup.css';
import './mobile-ui/components/MobileCheckoutPage.css';

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <NotificationProvider>
      <App />
    </NotificationProvider>
  </React.StrictMode>
);
