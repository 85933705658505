import React, { useState, useEffect, useCallback, useRef } from 'react';
import './CardList.css';
import Popup from './Popup';
import SkuDetailsPopup from './SkuDetailsPopup';
import PriceInquiryForm from './PriceInquiryForm';
import { useTranslation } from 'react-i18next';
import { BACKEND_URL } from '../config';

const CardList = ({ cards, addToCart, allowedConditions, floorPrice, bulkSettings, userSettings, excludedSku, calculatePrice, exchangeRates, isCashLimitReached }) => {
  const { t } = useTranslation();
  const [selectedConditions, setSelectedConditions] = useState({});
  const [selectedPrintings, setSelectedPrintings] = useState({});
  const [selectedLanguages, setSelectedLanguages] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [showSkuDetailsPopup, setShowSkuDetailsPopup] = useState(false);
  const [showPriceInquiryForm, setShowPriceInquiryForm] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [prices, setPrices] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const isDKeyPressed = useRef(false);
  const [showBulkItems, setShowBulkItems] = useState(() => {
    const saved = localStorage.getItem('showBulkItems');
    return saved !== null ? JSON.parse(saved) : true;
  });
  const [showNotBuying, setShowNotBuying] = useState(() => {
    const saved = localStorage.getItem('showNotBuying');
    return saved !== null ? JSON.parse(saved) : true;
  });
  const [isTableView, setIsTableView] = useState(() => {
    const saved = localStorage.getItem('isTableView');
    return saved !== null ? JSON.parse(saved) : false;
  });

  const currencySymbols = {
    USD: '$', EUR: '€', GBP: '£', JPY: '¥', CAD: 'C$', AUD: 'A$', CHF: 'CHF', CNY: '¥', SEK: 'kr', NZD: 'NZ$',
    MXN: 'Mex$', SGD: 'S$', HKD: 'HK$', NOK: 'kr', KRW: '₩', TRY: '₺', RUB: '₽', INR: '₹', BRL: 'R$', ZAR: 'R'
  };

  const handleLanguageChange = useCallback((cardId, newLanguage) => {
    setSelectedLanguages(prev => ({ ...prev, [cardId]: newLanguage }));
  }, []);

  useEffect(() => {
    const fetchPrices = async () => {
      const newPrices = {};
      for (const card of cards) {
        const cardConditions = [...new Set(card.skus
          .map(sku => sku.condAbbr)
          .filter(cond => allowedConditions.includes(cond)))];
        const uniquePrintings = [...new Set(card.skus.map(sku => sku.printingName))];
        let uniqueLanguages = [...new Set(card.skus.map(sku => sku.langAbbr))];
        
        // Default to EN if available, otherwise use the first available language
        const defaultLanguage = uniqueLanguages.includes('EN') ? 'EN' : uniqueLanguages[0] || '';
        
        // Initialize selected language if not already set
        if (uniqueLanguages.length > 0 && !selectedLanguages[card._id]) {
          setSelectedLanguages(prev => ({ ...prev, [card._id]: defaultLanguage }));
        }
        
        const condition = selectedConditions[card._id] || cardConditions[0] || '';
        const printing = selectedPrintings[card._id] || uniquePrintings[0] || '';
        const language = selectedLanguages[card._id] || defaultLanguage;
        
        // Create a price map for all languages for this card
        const cardPriceMap = {};
        
        // First calculate EN price if available (as other languages may depend on it)
        let enPrice = null;
        if (uniqueLanguages.includes('EN')) {
          // Force recalculation to ensure we get the latest price with inventory modifiers
          enPrice = calculatePrice(card, condition, printing, 'EN', true);
          cardPriceMap['EN'] = enPrice;
        }
        
        // Then calculate prices for all other languages that have SKUs
        for (const lang of uniqueLanguages) {
          if (lang !== 'EN') {
            // Force recalculation to ensure we get the latest price with inventory modifiers
            const langPrice = calculatePrice(card, condition, printing, lang, true);
            cardPriceMap[lang] = langPrice;
          }
        }
        
        // Apply derived pricing to non-English languages if we have EN prices
        if (enPrice && !enPrice.contactForPrice && userSettings.langPercent) {
          // Define all possible language codes we might encounter
          const allLanguageCodes = ['JP', 'DE', 'FR', 'ES', 'IT', 'PT', 'RU', 'KO', 'ZH', 'PL'];
          
          // Determine which languages to show based on langPercent configuration
          const languagesToShow = new Set(uniqueLanguages);
          
          // Always ensure EN is included
          languagesToShow.add('EN');
          
          if (typeof userSettings.langPercent === 'object') {
            // It's a map of language-specific percentages
            console.log(`Using language-specific percentages for ${card.name}:`, userSettings.langPercent);
            
            // Add languages that have defined percentages
            Object.keys(userSettings.langPercent).forEach(langCode => {
              // For each language with a defined percentage
              const langPercent = userSettings.langPercent[langCode];
              
              // Calculate derived prices based on EN price
              if (enPrice) {
                const derivedCashPrice = enPrice.cashPrice * (langPercent / 100);
                const derivedCreditPrice = enPrice.creditPrice * (langPercent / 100);
                const derivedBasePrice = enPrice.basePrice * (langPercent / 100);
                
                console.log(`Derived ${langCode} prices for ${card.name}:`, {
                  cashPrice: derivedCashPrice,
                  creditPrice: derivedCreditPrice,
                  basePrice: derivedBasePrice
                });
                
                // Create a derived price for this language
                cardPriceMap[langCode] = {
                  cashPrice: parseFloat(derivedCashPrice.toFixed(2)),
                  creditPrice: parseFloat(derivedCreditPrice.toFixed(2)),
                  cashPercentage: enPrice.cashPercentage,
                  creditPercentage: enPrice.creditPercentage,
                  isBulk: enPrice.isBulk,
                  timestamp: Date.now(),
                  cashAvailable: derivedCashPrice >= parseFloat(userSettings.floorPrice || 0),
                  creditAvailable: derivedCreditPrice >= parseFloat(userSettings.floorPrice || 0),
                  contactForPrice: false,
                  basePrice: derivedBasePrice,
                  isDerivedPrice: true,
                  derivedFrom: 'EN',
                  derivedPercentage: langPercent
                };
                
                // Add this language to the list of available languages
                languagesToShow.add(langCode);
              }
            });
          } else {
            // Legacy support for single percentage value
            const langPercent = typeof userSettings.langPercent === 'string' ? 
              parseFloat(userSettings.langPercent) : userSettings.langPercent;
            
            console.log(`Using legacy language percentage: ${langPercent}% for ${card.name}`);
            
            // For each non-EN language, add it with derived pricing (overriding any existing prices for JP)
            allLanguageCodes.forEach(langCode => {
              // For JP, always apply the derived price even if it already has a price from SKUs
              // For other languages, skip if they already have a price from SKUs
              if (langCode !== 'JP' && cardPriceMap[langCode]) return;
              
              // Calculate derived prices based on EN price
              const derivedCashPrice = enPrice.cashPrice * (langPercent / 100);
              const derivedCreditPrice = enPrice.creditPrice * (langPercent / 100);
              const derivedBasePrice = enPrice.basePrice * (langPercent / 100);
              
              console.log(`Derived ${langCode} prices for ${card.name}:`, {
                cashPrice: derivedCashPrice,
                creditPrice: derivedCreditPrice,
                basePrice: derivedBasePrice
              });
              
              // Create a derived price for this language
              cardPriceMap[langCode] = {
                cashPrice: parseFloat(derivedCashPrice.toFixed(2)),
                creditPrice: parseFloat(derivedCreditPrice.toFixed(2)),
                cashPercentage: enPrice.cashPercentage,
                creditPercentage: enPrice.creditPercentage,
                isBulk: enPrice.isBulk,
                timestamp: Date.now(),
                cashAvailable: derivedCashPrice >= parseFloat(userSettings.floorPrice || 0),
                creditAvailable: derivedCreditPrice >= parseFloat(userSettings.floorPrice || 0),
                contactForPrice: false,
                basePrice: derivedBasePrice,
                isDerivedPrice: true,
                derivedFrom: 'EN',
                derivedPercentage: langPercent
              };
              
              // Add this language to the list of available languages
              languagesToShow.add(langCode);
            });
          }
          
          // Convert Set back to array for the dropdown
          uniqueLanguages = Array.from(languagesToShow);
          
          // Update the dropdown options
          if (!selectedLanguages[card._id]) {
            setSelectedLanguages(prev => ({ ...prev, [card._id]: defaultLanguage }));
          }
        }
        
        // Store the price map and the current selected language price
        newPrices[card._id] = {
          currentPrice: cardPriceMap[language] || { contactForPrice: true },
          priceMap: cardPriceMap,
          availableLanguages: uniqueLanguages
        };
      }
      setPrices(newPrices);
      setIsLoading(false);
    };

    fetchPrices();
  }, [cards, selectedConditions, selectedPrintings, allowedConditions, calculatePrice, userSettings]);
  
  // Update current price when selected language changes
  useEffect(() => {
    setPrices(prevPrices => {
      const updatedPrices = { ...prevPrices };
      for (const cardId in selectedLanguages) {
        if (updatedPrices[cardId] && updatedPrices[cardId].priceMap) {
          const language = selectedLanguages[cardId];
          const priceMap = updatedPrices[cardId].priceMap;
          updatedPrices[cardId] = {
            ...updatedPrices[cardId],
            currentPrice: priceMap[language] || { contactForPrice: true }
          };
        }
      }
      return updatedPrices;
    });
  }, [selectedLanguages]);

  const handleConditionChange = useCallback((cardId, newCondition) => {
    setSelectedConditions(prev => ({ ...prev, [cardId]: newCondition }));
  }, []);

  const handlePrintingChange = useCallback((cardId, newPrinting) => {
    setSelectedPrintings(prev => ({ ...prev, [cardId]: newPrinting }));
  }, []);

  const handleAddToCart = useCallback((card, isCash) => {
    const condition = selectedConditions[card._id] || card.skus[0]?.condAbbr || '';
    const printing = selectedPrintings[card._id] || card.skus[0]?.printingName || '';
    const language = selectedLanguages[card._id] || 'EN';
    const quantity = 1;
    
    // Get the price data for the selected language
    const cardPriceData = prices[card._id];
    if (!cardPriceData || !cardPriceData.currentPrice) {
      console.error('Price not found for card:', card._id);
      return;
    }

    const currentPrice = cardPriceData.currentPrice;
    const basePrice = currentPrice.basePrice;
    const offeredPrice = isCash ? currentPrice.cashPrice : currentPrice.creditPrice;
    
    // For non-English cards, we need to find the equivalent EN SKU
    // but add a note that it's actually a non-English card
    let sku;
    let orderNote = '';
    
    if (language !== 'EN') {
      // First try to find the exact SKU with the selected language
      const exactSku = card.skus.find(s => 
        s.condAbbr === condition && 
        s.printingName === printing && 
        s.langAbbr === language
      );
      
      // Then find the equivalent EN SKU
      const enSku = card.skus.find(s => 
        s.condAbbr === condition && 
        s.printingName === printing && 
        s.langAbbr === 'EN'
      );
      
      // If we have an EN SKU, use it but add a note
      if (enSku) {
        sku = enSku;
        // Include the percentage in the note if it's a derived price
        if (currentPrice.isDerivedPrice) {
          orderNote = `This ${card.name} is actually a ${language} language card (${currentPrice.derivedPercentage}% of EN price).`;
        } else {
          orderNote = `This ${card.name} is actually a ${language} language card.`;
        }
        console.log(`Using EN SKU for ${language} card with note: ${orderNote}`);
      } else {
        // If no EN SKU exists, use the exact language SKU if available
        sku = exactSku;
        console.log(`No EN SKU found, using ${language} SKU directly`);
      }
    } else {
      // For English cards, just find the matching SKU
      sku = card.skus.find(s => 
        s.condAbbr === condition && 
        s.printingName === printing && 
        s.langAbbr === language
      );
    }

    console.log('Adding to cart:', {
      name: card.name,
      condition,
      printing,
      language,
      basePrice,
      offeredPrice,
      isCash,
      skuId: sku?.skuId,
      isDerivedPrice: currentPrice.isDerivedPrice,
      derivedPercentage: currentPrice.derivedPercentage,
      orderNote
    });

    // Create a nonEnglish field for non-English cards
    let nonEnglish = null;
    if (language !== 'EN') {
      nonEnglish = {
        language,
        offeredPrice,
        isDerivedPrice: currentPrice.isDerivedPrice,
        derivedPercentage: currentPrice.derivedPercentage,
        basePrice
      };
    }

    // Pass the actual selected language, order note, and nonEnglish data to addToCart
    addToCart(card, condition, printing, quantity, sku?.skuId, isCash, language, orderNote, nonEnglish);
  }, [selectedConditions, selectedPrintings, selectedLanguages, addToCart, prices]);

  const handleContactForPrice = useCallback((card) => {
    setSelectedCard(card);
    setShowPriceInquiryForm(true);
  }, []);

  const closePopup = useCallback(() => {
    setShowPopup(false);
  }, []);

  const handleOpenSkuDetails = useCallback((card) => {
    if (isDKeyPressed.current) {
      setSelectedCard(card);
      setShowSkuDetailsPopup(true);
    }
  }, []);

  const closeSkuDetailsPopup = useCallback(() => {
    setShowSkuDetailsPopup(false);
    setSelectedCard(null);
  }, []);

  const closePriceInquiryForm = useCallback(() => {
    setShowPriceInquiryForm(false);
    setSelectedCard(null);
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'd' || e.key === 'D') {
        isDKeyPressed.current = true;
      }
    };

    const handleKeyUp = (e) => {
      if (e.key === 'd' || e.key === 'D') {
        isDKeyPressed.current = false;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const isExcluded = useCallback((card) => {
    console.log('Checking exclusion for:', {
      cardName: card.name,
      productId: card.productId,
      excludedSku,
      isExcluded: excludedSku.includes(card.productId.toString())
    });
    return excludedSku.includes(card.productId.toString());
  }, [excludedSku]);

  const formatPrice = (price) => {
    return isNaN(price) ? '0.00' : price.toFixed(2);
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  const toggleBulkItems = () => {
    const newValue = !showBulkItems;
    setShowBulkItems(newValue);
    localStorage.setItem('showBulkItems', JSON.stringify(newValue));
    
    // Notify parent component about the change
    if (typeof window !== 'undefined') {
      window.dispatchEvent(new CustomEvent('bulkItemsToggle', { detail: { showBulkItems: newValue } }));
    }
  };

  const toggleTableView = () => {
    const newValue = !isTableView;
    setIsTableView(newValue);
    localStorage.setItem('isTableView', JSON.stringify(newValue));
  };

  const toggleNotBuying = () => {
    const newValue = !showNotBuying;
    setShowNotBuying(newValue);
    localStorage.setItem('showNotBuying', JSON.stringify(newValue));
    
    // Notify parent component that we need more cards if we're hiding not buying items
    if (!newValue) {
      // Create and dispatch a custom event that the parent component can listen for
      if (typeof window !== 'undefined') {
        window.dispatchEvent(new CustomEvent('needMoreCards', { 
          detail: { showNotBuying: newValue } 
        }));
      }
    }
  };

  if (isLoading) {
    return (
      <div className="loading-screen">
        <p>{t('Just fetching you the best prices...')}</p>
      </div>
    );
  }

  // No longer filtering cards here as it's done at the parent component level
  const renderCardView = (card) => {
    const cardConditions = [...new Set(card.skus
      .map(sku => sku.condAbbr)
      .filter(cond => allowedConditions.includes(cond)))];
    const uniquePrintings = [...new Set(card.skus.map(sku => sku.printingName))];
    const condition = selectedConditions[card._id] || cardConditions[0] || '';
    const printing = selectedPrintings[card._id] || uniquePrintings[0] || '';
    const cardPriceData = prices[card._id] || { currentPrice: { cashPrice: 0, creditPrice: 0, isBulk: false, timestamp: null, contactForPrice: false } };
    const { cashPrice, creditPrice, isBulk, timestamp, contactForPrice, isDerivedPrice, derivedPercentage } = cardPriceData.currentPrice;
    const excluded = isExcluded(card); // Updated to only pass card
    const noPriceAvailable = cashPrice === 0 && creditPrice === 0 && !isBulk;

    return (
      <div key={card._id} className="card">
        <img 
          src={card.image || process.env.PUBLIC_URL + '/flags/No-Image-Placeholder.svg.png'} 
          alt={card.name} 
          className="card-image" 
          onError={(e) => {
            console.log('Image failed to load:', card.image);
            e.target.onerror = null; // Prevent infinite loop
            e.target.src = process.env.PUBLIC_URL + '/flags/No-Image-Placeholder.svg.png';
          }}
        />
        <div className="card-info">
          <h3 className="card-name" style={{ whiteSpace: 'normal', overflow: 'visible', textOverflow: 'clip' }}>{card.name}</h3>
          <p className="card-expansion">
            {card.expansionName || t('Expansion not available')}
          </p>
          {card.number && <p className="card-number">#{card.number}</p>}
          {!card.number && card.productId && <p className="card-number">ID: {card.productId}</p>}
          {card.rarity && <p className="card-rarity">{card.rarity}</p>}
          <div className="card-options">
            <select 
              className="condition-select"
              value={condition}
              onChange={(e) => handleConditionChange(card._id, e.target.value)}
            >
              {cardConditions.map((cond, index) => (
                <option key={index} value={cond}>{cond}</option>
              ))}
            </select>
            <select 
              className="printing-select"
              value={printing}
              onChange={(e) => handlePrintingChange(card._id, e.target.value)}
            >
              {uniquePrintings.map((printing, index) => (
                <option key={index} value={printing}>{printing}</option>
              ))}
            </select>
            {/* Language dropdown */}
            {(() => {
              // Use the availableLanguages from the price data if available, otherwise fall back to the card's SKUs
              const availableLanguages = prices[card._id]?.availableLanguages || 
                [...new Set(card.skus.map(sku => sku.langAbbr))];
              
              const language = selectedLanguages[card._id] || 
                (availableLanguages.includes('EN') ? 'EN' : availableLanguages[0] || '');
              
              // Only show language dropdown if there are multiple languages
              if (availableLanguages.length > 1) {
                return (
                  <select 
                    className="language-select"
                    value={language}
                    onChange={(e) => handleLanguageChange(card._id, e.target.value)}
                  >
                    {availableLanguages.map((lang, index) => (
                      <option key={index} value={lang}>{lang}</option>
                    ))}
                  </select>
                );
              } else if (availableLanguages.length === 1 && availableLanguages[0] === 'JP') {
                // If only Japanese is available, show a label
                return <span className="japanese-only-label">{t('Japanese Only')}</span>;
              }
              return null;
            })()}
          </div>
          <div className="card-actions">
            {contactForPrice ? (
              <button 
                className="add-to-cart-button contact-for-price amber-bg"
                onClick={() => handleContactForPrice(card)}
              >
                {t('Contact for Price')}
              </button>
            ) : cardPriceData.currentPrice.isRecentRelease || excluded || noPriceAvailable ? (
              <button className="add-to-cart-button not-buying" disabled>
                {t('Not Buying')}
              </button>
            ) : isBulk ? (
              <button className="add-to-cart-button bulk" onClick={() => setShowPopup(true)}>
                {t('Bulk Item')}
              </button>
            ) : (
              <>
                {creditPrice > 0 && (
                  <button 
                    className="add-to-cart-button credit"
                    onClick={() => handleAddToCart(card, false)}
                  >
                    {t('Credit')} {currencySymbols[userSettings.currency] || '$'}{formatPrice(creditPrice)}
                    {cardPriceData.currentPrice.isDerivedPrice && (
                      <span className="derived-price-indicator" title={`${t('Price calculated as')} ${cardPriceData.currentPrice.derivedPercentage}% ${t('of EN price')}`}>*</span>
                    )}
                  </button>
                )}
                {cashPrice > 0 ? (
                  <button 
                    className={`add-to-cart-button cash ${isCashLimitReached ? 'disabled' : ''}`}
                    onClick={() => handleAddToCart(card, true)}
                    disabled={isCashLimitReached}
                    title={isCashLimitReached ? t('Cash limit reached') : ''}
                  >
                    {isCashLimitReached ? t('Cash Limit Reached') : `${t('Cash')} ${currencySymbols[userSettings.currency] || '$'}${formatPrice(cashPrice)}`}
                    {!isCashLimitReached && cardPriceData.currentPrice.isDerivedPrice && (
                      <span className="derived-price-indicator" title={`${t('Price calculated as')} ${cardPriceData.currentPrice.derivedPercentage}% ${t('of EN price')}`}>*</span>
                    )}
                  </button>
                ) : (
                  <button className="add-to-cart-button not-buying" disabled>
                    {t('Not Buying Cash')}
                  </button>
                )}
              </>
            )}
          </div>
          {timestamp && (
            <p className="price-timestamp" onClick={() => handleOpenSkuDetails(card)}>
              {t('Price fetched at')}: {formatTimestamp(timestamp)}
            </p>
          )}
        </div>
      </div>
    );
  };

  const renderTableView = (filteredCards) => {
    return (
      <table className="card-table">
        <thead>
          <tr>
            <th>{t('Name')}</th>
            <th>{t('Expansion')}</th>
            <th>{t('Number')}</th>
            <th>{t('Rarity')}</th>
            <th>{t('Condition')}</th>
            <th>{t('Printing')}</th>
            <th>{t('Language')}</th>
            <th>{t('Credit Price')}</th>
            <th>{t('Cash Price')}</th>
            <th>{t('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {filteredCards.map((card) => {
            const cardConditions = [...new Set(card.skus
              .map(sku => sku.condAbbr)
              .filter(cond => allowedConditions.includes(cond)))];
            const uniquePrintings = [...new Set(card.skus.map(sku => sku.printingName))];
            const condition = selectedConditions[card._id] || cardConditions[0] || '';
            const printing = selectedPrintings[card._id] || uniquePrintings[0] || '';
            const cardPriceData = prices[card._id] || { currentPrice: { cashPrice: 0, creditPrice: 0, isBulk: false, timestamp: null, contactForPrice: false } };
            const { cashPrice, creditPrice, isBulk, contactForPrice, isDerivedPrice, derivedPercentage } = cardPriceData.currentPrice;
            const excluded = isExcluded(card); // Updated to only pass card
            const noPriceAvailable = cashPrice === 0 && creditPrice === 0 && !isBulk;

            return (
              <tr key={card._id}>
                <td>
                  {card.image ? (
                    <img 
                      src={card.image} 
                      alt={card.name} 
                      className="table-card-image" 
                      onError={(e) => {
                        console.log('Table image failed to load:', card.image);
                        e.target.onerror = null; // Prevent infinite loop
                        e.target.src = process.env.PUBLIC_URL + '/flags/No-Image-Placeholder.svg.png';
                      }}
                    />
                  ) : (
                    <img 
                      src={process.env.PUBLIC_URL + '/flags/No-Image-Placeholder.svg.png'} 
                      alt={card.name} 
                      className="table-card-image" 
                    />
                  )}
                  {card.name}
                </td>
                <td>
                  {card.expansionName || t('Expansion not available')}
                </td>
                <td>
                  {card.number ? `#${card.number}` : (card.productId ? `ID: ${card.productId}` : t('N/A'))}
                </td>
                <td>
                  {card.rarity || t('N/A')}
                </td>
                <td>
                  <select 
                    value={condition}
                    onChange={(e) => handleConditionChange(card._id, e.target.value)}
                  >
                    {cardConditions.map((cond, index) => (
                      <option key={index} value={cond}>{cond}</option>
                    ))}
                  </select>
                </td>
                <td>
                  <select 
                    value={printing}
                    onChange={(e) => handlePrintingChange(card._id, e.target.value)}
                  >
                    {uniquePrintings.map((printing, index) => (
                      <option key={index} value={printing}>{printing}</option>
                    ))}
                  </select>
                </td>
                <td>
                  {(() => {
                    // Use the availableLanguages from the price data if available, otherwise fall back to the card's SKUs
                    const availableLanguages = prices[card._id]?.availableLanguages || 
                      [...new Set(card.skus.map(sku => sku.langAbbr))];
                    
                    const language = selectedLanguages[card._id] || 
                      (availableLanguages.includes('EN') ? 'EN' : availableLanguages[0] || '');
                    
                    // Only show language dropdown if there are multiple languages
                    if (availableLanguages.length > 1) {
                      return (
                        <select 
                          value={language}
                          onChange={(e) => handleLanguageChange(card._id, e.target.value)}
                        >
                          {availableLanguages.map((lang, index) => (
                            <option key={index} value={lang}>{lang}</option>
                          ))}
                        </select>
                      );
                    } else if (availableLanguages.length === 1 && availableLanguages[0] === 'JP') {
                      // If only Japanese is available, show a label
                      return <span className="japanese-only-label">{t('Japanese Only')}</span>;
                    } else {
                      return language;
                    }
                  })()}
                </td>
                <td>
                  {currencySymbols[userSettings.currency] || '$'}{formatPrice(creditPrice)}
                  {cardPriceData.currentPrice.isDerivedPrice && (
                    <span className="derived-price-indicator" title={`${t('Price calculated as')} ${cardPriceData.currentPrice.derivedPercentage}% ${t('of EN price')}`}>*</span>
                  )}
                </td>
                <td>
                  {currencySymbols[userSettings.currency] || '$'}{formatPrice(cashPrice)}
                  {cardPriceData.currentPrice.isDerivedPrice && (
                    <span className="derived-price-indicator" title={`${t('Price calculated as')} ${cardPriceData.currentPrice.derivedPercentage}% ${t('of EN price')}`}>*</span>
                  )}
                </td>
                <td>
                  {contactForPrice ? (
                    <button 
                      className="add-to-cart-button contact-for-price amber-bg"
                      onClick={() => handleContactForPrice(card)}
                    >
                      {t('Contact for Price')}
                    </button>
                  ) : cardPriceData.currentPrice.isRecentRelease || excluded || noPriceAvailable ? (
                    <button className="add-to-cart-button not-buying" disabled>
                      {t('Not Buying')}
                    </button>
                  ) : isBulk ? (
                    <button className="add-to-cart-button bulk" onClick={() => setShowPopup(true)}>
                      {t('Bulk Item')}
                    </button>
                  ) : (
                    <>
                      <button 
                        className="add-to-cart-button credit"
                        onClick={() => handleAddToCart(card, false)}
                      >
                        {t('Add Credit')}
                      </button>
                      <button 
                        className={`add-to-cart-button cash ${isCashLimitReached ? 'disabled' : ''}`}
                        onClick={() => handleAddToCart(card, true)}
                        disabled={isCashLimitReached}
                        title={isCashLimitReached ? t('Cash limit reached') : ''}
                      >
                        {isCashLimitReached ? t('Cash Limit') : t('Add Cash')}
                      </button>
                    </>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <div className="card-list-container">
      <div className="toggle-row">
        {/* Bulk toggle removed as requested, but functionality kept in code */}
        <div className="view-toggle">
          <label className="switch">
            <input
              type="checkbox"
              checked={isTableView}
              onChange={toggleTableView}
            />
            <span className="slider round"></span>
          </label>
          <span className="toggle-label">{t('Table View')}</span>
        </div>
        <div className="view-toggle">
          <label className="switch">
            <input
              type="checkbox"
              checked={showNotBuying}
              onChange={toggleNotBuying}
            />
            <span className="slider round"></span>
          </label>
          <span className="toggle-label">{t('Show Not Buying')}</span>
        </div>
      </div>
      <div className={`card-list ${isTableView ? 'table-view' : ''}`}>
        {isTableView ? 
          renderTableView(
            cards.filter(card => {
              if (showNotBuying) return true;
              
              const cardPriceData = prices[card._id] || { currentPrice: { cashPrice: 0, creditPrice: 0, isBulk: false } };
              const { cashPrice, creditPrice, isBulk, isRecentRelease } = cardPriceData.currentPrice;
              const excluded = isExcluded(card);
              const noPriceAvailable = cashPrice === 0 && creditPrice === 0 && !isBulk;
              
              // If any of these conditions are true, the card is "not buying" or a bulk item
              const isNotBuying = isRecentRelease || excluded || noPriceAvailable || isBulk;
              
              // Only show the card if it's not a "not buying" card
              return !isNotBuying;
            })
          ) : 
          cards
            .filter(card => {
              if (showNotBuying) return true;
              
              const cardPriceData = prices[card._id] || { currentPrice: { cashPrice: 0, creditPrice: 0, isBulk: false } };
              const { cashPrice, creditPrice, isBulk, isRecentRelease } = cardPriceData.currentPrice;
              const excluded = isExcluded(card);
              const noPriceAvailable = cashPrice === 0 && creditPrice === 0 && !isBulk;
              
              // If any of these conditions are true, the card is "not buying" or a bulk item
              const isNotBuying = isRecentRelease || excluded || noPriceAvailable || isBulk;
              
              // Only show the card if it's not a "not buying" card
              return !isNotBuying;
            })
            .map(renderCardView)
        }
      </div>
      {showPopup && <Popup content={bulkSettings} onClose={closePopup} />}
      {showSkuDetailsPopup && selectedCard && (
        <SkuDetailsPopup
          card={selectedCard}
          onClose={closeSkuDetailsPopup}
          calculatePrice={calculatePrice}
          userSettings={userSettings}
          exchangeRates={exchangeRates}
        />
      )}
      {showPriceInquiryForm && selectedCard && (
        <PriceInquiryForm
          card={selectedCard}
          condition={selectedConditions[selectedCard._id] || selectedCard.skus[0]?.condAbbr || ''}
          printing={selectedPrintings[selectedCard._id] || selectedCard.skus[0]?.printingName || ''}
          onClose={closePriceInquiryForm}
          userSettings={userSettings}
        />
      )}
    </div>
  );
};

export default React.memo(CardList);
