import React, { useState } from 'react';
import './SaveBuylistPopup.css';
import { BACKEND_URL } from '../config';

const SaveBuylistPopup = ({ onClose, cartItems, username, userSettings }) => {
  // Define currency symbols
  const currencySymbols = {
    USD: '$', EUR: '€', GBP: '£', JPY: '¥', CAD: 'C$', AUD: 'A$', CHF: 'CHF', CNY: '¥', SEK: 'kr', NZD: 'NZ$',
    MXN: 'Mex$', SGD: 'S$', HKD: 'HK$', NOK: 'kr', KRW: '₩', TRY: '₺', RUB: '₽', INR: '₹', BRL: 'R$', ZAR: 'R'
  };

  // Get the currency symbol based on user settings
  const currencySymbol = currencySymbols[userSettings?.currency || 'USD'] || '$';
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate email
    if (!validateEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }
    
    // Validate cart
    if (cartItems.length === 0) {
      setError('Your cart is empty. Please add items before saving.');
      return;
    }
    
    setIsSubmitting(true);
    setError('');
    
    try {
      // Calculate the original total
      const original_total = cartItems.reduce((sum, item) => {
        const price = item.isCash ? parseFloat(item.cashPrice) : parseFloat(item.creditPrice);
        return sum + (price * item.quantity);
      }, 0);
      
      // Prepare the data to send
      const buylistData = {
        customer_email: email,
        cart_items: cartItems,
        username: username,
        original_total: parseFloat(original_total.toFixed(2))
      };
      
      // Send the data to the server
      const response = await fetch(`${BACKEND_URL}/save-buylist`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(buylistData),
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to save buylist');
      }
      
      const data = await response.json();
      console.log('Buylist saved successfully:', data);
      
      // Show success message
      setSuccess(true);
      
      // Close the popup after a delay
      setTimeout(() => {
        onClose();
      }, 3000);
      
    } catch (err) {
      console.error('Error saving buylist:', err);
      setError(err.message || 'Failed to save buylist. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="popup-overlay">
      <div className="save-buylist-popup">
        <button className="close-button" onClick={onClose}>×</button>
        <h2>Save Your Buylist for Later</h2>
        
        {success ? (
          <div className="success-message">
            <p>Your buylist has been saved successfully!</p>
            <p>We've sent a confirmation to {email}.</p>
            <p>You can load this buylist later using the same email address.</p>
          </div>
        ) : (
          <>
            <p>Enter your email address to save your current buylist. Your buylist will be saved for 48 hours. You can return later to load it with the exact same prices as when it was saved.</p>
            
            {error && <div className="error-message">{error}</div>}
            
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="email">Email Address:</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Enter your email"
                  required
                />
              </div>
              
              <div className="cart-summary">
                <p><strong>Items in cart:</strong> {cartItems.length}</p>
                <p><strong>Total value:</strong> {currencySymbol}{cartItems.reduce((sum, item) => {
                  const price = item.isCash ? parseFloat(item.cashPrice) : parseFloat(item.creditPrice);
                  return sum + (price * item.quantity);
                }, 0).toFixed(2)}</p>
              </div>
              
              <div className="form-actions">
                <button 
                  type="button" 
                  className="cancel-button"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button 
                  type="submit" 
                  className="save-button"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Saving...' : 'Save Buylist'}
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default SaveBuylistPopup;
