import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import BulkSearchTable from './BulkSearchTable';
import BulkSearchResults from './BulkSearchResults';
import { BACKEND_URL } from '../../config';
import './BulkSearchPage.css';

const BulkSearchPage = ({ addToCart, userSettings, allowedConditions, calculatePrice, exchangeRates }) => {
  const { t } = useTranslation();
  const [rows, setRows] = useState([
    { id: 1, name: '', abbreviation: '', number: '' },
    { id: 2, name: '', abbreviation: '', number: '' },
  ]);
  const [nextId, setNextId] = useState(3);
  const [searchResults, setSearchResults] = useState({});
  const [isSearching, setIsSearching] = useState(false);

  const handleAddRow = useCallback(() => {
    setRows(prevRows => [...prevRows, { id: nextId, name: '', abbreviation: '', number: '' }]);
    setNextId(prevId => prevId + 1);
  }, [nextId]);

  const handleRemoveRow = useCallback((id) => {
    setRows(prevRows => prevRows.filter(row => row.id !== id));
    setSearchResults(prevResults => {
      const newResults = { ...prevResults };
      delete newResults[id];
      return newResults;
    });
  }, []);

  const handleRowChange = useCallback((id, field, value) => {
    setRows(prevRows => 
      prevRows.map(row => 
        row.id === id ? { ...row, [field]: value } : row
      )
    );
  }, []);

  const parseInput = useCallback((input) => {
    // Parse input in the format "Card Name [Set] #Number"
    const regex = /^(.*?)(?:\s+\[([^\]]+)\])?(?:\s+#?(\d+))?$/;
    const match = input.match(regex);
    
    if (match) {
      const [, name, abbreviation = '', number = ''] = match;
      return { name: name.trim(), abbreviation: abbreviation.trim(), number: number.trim() };
    }
    
    return { name: input.trim(), abbreviation: '', number: '' };
  }, []);

  const handleBulkInput = useCallback((text) => {
    const lines = text.split('\n').filter(line => line.trim() !== '');
    
    const newRows = lines.map((line, index) => {
      const { name, abbreviation, number } = parseInput(line);
      return { 
        id: nextId + index, 
        name, 
        abbreviation, 
        number 
      };
    });
    
    setRows(newRows);
    setNextId(nextId + newRows.length);
    
    // Clear previous search results
    setSearchResults({});
  }, [nextId, parseInput]);

  const searchCard = useCallback(async (row) => {
    if (!row.name.trim()) return null;
    
    setSearchResults(prev => ({
      ...prev,
      [row.id]: { status: 'searching', results: [] }
    }));
    
    try {
      const url = new URL(`${BACKEND_URL}/catalog`);
      const params = {
        search: row.name,
        abbr: row.abbreviation,
        number: row.number,
        limit: "10"
      };
      
      url.search = new URLSearchParams(params).toString();
      
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.items && data.items.length > 0) {
        setSearchResults(prev => ({
          ...prev,
          [row.id]: { 
            status: 'complete', 
            results: data.items,
            selectedResult: data.items.length === 1 ? data.items[0] : null
          }
        }));
      } else {
        setSearchResults(prev => ({
          ...prev,
          [row.id]: { 
            status: 'complete', 
            results: [],
            message: t('No cards found matching these criteria')
          }
        }));
      }
    } catch (error) {
      console.error('Error searching for card:', error);
      setSearchResults(prev => ({
        ...prev,
        [row.id]: { 
          status: 'error', 
          results: [],
          message: t('Error searching for card')
        }
      }));
    }
  }, [t]);

  const handleSearchAll = useCallback(async () => {
    setIsSearching(true);
    
    // Filter out empty rows
    const validRows = rows.filter(row => row.name.trim() !== '');
    
    if (validRows.length === 0) {
      setIsSearching(false);
      return;
    }
    
    // Initialize all rows as pending
    const initialResults = {};
    validRows.forEach(row => {
      initialResults[row.id] = { status: 'pending', results: [] };
    });
    setSearchResults(initialResults);
    
    // Search each card sequentially
    for (const row of validRows) {
      await searchCard(row);
    }
    
    setIsSearching(false);
  }, [rows, searchCard]);

  const handleSelectResult = useCallback((rowId, cardId) => {
    setSearchResults(prev => {
      const rowResult = prev[rowId];
      if (!rowResult) return prev;
      
      const selectedCard = rowResult.results.find(card => card._id === cardId);
      
      return {
        ...prev,
        [rowId]: {
          ...rowResult,
          selectedResult: selectedCard || null
        }
      };
    });
  }, []);

  const handleAddToCart = useCallback((card, condition, printing, isCash) => {
    addToCart(card, condition, printing, 1, null, isCash);
  }, [addToCart]);

  return (
    <div className="bulk-search-page">
      <h2 className="bulk-search-title">{t('Bulk Search')}</h2>
      <p className="bulk-search-description">
        {t('Enter card names, optionally with set abbreviation and card number. Example: "Counterspell [7ED] #67" or just "Mana Crypt".')}
      </p>
      
      <BulkSearchTable 
        rows={rows}
        onAddRow={handleAddRow}
        onRemoveRow={handleRemoveRow}
        onRowChange={handleRowChange}
        onBulkInput={handleBulkInput}
      />
      
      <div className="bulk-search-actions">
        <button 
          className="search-all-button"
          onClick={handleSearchAll}
          disabled={isSearching || rows.every(row => !row.name.trim())}
        >
          {isSearching ? t('Searching...') : t('Search All')}
        </button>
      </div>
      
      <BulkSearchResults 
        searchResults={searchResults}
        rows={rows}
        onSelectResult={handleSelectResult}
        onAddToCart={handleAddToCart}
        allowedConditions={allowedConditions}
        calculatePrice={calculatePrice}
        userSettings={userSettings}
        exchangeRates={exchangeRates}
      />
    </div>
  );
};

export default BulkSearchPage;
